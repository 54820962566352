.sub-nav-container{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 1280px;
    gap: 16px;
    overflow: scroll;
    margin: 0 auto;
    font-family: Mulish;
    padding: 36px 72px 0px;
}
.sub_nav_container{
    display: flex;
padding: 8px 7px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
background: #FFF7F1;
font-family: Mulish;
color: rgba(176, 56, 61, 1);
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 28px; 
letter-spacing: 0.035px;
cursor: pointer;
max-width: 212px;
min-height: 36px;
}
.sub-nav-container::-webkit-scrollbar{
    display: none;
}
@media screen and (max-width: 1024px) {
    .sub-nav-container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        max-width: 1000px;
        gap: 16px;
        margin-top: 24px;
        margin: 0 auto;
        margin-top: 24px;
        font-family: Mulish;
        padding: 0px 16px 16px 16px;
        overflow: scroll;
        
    
    }
    .sub-nav-container::-webkit-scrollbar{
        display: none;
    }
   
    .sub_nav_container {
        display: flex;
        padding: 4px 4px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        text-align: center;
        border-radius: 8px;
        background: #FFF7F1;
        font-family: Mulish;
        color: rgba(176, 56, 61, 1);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.035px;
        cursor: pointer;
        min-width: 245px;
        min-height: 48px;
    }
    }
@media screen and (max-width: 660px) {
.sub-nav-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 1000px;
    gap: 16px;
    margin-top: 24px;
    margin: 0 auto;
    margin-top: 24px;
    font-family: Mulish;
    padding: 0px 16px 16px 16px;
    overflow: scroll;
    

}
.sub-nav-container::-webkit-scrollbar{
    display: none;
}
.sub_nav_container {
    display: flex;
    padding: 4px 4px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    text-align: center;
    border-radius: 8px;
    background: #FFF7F1;
    font-family: Mulish;
    color: rgba(176, 56, 61, 1);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.035px;
    cursor: pointer;
    min-width: 245px;
    min-height: 48px;
}
}
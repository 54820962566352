/* Container for service migration */
.service_migration_container {
    max-width: 1280px;
    padding: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    padding: 24px 32px;
}

/* Title styling */
.service_migration_title {
    color: #045590;
    font-family: mulish;
    font-size: 28px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%; /* 79.8px */
    letter-spacing: 0.143px;
    max-width: 1088px;
}

/* Header styling */
.service_migration_head {
    border-radius: 20px;
    background: #F4F4F4;
    max-width: 1170px;
    font-family: mulish;
    width: 100%;
    padding: 24px;
    flex-shrink: 0;
}

/* Body styling */
.service_migration_body {
    display: flex;
    margin: 0 auto;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
}

/* Description Sections styling */
.service_migration_desc_sections {
    width: 380px;
    flex-shrink: 0;
    font-family: mulish;
    border-radius: 20px;
    background: #F4F4F4;
    padding: 32px;
    gap: 24px;
    display: flex;
    flex-direction: column;
}

/* Description styling */
.service_migration_desc {
    display: grid;
    font-family: mulish;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
}

/* End Section styling */
.service_migration_desc_sections_end {
    width: 860px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #F4F4F4;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 0 auto;
}

/* Section Title styling */
.service_migration_desc_sections_title {
    color: #3B3B3B;
    font-family: mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 28.8px */
    letter-spacing: 0.06px;

}

.service_img{
height: 653px;
flex-shrink: 0;
}
/* Section Description styling */
.service_migration_desc_sections_desc,
.service_migration_desc_sections_desc_end {
    /* width: 299px; */
    color: #848484;
    font-family: mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
}
.service_migration_conclution{
    max-width: 1150px;
flex-shrink: 0;
width: 100%;
border-radius: 20px;
background: #FFF7F1;
font-family: mulish;
padding: 32px;
}
.service_migration_conclution_text{
    color: #848484;
    font-size: 18px;
    font-family: mulish;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 28.8px */
}

/* Media queries for responsive design */
@media screen and (max-width: 1024px) {
    .service_migration_container,
    .service_migration_body {
        max-width: 1280px;
        padding: 0;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
    }

    .service_migration_body {
        flex-direction: column;
    }
    .service_migration_head {
        border-radius: 20px;
        background: #F4F4F4;
        width: 750px;
        flex-shrink: 0;
        width: 700px;
        padding: 24px;
    }
    .service_migration_title {
        color: #045590;
       
        font-size: 32px;
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.143px;
        max-width: 1088px;
    }
    .service_migration_desc {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        margin: 0 auto;
    }
    .service_migration_desc_sections {
        width: 300px;
        flex-shrink: 0;
        border-radius: 20px;
        background: #F4F4F4;
        padding: 32px;
        gap: 24px;
        display: flex;
        flex-direction: column;
    }
    .service_migration_desc_sections_end {
        width: 700px;
        flex-shrink: 0;
        border-radius: 20px;
        background: #F4F4F4;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin: 0 auto;
    }
    .service_img {
        display: none;
      }
      .service_migration_conclution {
 
        flex-shrink: 0;
        width: 90%;
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
    }
    .service_migration_conclution_text {
        color: #3B3B3B;
       
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
    }
    
}
@media screen and (max-width: 880px) {
.service_migration_conclution {
 
    flex-shrink: 0;
    width: 90%;
    border-radius: 20px;
    background: #FFF7F1;
    padding: 24px;
}
.service_migration_conclution_text {
    color: #3B3B3B;
   
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
}
}


@media screen and (max-width: 480px) {
    .service_migration_body {
        display: flex;
        margin: 0 auto;
        align-items: flex-start;
        justify-content: space-between;
        gap: 24px;
        flex-direction: column;
    }

    .service_migration_desc {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 9px;
    }
    .service_migration_desc_sections_end {
        width: 330px;
        flex-shrink: 0;
        border-radius: 20px;
        background: #F4F4F4;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin: 0 auto;
    }
    .service_migration_desc_sections {
        width: 330px;
        flex-shrink: 0;
        border-radius: 20px;
        background: #F4F4F4;
        padding: 16px;
        gap: 16px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }
   
    .service_migration_head {
        border-radius: 20px;
        background: #F4F4F4;
        width: 330px;
        padding: 16px;
        flex-shrink: 0;
    }
    .service_migration_title {
        color: #045590;
       
        font-size: 24px;
        font-style: normal;
        width: 330px;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.143px;
      
    }
    .service_migration_desc_sections_title {
        color: #3B3B3B;
       
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: 0.06px;
        width: 258px;
    }
    .service_migration_desc_sections_desc, .service_migration_desc_sections_desc_end {
        width: 299px;
        color: #848484;
       
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
    .service_migration_conclution {
        /* max-width: 1150px; */
        flex-shrink: 0;
        width: 330px;
        border-radius: 20px;
        background: #FFF7F1;
        padding: 16px;
        margin: 0 auto;
    }
    .service_migration_conclution_text {
        color: #848484;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
    }
    .service_img {
        display: none;
      }
}


.InternationalTeamComp_layout{
    margin: 0 auto;
    max-width: 1280px;
    padding-left: 72px;
    padding-top: 36px;
    padding-bottom: 36px;
    padding-right: 72px;
}
.InternationalTeamComp_head{
    font-family: Mulish;
font-size: 57px;
font-weight: 900;
line-height: 80px;
letter-spacing: 0.0025em;
text-align: left;
padding: 32px;
border-radius: 20px;
}
.InternationalTeamComp_Fields{
    display: flex;
    /* justify-content: space-between; */
    gap: 24px;
    align-items: center;
}
.InternationalTeamComp_Fields1{
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    width: 340px;
    height: 170px;
    border-radius: 20px;
    margin-top: 24px;
    padding: 32px;

}
.InternationalTeamComp_contents{
    display: flex;
    flex-direction: column;
    align-items: center;
    
   
}
.InternationalTeamComp_Img{
    margin-top: 24px;
    height: 490px;
    width: 420px;
}
.InternationalTeamComp_Fields2{
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    width: 340px;
    height: 170px;
    border-radius: 20px;
    margin-top: 24px;
    padding: 32px;
}
.InternationalTeamComp_Fields_set{
    display: flex;
    gap: 24px;
    justify-content: space-between;
    align-items: center;
}
.InternationalTeamComp_title{
    font-family: Mulish;
font-size: 24px;
font-weight: 500;
line-height: 29px;
letter-spacing: 0.0025em;
text-align: left;
max-width: 300px;
max-height: 58px;
color: rgba(59, 59, 59, 1);

}
.InternationalTeamComp_desc{
max-width: 400px;
/* max-height: 80px; */
font-family: Mulish;
font-size: 16px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
color: rgba(132, 132, 132, 1);


}
.helpYouDesc {
    border-radius: 20px 0px 0px 20px;
    background: #F4F4F4;
    padding: 32px 52px;
    max-width: 790px;
    overflow: hidden;
}

.helpYouDesc::-webkit-scrollbar {
    display: none;
}
@media screen and (max-width: 890px) {
    .InternationalTeamComp_Fields {
        display: flex;
        justify-content: space-between;
        gap: 24px;
        flex-direction: column;
        align-items: center;
        width: 85%;
        margin: 0 auto;
    }
    .InternationalTeamComp_layout {
        margin: 0 auto;
        max-width: 1280px;
        padding: 16px;
    }
    .InternationalTeamComp_Fields1 {
        display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    width: 300px;
    height: 200px;
    border-radius: 20px;
    /* margin-top: 24px; */
    padding: 18px;

    }
    .InternationalTeamComp_Fields2 {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        width: 300px;
        height: 200px;
        /* border-radius: 20px; */
        margin-top: 24px;
        padding: 18px;
    
    }
    .InternationalTeamComp_head {
        font-family: Mulish;
        font-size: 32px;
        font-weight: 900;
        line-height: 40px;
        letter-spacing: 0.0023em;
        text-align: left;
        padding: 24px;
        border-radius: 20px;
    }
    .InternationalTeamComp_Fields_set{
        max-width: 1000px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
            gap: 0px;
            justify-content: space-between;
            align-items: center;
       
    }
    .InternationalTeamComp_Fields_set::-webkit-scrollbar {
        display: none;
    }
    .InternationalTeamComp_Img {
        margin-top: 0px;
        height: 545px;
        width: 340px;
    }
}
@media screen and (max-width: 480px) {
    .InternationalTeamComp_Fields {
        display: flex;
        justify-content: space-between;
        gap: 24px;
        flex-direction: column;
        align-items: center;
        width: 85%;
        margin: 0 auto;
    }
    .InternationalTeamComp_layout {
        margin: 0 auto;
        max-width: 1280px;
        padding: 16px;
    }
    .InternationalTeamComp_Fields1 {
        display: flex;
        /* justify-content: center; */
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        width: 300px;
        height: auto;
        border-radius: 20px;
        /* margin-top: 24px; */
        padding: 24px;

    }
    .InternationalTeamComp_Fields2 {
        display: flex;
        /* justify-content: center; */
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        width: 300px;
        height: auto;
        /* border-radius: 20px; */
        margin-top: 24px;
        padding: 24px;
    
    }
    .InternationalTeamComp_title {
        font-family: Mulish;
        font-size: 22px;
        font-weight: 500;
        line-height: 29px;
        letter-spacing: 0.0025em;
        text-align: left;
        max-width: 300px;
        max-height: 58px;
        color: rgba(59, 59, 59, 1);
    }
    .InternationalTeamComp_desc {
        max-width: 400px;
        /* max-height: 80px; */
        font-family: Mulish;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(132, 132, 132, 1);
    }
    .InternationalTeamComp_head {
        font-family: Mulish;
        font-size: 28px;
        font-weight: 900;
        line-height: 36px;
        letter-spacing: 0.0023em;
        text-align: left;
        padding: 24px;
        border-radius: 20px;
    }
    .InternationalTeamComp_Fields_set{
        max-width: 1000px;
        overflow: scroll;
        display: flex;
        flex-direction: column;
    }
    .InternationalTeamComp_Fields_set::-webkit-scrollbar {
        display: none;
    }
    .InternationalTeamComp_Img {
        margin-top: 0px;
        height: 545px;
        width: 340px;
    }
}
.VisaFinderOtion_layout{
    margin: 0 auto;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    margin-top: 72px;
    gap: 24px;
}
.VisaFinderOtion_content{
    display: flex;
    gap: 24px;
}
.VisaFinderOtion_Title{
    border-radius: 20px;
    padding: 32px;
    font-family: Mulish;
font-size: 56px;
font-weight: 900;
line-height: 78px;
letter-spacing: 0.0025em;
text-align: left;
color: rgba(1, 74, 145, 1);
    background: rgba(244, 244, 244, 1);
}
.VisaFinderOtion_content_box{
    width: 564px;
    height: 210px;
    border-radius: 20px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background: rgba(244, 244, 244, 1);

}
.VisaFinderOtion_content_box_tri{
    width: 345px;
    height: 270px;
    border-radius: 20px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background: rgba(244, 244, 244, 1);

}
.VisaFinderOtion_content_box_double{
    width: 560px;
    height: 220px;
    border-radius: 20px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background: rgba(244, 244, 244, 1);
}
.VisaFinderOtion2_content_box_tite {
    color: rgba(59, 59, 59, 1);
    font-family: Mulish;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0.0025em;
    text-align: left;
    width: 235px;
}
.VisaFinderOtion_content_box_tite{
    color: rgba(59, 59, 59, 1);
    font-family: Mulish;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0.0025em;
    text-align: left;
    
}
.VisaFinderOtion_content_box_desc{
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;
    text-align: left;
    color: rgba(132, 132, 132, 1);
    
}
.VisaFinderOtion2_content_box_desc {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;
    text-align: left;
    color: rgba(132, 132, 132, 1);
    width: 100%;
}
.VisaFinderOtion_content_box_btn{
    background: rgba(176, 56, 61, 1);
    width: 170px;
    cursor: pointer;
    padding: 10px 28px 10px 36px;
    border-radius: 16px;
    gap: 8px;
    font-family: Mulish;
    text-transform: uppercase;
font-size: 16px;
font-weight: 700;
display: flex;
justify-content: center;
align-items: center;
line-height: 20px;
letter-spacing: 0.0025em;
text-align: left;
color: rgba(241, 253, 255, 1);
    
}

@media screen and (max-width:890px) {
    .VisaFinderOtion_layout{
        margin: 0 auto;
        padding: 16px;
    }
    .VisaFinderOtion_content {
        display: flex;
        gap: 24px;
        flex-direction: column;
    }
    .VisaFinderOtion_content_box_tri {
        width: auto;
        height: auto;
        border-radius: 20px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        background: rgba(244, 244, 244, 1);
    }
    .VisaFinderOtion_content_box_double {
        width: auto;
        height: auto;
        border-radius: 20px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        background: rgba(244, 244, 244, 1);
    }
    .VisaFinderOtion_content_box {
        width: 93%;
        height: auto;
        border-radius: 20px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        background: rgba(244, 244, 244, 1);
    }
}
@media screen and (max-width:660px) {
    .VisaFinderOtion_content_box {
        width: 80%;
        height: auto;
        border-radius: 20px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        background: rgba(244, 244, 244, 1);
    }
    .VisaFinderOtion_Title {
        border-radius: 20px;
        padding: 24px;
        font-family: Mulish;
        font-size: 28px;
        font-weight: 900;
        /* line-height: 78px; */
        letter-spacing: 0.0025em;
        text-align: left;
        color: rgba(1, 74, 145, 1);
        background: rgba(244, 244, 244, 1);
    }
    .VisaFinderOtion_content_box_btn {
        background: rgba(176, 56, 61, 1);
        width: 120px;
        cursor: pointer;
        padding: 10px 20px 10px 20px;
        border-radius: 16px;
        gap: 8px;
        font-family: Mulish;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 20px;
        letter-spacing: 0.0025em;
        text-align: left;
        color: rgba(241, 253, 255, 1);
    }
}
@media screen and (max-width:480px) {
    .VisaFinderOtion_content_box {
        width: 85%;
        height: auto;
        border-radius: 20px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        background: rgba(244, 244, 244, 1);
    }
    .VisaFinderOtion_Title {
        border-radius: 20px;
        padding: 24px;
        font-family: Mulish;
        font-size: 28px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0.0025em;
        text-align: left;
        color: rgba(1, 74, 145, 1);
        background: rgba(244, 244, 244, 1);
    }
    .VisaFinderOtion_content_box_btn {
        background: rgba(176, 56, 61, 1);
        width: 120px;
        cursor: pointer;
        padding: 10px 20px 10px 20px;
        border-radius: 16px;
        gap: 8px;
        font-family: Mulish;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 20px;
        letter-spacing: 0.0025em;
        text-align: left;
        color: rgba(241, 253, 255, 1);
    }
    .VisaFinderOtion_content_box_tite {
    color: rgba(59, 59, 59, 1);
    font-family: Mulish;
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0.0025em;
    text-align: left;
}
}
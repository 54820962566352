.family494-layout {
    margin: 0 auto;
    max-width: 1280px;
    /* padding: 72px; */
    /* padding-bottom: 72px; */
}

.family494-headbox {
    border-radius: 20px;
    background: #F4F4F4;
    padding: 32px;
    margin-bottom: 24px;
}

.family494-heading {
    color: #014A91;
    font-family: Arial;
    font-size: 56px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%;
    letter-spacing: 0.14px;
}

.family494-headbox-layout {
    display: flex;
    gap: 24px;
    justify-content: space-between;
}

.family494-headbox1 {
    border-radius: 20px;
    background: #F4F4F4;
    padding: 32px;
    margin-bottom: 24px;
    width: 556px;
}

.family494-subheading {
    color: #3B3B3B;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
    margin-bottom: 24px;
}

.family494-subheading-desc {
    color: #848484;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04px;
}

@media screen and (max-width:890px) {
    .family494-layout {
        padding: 54px;
    }

    .family494-headbox {
        padding: 24px;
    }

    .family494-heading {
        font-size: 48px;
    }

    .family494-headbox1 {
        padding: 24px;
    }

    .family494-subheading {
        font-size: 18px;
    }

    .family494-subheading-desc {
        font-size: 14px;
    }
}

@media screen and (max-width:660px) {
    .family494-layout {
        padding: 16px;
    }

    .family494-headbox {
        padding: 16px;
    }

    .family494-heading {
        font-size: 24px;
    }

    .family494-headbox1 {
        padding: 16px;
        width: auto;
    }

    .family494-subheading {
        font-size: 16px;
    }

    .family494-subheading-desc {
        font-size: 12px;
    }

    .family494-headbox-layout{
        flex-direction: column;
        gap: 0px;
    }
}
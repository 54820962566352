.SkilledVisa189_part2_fields{
    display: flex;
    margin: 0 auto;
    max-width: 1280px;
    gap: 24px;
    margin-top: 24px;
    margin-bottom: 24px;
}
.SkilledVisa189_part2_Box{
    Width:566px;
    Height:auto;
    padding: 32px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background: rgba(244, 244, 244, 1);

}
.SkilledVisa189_part2_Box_desc{
    Width:566px;
    Height:auto; 
    padding: 32px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background: rgba(244, 244, 244, 1);
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;
    text-align: left;
    color: rgba(132, 132, 132, 1);
    
}
@media screen and (max-width: 880px) {
    .SkilledVisa189_part2_fields{
        display: flex;
        margin: 0 auto;
        flex-direction: column;
        gap: 24px;
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .SkilledVisa189_part2_Box {
        Width: 80%;
        Height: auto;
        padding: 24px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin: 0 auto  ;
        background: rgba(244, 244, 244, 1);
    }
    }
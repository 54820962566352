.Tss_head_container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}
.Tss_head{
    border-radius: 20px;
background: #F4F4F4;
padding: 32px;
color: #014A91;
font-size: 56px;
font-family: Mulish;
font-style: normal;
font-weight: 900;
line-height: 140%; /* 78.4px */
letter-spacing: 0.14px;
}
.TssHead_desc{
    padding: 32px;
    border-radius: 20px;
background: #F4F4F4;
width: 95%;
}
.TssHead_desc li{
    color: var(--Red, #B0383D);
font-size: 24px;
font-family: Mulish;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 33.6px */
letter-spacing: 0.06px;
}
.VisaHowLayout {
    margin: 0 auto;
    max-width: 1280px;
    padding: 0px 72px 72px 72px;
}

.VisaHowHeadBox {
    border-radius: 20px;
    background: #FFF7F1;
    padding: 32px;
}

.VisaHow-Image {
    width: 100%;
    border-radius: 20px;
}

.VisaHowImage {
    margin-top: 24px;
    margin-bottom: 24px;
    max-width: 1280px;
}

.VisaHow-SubLayout {
    border-radius: 20px;
    background: #FFF7F1;
    width: 563px;
    height: 90px;
    padding: 32px;
}

.VisaHowSubLayout {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
}

.VisaHowSubLayout2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    margin-top: 24px;
}

.VisaHowHeading {
    color: #B0383D;
    font-family: Arial;
    font-size: 57px;
    font-family: Mulish;
    font-style: normal;
    font-weight: 900;
    line-height: 140%;
    letter-spacing: 0.143px;
}

.VisaHowSubHead {
    color: #3B3B3B;
    font-family: Arial;
    font-size: 24px;
    font-family: Mulish;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
    margin-bottom: 16px;
}

.VisaHowDesc {
    color: #848484;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04px;
}

.VisaHowSubLayout3 {
    padding: 32px;
    border-radius: 20px;
    background: #FFF7F1;
    margin-top: 24px;
}

.VisaFindEmpHead {
    color: #3B3B3B;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
}

.VisaFindEmpDesc {
    color: #848484;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04px;
    margin-top: 24px;
    margin-bottom: 32px;
}

.VisaFindView {
    border: none;
    color: #F1FDFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.04px;
    text-transform: uppercase;
    background: none;
}

.VisaFindEmpButton {
    border-radius: 128px;
    background: #B0383D;
    display: inline-flex;
    padding: 10px 36px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

@media screen and (max-width: 890px) {
    .VisaHowLayout {
        padding: 0px 54px 54px 54px;
    }

    .VisaHow-SubLayout {
        height: 150px;
    }

    .VisaHowHeadBox,
    .VisaHow-SubLayout {
        padding: 24px;
    }

    .VisaHowHeading {
        font-size: 48px;
    }

    .VisaHowSubHead {
        font-size: 18px;
    }

    .VisaHowDesc {
        font-size: 14px;
    }

    .VisaHowSubLayout3 {
        padding: 24px;
    }

    .VisaFindEmpHead {
        font-size: 18px;
    }

    .VisaFindEmpDesc {
        font-size: 12px;
        margin-top: 18px;
        margin-bottom: 24px;
    }

    .VisaFindView {
        font-size: 12px;
    }

    .VisaFindEmpButton {
        padding: 10px 24px;
    }
}

@media screen and (max-width: 660px) {
    .VisaHowLayout {
        padding: 0px 16px 16px 16px;
    }

    .VisaHow-SubLayout {
        height: 150px;
    }

    .VisaHowHeadBox,
    .VisaHow-SubLayout {
        padding: 16px;
    }

    .VisaHowHeading {
        font-size: 28px;
    }

    .VisaHowSubHead {
        font-size: 22px;
        margin-bottom: 12px;
    }

    .VisaHowDesc {
        font-size: 16px;
    }

    .VisaHowSubLayout,
    .VisaHowSubLayout2 {
        flex-wrap: wrap;
    }

    .VisaHow-SubLayout {
        height: auto;
    }

    .VisaHowSubLayout3 {
        padding: 16px;
    }

    .VisaFindEmpHead {
        font-size: 16px;
    }

    .VisaFindEmpDesc {
        margin-top: 14px;
        margin-bottom: 18px;
    }

    .VisaFindEmpButton {
        padding: 10px 16px;
    }
}
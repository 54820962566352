.NzFamily_content{
    width: 340px;
height: 180px;
flex-shrink: 0;
border-radius: 20px;
background: #F4F4F4;
display: flex;
flex-direction: column;
gap: 24px;
}
@media screen and (max-width: 880px) {
.NzFamily_content {
    width: 80%;
    height: auto;
    flex-shrink: 0;
    border-radius: 20px;
    background: #F4F4F4;
    display: flex;
    flex-direction: column;
    gap: 24px;
}
}
@media screen and (max-width: 480px) {
    .NzFamily_content {
        width: 80%;
        height: auto;
        flex-shrink: 0;
        border-radius: 20px;
        background: #F4F4F4;
        display: flex;
        flex-direction: column;
        gap: 24px;

    }
    }
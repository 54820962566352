.Conatct_us_container{
    display: flex;
    margin: 0 auto;
    gap: 24px;
  max-width: 1280px;
  padding: 36px 72px;
}

.Conatct_us_container_admin{
    display: flex;
    margin: 0 auto;
    gap: 24px;
    flex-direction: column;
  max-width: 1280px;
  padding: 36px 72px;   
}
.Conatct_us_container_link {
    display: flex;
    margin: 0 auto;
    gap: 24px;
    max-width: 1280px;
    padding: 36px 72px;
    flex-direction: column;
}
.Links_conatiner_fields{
    display: flex;
    /* align-items: flex-start; */
    flex-direction: column;
    gap: 4px;
    text-align: left;
    /* margin-bottom: 18px; */
}
.admin_links_head{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

}
.Conatct_us_container_left{
    background: rgba(255, 247, 241, 1);
    padding: 32px;
    color: rgba(176, 56, 61, 1);
    font-family: Mulish;
    font-size: 52px;
    font-weight: 900;
    line-height: 60px;
    letter-spacing: 0.0025em;
    text-align: left;
    width: 299px;
    height: 440px;
    border-radius: 20px;

}
.Conatct_us_container_right{
    background: rgba(255, 247, 241, 1);
    padding: 32px;
    color: rgba(176, 56, 61, 1);
    font-family: Mulish;
font-size: 48px;
font-weight: 900;
line-height: 70px;
letter-spacing: 0.0025em;
text-align: left;
width: 900px;
height: 440px;
border-radius: 20px;

}
.Conatct_us_container_right_admin {
    background: rgba(255, 247, 241, 1);
    padding: 32px;
    color: rgba(176, 56, 61, 1);
    font-family: Mulish;
    font-size: 48px;
    font-weight: 900;
    line-height: 70px;
    letter-spacing: 0.0025em;
    text-align: left;
    /* width: 900px; */
    height: auto;
    border-radius: 20px;
}
.Conatct_us_container_left_admin {
    background: rgba(255, 247, 241, 1);
    padding: 32px;
    color: rgba(176, 56, 61, 1);
    font-family: Mulish;
    font-size: 52px;
    font-weight: 900;
    line-height: 78px;
    letter-spacing: 0.0025em;
    text-align: left;
    /* width: 299px; */
    height: auto;
    border-radius: 20px;
}
.Link_container_left{
    background: rgba(255, 247, 241, 1);
    padding: 32px;
    color: rgba(176, 56, 61, 1);
    font-family: Mulish;
font-size: 52px;
font-weight: 900;
line-height: 78px;
letter-spacing: 0.0025em;
text-align: left;
height: auto;
border-radius: 20px;

}
.Link_container_right{
    background: rgba(255, 247, 241, 1);
    padding: 32px;
    color: rgba(176, 56, 61, 1);
    font-family: Mulish;
font-size: 48px;
font-weight: 900;
line-height: 70px;
letter-spacing: 0.0025em;
text-align: left;
height: auto;
border-radius: 20px;

}
.Link_container_left2{
    background: rgba(244, 244, 244, 1);
    padding: 32px;
    color:  rgba(4, 85, 144, 1);
    font-family: Mulish;
font-size: 52px;
font-weight: 900;
line-height: 78px;
letter-spacing: 0.0025em;
text-align: left;
height: auto;
border-radius: 20px;

}
.Link_container_right2{
    background: rgba(244, 244, 244, 1);
    padding: 32px;
    color: rgba(4, 85, 144, 1);
    font-family: Mulish;
font-size: 48px;
font-weight: 900;
line-height: 70px;
letter-spacing: 0.0025em;
text-align: left;
height: auto;
border-radius: 20px;

}
.Help-conatiner {
    display: flex;
    flex-direction: column;
    gap: 56px;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto;
}

.help-screen-contact-img {
    display: flex;
    width: 690px;
    height: 496px;
    padding: 0px 0.007px 0px 0.001px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.Help-container-contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}

.help-container-title {
    color: #000;
    font-family: Mulish;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-transform: uppercase;
    width: 486px;
    color: rgba(0, 0, 0, 1);
}

.submit-button-help {
    display: flex;
    width: 180px;
    padding: 10px 36px 10px 36px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    text-transform: uppercase;
    border-radius: 79px;
    background: rgba(176, 56, 61, 1);
    color: white;
    border: none;
    height: 40px;
}

.help-screen-form {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    gap: 23px;
}

.Help-container-form {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto;
    margin-bottom: 1rem;
}

.help-form-textField {
    display: flex;
    width: 690px;
    padding: 16px 24px;
    font-family: Mulish;
    align-items: center;
    gap: 8px;
    border-radius: 104px;
    border: 1px solid rgba(0, 0, 0, 0.40);
    color: rgba(0, 0, 0, 1);
    background: none;
}
.help-form-textField_area{
    display: flex;
    width: 660px;
    padding: 8px 12px 8px 16px;
    height: 120px;
    font-family: Mulish;
    align-items: center;
    gap: 8px;
    border-radius: 20px;
    font-size: 16px;
    border: 1px solid rgba(0, 0, 0, 0.40);
    color: rgba(0, 0, 0, 1);
    background: none;
    resize: none;
}
.help-form-textarea{
    display: flex;
    width: 100%;
    padding: 16px 24px;
    height: 300px;
    font-family: Mulish;
    align-items: center;
    gap: 8px;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.40);
    color: rgba(0, 0, 0, 1);
    background: none;
    resize: none;
}

.Form-textArea {
    display: flex;
    width: 690px;
    height: 118px;
    padding: 16px 24px;
    align-items: flex-start;
    gap: 8px;
    font-family: Mulish;
    border-radius: 20px;
    background: none;
    border: 1px solid rgba(0, 0, 0, 0.40);
    color: rgba(0, 0, 0, 1);
    resize: none;
}
/* Add these styles to your CSS file */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .delete-modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    border: none;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
  }
  .delete-modal p{
    font-size: 20px;
    font-weight: 400;
    color:  rgba(132, 132, 132, 1);
  }
  .sure_btns{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 18px;
  }
  .sure_cancel{
  border: 1px solid rgba(176, 56, 61, 1);
  color: rgba(176, 56, 61, 1);
  border-radius: 20px;
  width: 100px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  }
  .sure_ok{
    background: rgba(176, 56, 61, 1);
    color: white;
    border: none;
    width: 100px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    font-size: 16px;
    height: 32px;
    padding: 10px;
  }
  .delete-modal button {
    margin-right: 10px;
  }
  
  .help-form-textField
.css-1v4ccyo {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    cursor: text;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    border-radius: 122px;
    width: 680px;
}
  .help-form-textField
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 122px;
    width: 685px;
    border: 1px solid rgba(0, 0, 0, 0.4) !important;
}

@media screen and (max-width: 1024px) {
    .Help-conatiner {
        gap: 30px;
        width: 100%;
    }
.Help-container-contact{
    width: 100%;
    margin: 0;
    padding: 1rem;
}
.Help-container-form{
    width: 100%;
    margin: 0;
    padding: 1rem;
}
    .help-container-title {
        width: 65%; 
        font-size: 24px;
    }

    .help-screen-contact-img {
        width: 50%; 
        height: auto;
    }

    .help-form-textField,
    .help-form-textarea,
    .Form-textArea {
        width: 80% 
    }
    .help-form-textField_area {
        display: flex;
        width: 370px;
        padding: 8px 12px 8px 16px;
        height: 120px;
        font-family: Mulish;
        align-items: center;
        gap: 8px;
        border-radius: 20px;
        font-size: 16px;
        border: 1px solid rgba(0, 0, 0, 0.40);
        color: rgba(0, 0, 0, 1);
        background: none;
        resize: none;
    }
    .help-screen-form{
        width: 75%;
    }
    .Conatct_us_container_left {
        background: rgba(255, 247, 241, 1);
        padding: 24px;
        color: rgba(176, 56, 61, 1);
        font-family: Mulish;
        font-size: 40px;
        font-weight: 900;
        line-height: 78px;
        letter-spacing: 0.0025em;
        text-align: left;
        width: 95%;
        height: auto;
        border-radius: 20px;
    }
    .Conatct_us_container_right{
        width: 95%;
        height: auto;
        padding: 24px;
    
    }
    .Link_container_left {
        background: rgba(255, 247, 241, 1);
        padding: 24px;
        color: rgba(176, 56, 61, 1);
        font-family: Mulish;
        font-size: 40px;
        font-weight: 900;
        line-height: 78px;
        letter-spacing: 0.0025em;
        text-align: left;
        width: 95%;
        height: auto;
        border-radius: 20px;
    }
    .Link_container_right{
        width: 95%;
        height: auto;
        padding: 24px;
    
    }
    .Conatct_us_container {
        display: flex;
        margin: 0 auto;
        gap: 24px;
        flex-direction: column;
        padding: 36px;
    }
      
  .help-form-textField
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 122px;
    width: 600px;
    border: 1px solid rgba(0, 0, 0, 0.4) !important;
}
    
}
@media screen and (max-width: 880px) {
.Conatct_us_container_left {
    background: rgba(255, 247, 241, 1);
    padding: 32px;
    color: rgba(176, 56, 61, 1);
    font-family: Mulish;
    font-size: 42px;
    font-weight: 900;
    line-height: 78px;
    letter-spacing: 0.0025em;
    text-align: left;
    /* width: 299px; */
    height: 440px;
    border-radius: 20px;
}
.Conatct_us_container {
    display: flex;
    margin: 0 auto;
    gap: 24px;
    flex-direction: column;
    padding: 36px;
}
.Conatct_us_container_left {
    background: rgba(255, 247, 241, 1);
    padding: 24px;
    color: rgba(176, 56, 61, 1);
    font-family: Mulish;
    font-size: 40px;
    font-weight: 900;
    line-height: 78px;
    letter-spacing: 0.0025em;
    text-align: left;
    width: 95%;
    height: auto;
    border-radius: 20px;
}
.Conatct_us_container_right{
    width: 95%;
    height: auto;
    padding: 24px;

}
.Conatct_us_container_link {
    display: flex;
    margin: 0 auto;
    gap: 24px;
    max-width: 1280px;
    padding: 16px;
    flex-direction: column;
}
  
.help-form-textField
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 122px;
  width: 400px;
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
}
.help-form-textField .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 122px;
    width: 415px;
}
}
@media screen and (max-width: 480px) {
    .Help-conatiner {
        gap: 20px;
    }

    .Help-container-contact,
    .Help-container-form,
    .help-screen-form,
    .help-form-textarea,
    .help-form-textField,
    .Form-textArea {
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        justify-content: center;
        align-items: flex-start;
    }

    .help-screen-contact-img {
        width: 100%;
        height: auto;
    }
    .help-container-title {
        width: 80%; 
        font-size: 20px;
    }
    .help-form-textField,
    .Form-textArea {
        width: 225px; 
    }
    .Conatct_us_container {
        padding: 16px
    }
    .Conatct_us_container_left {
        background: rgba(255, 247, 241, 1);
        padding: 24px;
        color: rgba(176, 56, 61, 1);
        font-family: Mulish;
        font-size: 42px;
        font-weight: 900;
        line-height: 78px;
        letter-spacing: 0.0025em;
        text-align: left;
        width: 85%;
        height: auto;
        border-radius: 20px;
    }
    .Conatct_us_container_right{
        width: 85%;
        height: auto;
        padding: 24px;
    }
    .Conatct_us_container_admin {
        display: flex;
        margin: 0 auto;
        gap: 24px;
        flex-direction: column;
        padding: 16px;
    }
    .Conatct_us_container_link {
        display: flex;
        margin: 0 auto;
        gap: 24px;
        max-width: 1280px;
        padding: 16px;
        flex-direction: column;
    }
    .help-form-textField_area {
        display: flex;
        width: 250px;
        padding: 8px 12px 8px 16px;
        height: 120px;
        font-family: Mulish;
        align-items: center;
        gap: 8px;
        border-radius: 20px;
        font-size: 16px;
        border: 1px solid rgba(0, 0, 0, 0.40);
        color: rgba(0, 0, 0, 1);
        background: none;
        resize: none;
    }
  .help-form-textField
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 122px;
    width: 280px;
    border: 1px solid rgba(0, 0, 0, 0.4) !important;
}
.help-form-textField 
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 122px;
    width: 280px;
}
}

/* Base layout styles */
.LabourAgreement_layout {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .LabourAgreement_layout_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding-top: 24px;
  }
  
  /* Description styles */
  .LabourAgreement_Desc {
    width: 95%; /* Adjusted for responsiveness */
    padding: 32px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #FFF7F1;
    color: #3B3B3B;
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.06px;
  }
  
  .LabourAgreement_Desc li {
    color: #848484;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.04px;
  }
  
  /* Section styles */
  .LabourAgreement_section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding-top: 24px;
    margin: 0 auto;
  }
  
  .LabourAgreement_section-container {
    border-radius: 20px;
    background: #FFF7F1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 32px;
    gap: 24px;
    flex-shrink: 0;
    width: 345px;
    height: 195px;
  }
  
  .LabourAgreement_section-container_title {
    color: #3B3B3B;
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
  }
  
  .LabourAgreement_section-container_desc {
    color: #848484;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.04px;
  }
  
  /* Extra layout styles */
  .LabourAgreement_extrayLay {
    width: 95%; /* Adjusted for responsiveness */
    border-radius: 20px;
    background: #FFF7F1;
    margin: 0 auto;
    padding: 32px;
    margin-top: 24px;
  }
  .LabourAgreement_section-container_desc_extrayLay{
    color: #848484;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 125% */
    letter-spacing: 0.04px;
    margin-top: 24px;
}
@media screen and (max-width: 1280px) {
.LabourAgreement_extrayLay {
  width: 90%;
  border-radius: 20px;
  background: #FFF7F1;
  margin: 0 auto;
  padding: 24px;
  margin-top: 24px;
}
}
  /* Media queries for responsive design */
  @media screen and (max-width: 768px) {
    .LabourAgreement_Desc {
      padding: 24px;
      font-size: 20px;
    }
    .LabourAgreement_section{
      flex-direction: column;
    }
  
    .LabourAgreement_section-container {
      width: 91%;
      padding: 24px;
    }
  
    .LabourAgreement_extrayLay {
      padding: 24px;
    }
    .LabourAgreement_section-container_desc {
        color: #848484;
        
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.04px;
    }
    .LabourAgreement_section-container_desc_extrayLay{
        /* padding: 24px; */
        margin-top: 24px;
    }
    .LabourAgreement_section {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 24px;
      padding-top: 24px;
      margin: 0 auto;
      max-width: 900px;
      overflow: scroll;
  }
  .LabourAgreement_section::-webkit-scrollbar{
    display: none;
  }
  }
  @media screen and (max-width: 880px) {
  .LabourAgreement_section {
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    gap: 24px;
    padding-top: 24px;
    margin: 0 auto;
    /* max-width: 700px; */
    /* width: 90%; */
    overflow: scroll;
}
.LabourAgreement_Desc {
  width: 90%;
  padding: 25px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #FFF7F1;
  color: #3B3B3B;
  font-family: Mulish;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.06px;
}
.LabourAgreement_section::-webkit-scrollbar{
  display: none;
}
  }
  @media screen and (max-width: 480px) {
    .LabourAgreement_Desc {
      padding: 24px;
      width: 80%;
      font-size: 16px;
    }
  
    .LabourAgreement_section-container {
      padding: 24px;
      font-size: 20px;
      margin: 0 auto;
      height: auto;
      width: 80%;
    }
  
    .LabourAgreement_extrayLay {
     padding: 24px;
     width: 80%;
    }
    .LabourAgreement_section-container_desc_extrayLay{
        /* padding: 24px; */
        margin-top: 24px;
    }
  }
  
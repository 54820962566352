.Visa190_tri_Field{
    display: flex;
    margin: 0 auto;
    gap: 24px;
    max-width: 1280px;
}
.Visa190_tri_Container{
    padding: 32px;
    border-radius: 20px;
    background: rgba(244, 244, 244, 1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 363px;
    height: 270px;
    margin-top: 24px;
    gap: 24px;
}
.Visa190_tri_Container_title{
    color: rgba(59, 59, 59, 1);
    font-family: Mulish;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.0025em;
    text-align: left;
    
}
.Visa190_tri_Container_desc{
    color: rgba(132, 132, 132, 1);
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;
    text-align: left;
}
.Visa190_tri_overlay{
    padding: 32px;
    border-radius: 20px;
    background: rgba(244, 244, 244, 1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 24px;
    gap: 24px;
}
.Visa190_tri_Container_Extended{
    padding: 32px;
    border-radius: 20px;
    background: rgba(244, 244, 244, 1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 24px;
    width:770px;
    height: 270px;
    gap: 24px;
}
@media screen and (max-width: 1280px) {
.Visa190_tri_Field {
    display: flex;
    margin: 0 auto;
    gap: 24px;
     flex-direction: column;
}
.Visa190_tri_Container {
    padding: 24px;
    border-radius: 20px;
    background: rgba(244, 244, 244, 1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
    height: auto;
    margin: 0 auto;
    margin-top: 24px;
    gap: 24px;
}
.Visa190_tri_overlay {
    padding: 24px;
    border-radius: 20px;
    background: rgba(244, 244, 244, 1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 24px;
    gap: 24px;
    width: 80%;
    margin: 0 auto;
    margin-top: 24px;
}
.Visa190_tri_Container_Extended{
    padding: 24px;
    border-radius: 20px;
    background: rgba(244, 244, 244, 1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
    margin: 0 auto;
    margin-top: 24px;
    height: auto;
    gap: 24px;
}
}
@media screen and (max-width: 880px) {
    .Visa190_tri_Field {
        display: flex;
        margin: 0 auto;
        gap: 24px;
         flex-direction: column;
    }
    .Visa190_tri_Container {
        padding: 24px;
        border-radius: 20px;
        background: rgba(244, 244, 244, 1);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 80%;
        height: auto;
        margin: 0 auto;
        margin-top: 24px;
        gap: 24px;
    }
    .Visa190_tri_overlay {
        padding: 24px;
        border-radius: 20px;
        background: rgba(244, 244, 244, 1);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 24px;
        gap: 24px;
        width: 80%;
        margin: 0 auto;
        margin-top: 24px;
    }
    .Visa190_tri_Container_Extended{
        padding: 24px;
        border-radius: 20px;
        background: rgba(244, 244, 244, 1);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 80%;
        margin: 0 auto;
        margin-top: 24px;
        height: auto;
        gap: 24px;
    }
    }
    @media screen and (max-width: 480px) {
        .Visa190_tri_Field {
            display: flex;
            margin: 0 auto;
            gap: 24px;
             flex-direction: column;
        }
        .Visa190_tri_Container {
            padding: 24px;
            border-radius: 20px;
            background: rgba(244, 244, 244, 1);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 80%;
            height: auto;
            margin: 0 auto;
            margin-top: 24px;
            gap: 24px;
        }
        .Visa190_tri_overlay {
            padding: 24px;
            border-radius: 20px;
            background: rgba(244, 244, 244, 1);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 24px;
            gap: 24px;
            width: 80%;
            margin: 0 auto;
            margin-top: 24px;
        }
        .Visa190_tri_Container_Extended{
            padding: 24px;
            border-radius: 20px;
            background: rgba(244, 244, 244, 1);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 80%;
            margin: 0 auto;
            margin-top: 24px;
            height: auto;
            gap: 24px;
        }
        }
.footer_container {
    max-width: 1280px;
    padding-top: 80px;
    padding-bottom: 24px;
    margin: 0 auto;
    background: #FFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    font-family: Mulish;
}
.Social_links{
  display: flex;
  gap: 12px;
  align-items: flex-start;
}
    .footer_container_logo{
        display: flex;
        flex-direction: column;
        gap: 9px;
    }
    .footer_logo{
        width: 102px;
    height: 40px;
    cursor: pointer;
    flex-shrink: 0;
    }
    .footer_about_container{
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
    }
    .input-container {
        display: flex;
        align-items: center;
        position: relative;
      }
      
      .email-address-field {
        width: 296px;
        height: 30px;
        border-radius: 72px;
        background: rgba(236, 230, 240, 0.40);
        padding: 6px 13px;
        border: none;
      }
      .submit-btn-footer_Latest{
        width: 125px;
        height: 40px;
        border-radius: 72px;
        background: #B0383D;
        border: none;
        color: #FFF;
        cursor: pointer;
        text-transform: uppercase;
      }
      .submit-btn-footer {
        width: 125px;
        height: 40px;
        border-radius: 72px;
        background: #B0383D;
        border: none;
        color: #FFF;
        cursor: pointer;
        text-transform: uppercase;
        margin-left: -125px; /* Adjust this value as needed */
      }
      
    .footer-span {
        color: #096FB9;
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: 24px;
        /* cursor: pointer; */
        /* margin-bottom: 10px; */
        margin-left: 5px;
      }
      .Footer_contents_field{
        display: flex;
        align-items: center;
        gap: 8px;
      }
      @media screen and (max-width: 880px) {
      .footer_container {
        padding: 16px;
        margin: 0 auto;
        background: #FFF;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        flex-direction: column;
        gap: 1rem;
    }
    .footer_about_container {
      display: inline-flex;
      align-items: flex-start;
      gap: 16px;
      flex-direction: column;
  }
  .Footer_contents_field{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
  }
  
}
@media screen and (max-width: 480px) {
  .footer_container {
    padding: 16px;
    margin: 0 auto;
    background: #FFF;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
}
.footer_about_container {
  display: inline-flex;
  gap: 18px;
  justify-content: space-between;
  flex-direction: row;
  width: 94%;
}
.Footer_contents_field{
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
}
.footer_links_bottom{
  border:none !important;
}
}
      
.TssOption4_heading{
    border-radius: 20px;
    background: #FFF7F1;
    padding: 32px;
    color: var(--Blue, #014A91);
    font-family: Mulish;
    font-size: 57px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%; /* 79.8px */
    letter-spacing: 0.143px;
}
.Tss_Eligibility_fields_end_option4{
    border-radius: 20px;
background: #F4F4F4;
padding: 32px;
display: flex;
justify-content: flex-start;
gap: 24px;
}
.Tss_Eligibility_fields_container_option4{
    border-radius: 20px;
    background: #F4F4F4;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
 
}

.VisaFamily_Box_desc{
color: #848484;
font-family: Mulish;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 25px; 
letter-spacing: 0.04px;}
.Tss_Eligibility_fields_end_option4{
    border-radius: 20px;
    background: #F4F4F4;
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 95%;
    color: #848484;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: 0.04px;
}
.Tss_Eligibility_fields_end_option4 li{
    color: rgba(59, 59, 59, 1);
    font-family: Mulish;
    font-size: 24px;
 
}
@media screen and (max-width: 1024px) {
    .TssOption4_heading {
        border-radius: 20px;
        background: #F4F4F4;
        padding: 28px;
        color: #B0383D;
        
        font-size: 42px;
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.143px;
        width: 80%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 880px) {
    .TabsContainer_option1 {
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 12px;
        max-width: 1000px;
        margin: 0 auto;
        margin-bottom: 42px;
        margin-top: 42px;
        overflow: scroll;
      }
      .Tss_Eligibility_fields {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        flex-direction: column;
    }
    .Tss_Eligibility_fields_container_option4{
        padding: 24px;
        border-radius: 20px;
        background: #F4F4F4;
        width: 80%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }
    .Tss_Eligibility_fields_end_option4 {
        border-radius: 20px;
        background: #F4F4F4;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 80%;
    }
      .Tab {
        color: rgba(0, 0, 0, 0.25);
        min-width: 150px;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        text-align: center;
        line-height: 48px;
        /* min-width: 170px; */
        min-width: 200px;
        letter-spacing: 0.06px;
      }
      .Tab.isActive {
        color: #B0383D;
        min-width: 150px;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        text-align: center;
        line-height: 48px;
        min-width: 200px;
        letter-spacing: 0.06px;
        border-bottom: 1px solid #B0383D;
      }
      .TabsContainer_option1::-webkit-scrollbar{
        display: none;
    }.TssOption4_heading {
        border-radius: 20px;
        background: #F4F4F4;
        padding: 24px;
        color: #B0383D;
        
        font-size: 32px;
    
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.143px;
        width: 80%;
        margin: 0 auto;
    }
    .Tss_Overview_container_option2 {
        border-radius: 20px;
        background: #F4F4F4;
        padding: 24px;
        width: 80%;
        margin: 0 auto;
    }
    .Tss_Overview_container_option2 li {
        color: #3B3B3B;
        
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.06px;
    }
}
@media screen and (max-width: 780px) {
.TssOption4_heading {
    border-radius: 20px;
    background: #F4F4F4;
    padding: 24px;
    color: #B0383D;
    
    font-size: 32px;

    font-style: normal;
    font-weight: 900;
    line-height: 140%;
    letter-spacing: 0.143px;
    width: 80%;
    margin: 0 auto;
}.Tss_Overview_container_option2 {
    border-radius: 20px;
    background: #F4F4F4;
    padding: 24px;
    width: 80%;
    margin: 0 auto;
}
.Tss_Overview_container_option2 li {
    color: #3B3B3B;
    
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.06px;
}
.Tss_Eligibility_fields {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    flex-direction: column;
}
.Tss_Eligibility_fields_container_option4{
    padding: 24px;
    border-radius: 20px;
    background: #F4F4F4;
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}
.Tss_Eligibility_fields_end_option4 {
    border-radius: 20px;
    background: #F4F4F4;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 80%;
}
}
@media screen and (max-width: 750px) {
    .TssOption4_heading {
        border-radius: 20px;
        background: #F4F4F4;
        padding: 24px;
        color: #B0383D;
        
        font-size: 32px;
    
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.143px;
        width: 80%;
        margin: 0 auto;
    }
    .Tss_Overview_container_option2 {
        border-radius: 20px;
        background: #F4F4F4;
        padding: 24px;
        width: 80%;
        margin: 0 auto;
    }
    .Tss_Overview_container_option2 li {
        color: #3B3B3B;
        
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.06px;
    }
    .Tss_Eligibility_fields {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        flex-direction: column;
    }
    .Tss_Eligibility_fields_container_option4{
        padding: 24px;
        border-radius: 20px;
        background: #F4F4F4;
        width: 80%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }
    .Tss_Eligibility_fields_end_option4 {
        border-radius: 20px;
        background: #F4F4F4;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 80%;
    }
}
@media screen and (max-width: 660px) {
    .TssOption4_heading {
        border-radius: 20px;
        background: #F4F4F4;
        padding: 24px;
        color: #B0383D;
        
        font-size: 32px;
    
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.143px;
        width: 80%;
        margin: 0 auto;
    }
    .Tss_Overview_container_option2 {
        border-radius: 20px;
        background: #F4F4F4;
        padding: 24px;
        width: 80%;
        margin: 0 auto;
    }
    .Tss_Overview_container_option2 li {
        color: #3B3B3B;
        
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.06px;
    }
    .Tss_Eligibility_fields {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        flex-direction: column;
    }
    .Tss_Eligibility_fields_container_option4{
        padding: 24px;
        border-radius: 20px;
        background: #F4F4F4;
        width: 80%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }
    .Tss_Eligibility_fields_end_option4 {
        border-radius: 20px;
        background: #F4F4F4;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 80%;
    }
}
@media screen and (max-width: 550px) {
    .TssOption4_heading {
        border-radius: 20px;
        background: #F4F4F4;
        padding: 24px;
        color: #B0383D;
        
        font-size: 32px;
    
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.143px;
        width: 80%;
        margin: 0 auto;
    }
    .Tss_Overview_container_option2 {
        border-radius: 20px;
        background: #F4F4F4;
        padding: 24px;
        width: 80%;
        margin: 0 auto;
    }
    .Tss_Overview_container_option2 li {
        color: #3B3B3B;
        
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.06px;
    }
    .Tss_Eligibility_fields {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        flex-direction: column;
    }
    .Tss_Eligibility_fields_container_option4{
        padding: 24px;
        border-radius: 20px;
        background: #F4F4F4;
        width: 80%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }
    .Tss_Eligibility_fields_end_option4 {
        border-radius: 20px;
        background: #F4F4F4;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 80%;
    }
}
@media screen and (max-width: 480px) {
    .TssOption4_heading {
        border-radius: 20px;
        background: #F4F4F4;
        padding: 24px;
        color: #B0383D;
        
        font-size: 32px;
    
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.143px;
        width: 80%;
        margin: 0 auto;
    }
    .Tss_Overview_container_option2 {
        border-radius: 20px;
        background: #F4F4F4;
        padding: 24px;
        width: 80%;
        margin: 0 auto;
    }
    .Tss_Overview_container_option2 li {
        color: #3B3B3B;
        
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.06px;
    }
    .Tss_Eligibility_fields {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        flex-direction: column;
    }
    .Tss_Eligibility_fields_container_option4{
        padding: 24px;
        border-radius: 20px;
        background: #F4F4F4;
        width: 80%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }
    .Tss_Eligibility_fields_end_option4 {
        border-radius: 20px;
        background: #F4F4F4;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 80%;
    }
}

.Skill_Exemptions_detail_fields{
    display: flex;
    justify-content: center;
    gap: 24px;
    align-items: center;
    margin-top: 24px;
}
.Skill_Exemptions_detail_container{
    background: #F4F4F4;
    padding: 32px;
    border-radius: 20px;
    display: flex;
    height: 360px;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
}
@media screen and (max-width: 880px) {
    .Skill_Exemptions_detail_fields {
        display: flex;
        justify-content: center;
        gap: 24px;
        flex-direction: column;
        align-items: center;
        margin-top: 24px;
    }
    .Skill_Exemptions_detail_container {
        background: #F4F4F4;
        padding: 24px;
        border-radius: 20px;
        display: flex;
        height: auto;
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;
        margin: 0 auto;
        width: 80%;
    }
    }
@media screen and (max-width: 480px) {
.Skill_Exemptions_detail_fields {
    display: flex;
    justify-content: center;
    gap: 24px;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
}
.Skill_Exemptions_detail_container {
    background: #F4F4F4;
    padding: 24px;
    border-radius: 20px;
    display: flex;
    height: auto;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    margin: 0 auto;
    width: 80%;
}
}

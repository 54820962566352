.OverView494-layout {
    margin: 0 auto;
    max-width: 1280px;
    /* padding: 72px; */
    /* padding-bottom: 72px; */
}

.overview494-headbox {
    border-radius: 20px;
    background: #F4F4F4;
    padding: 32px;
    margin-bottom: 24px;
}

.overview494-heading {
    color: #014A91;
    font-family: Arial;
    font-size: 56px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%;
    letter-spacing: 0.14px;
}

.overview494-head-content {
    color: #3B3B3B;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.06px;
}

.overview494-head-content-two {
    color: #848484;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.04px;
}

.overview494-headbox1 {
    border-radius: 20px;
    background: #FFF7F1;
    padding: 32px;
    margin-bottom: 24px;
}

.overview494-heading1 {
    color: #B0383D;
    font-family: Arial;
    font-size: 57px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%;
    letter-spacing: 0.143px;
}

.overview494-head-content1 {
    color: #3B3B3B;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.06px;
}

.overview494-head-content-two1 {
    color: #3B3B3B;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.06px;
}

.overview494-sublayout-two,
.overview494-sublayout-three {
    margin-top: 72px;
}

@media screen and (max-width:890px) {
    .OverView494-layout {
        padding: 54px;
    }

    .overview494-headbox,
    .overview494-headbox1 {
        padding: 24px;
    }

    .overview494-heading,
    .overview494-heading1 {
        font-size: 48px;
    }

    .overview494-head-content,
    .overview494-head-content1,
    .overview494-head-content-two1 {
        font-size: 18px
    }

    .overview494-head-content-two {
        font-size: 14px;
    }

    .overview494-sublayout-two,
    .overview494-sublayout-three {
        margin-top: 32px;
    }

}

@media screen and (max-width:660px) {

    .OverView494-layout,
    .overview494-headbox,
    .overview494-headbox1 {
        padding: 16px;
    }

    .overview494-heading, .overview494-heading1 {
        font-size: 24px;
    }

    .overview494-head-content, .overview494-head-content1 {
        font-size: 16px
    }

    .overview494-head-content-two, .overview494-head-content-two1 {
        font-size: 12px;
    }

    .overview494-sublayout-two,
    .overview494-sublayout-three {
        margin-top: 24px;
    }

}
.VisaFinderNotSure_layout{
 margin: 0 auto;
 max-width: 1280px;
 display: flex;
 margin-top: 72px;
 gap: 24px;
}
.VisaFinderNotSure_layout_content{
    background: rgba(255, 247, 241, 1);
    padding: 32px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 564px;
    height: 345px;
}
.VisaFinderNotSure_layout_img_container{
    width: 50%;
    height: 100%;
}
.VisaFinderNotSure_layout_img{
    width: 630px;
    height: 410px;
    box-sizing: 20px;
}


.VisaFinderNotSure_layout_title{
    font-family: Mulish;
font-size: 48px;
font-weight: 900;
line-height: 67px;
letter-spacing: 0em;
text-align: left;
color: rgba(176, 56, 61, 1);

}
.VisaFinderNotSure_layout_desc{
    font-family: Mulish;
font-size: 24px;
font-weight: 400;
line-height: 34px;
letter-spacing: 0em;
text-align: left;
color: rgba(176, 56, 61, 1);

}
.VisaFinderNotSure_layout_btn{
    background: rgba(176, 56, 61, 1);
   color: rgba(241, 253, 255, 1);
   width: 239px;
padding: 10px 28px 10px 36px;
border-radius: 20px;
gap: 8px;
font-family: Mulish;
font-size: 16px;
text-transform: uppercase;
font-weight: 700;
line-height: 20px;
letter-spacing: 0.0025em;
text-align: left;
}

@media screen and (max-width:1280px) {
    .VisaFinderNotSure_layout{
        margin: 0 auto;
        width: 95%;
        display: flex;
        margin-top: 72px;
        /* padding: 32px 72px; */
        gap: 24px;
       }
       .VisaFinderNotSure_layout_content{
        background: rgba(255, 247, 241, 1);
        padding: 24px;
        width: 556px;
        height: auto;
    }
    .VisaFinderNotSure_layout_img_container{
        width: 50%;
        height: 100%;
    }
    .VisaFinderNotSure_layout_img{
        width: 443px;
        height: 458px;
        object-fit: fill;
        border-radius: 20px;
    }
   
}

@media screen and (max-width:890px) {
    .VisaFinderNotSure_layout{
        margin: 0 auto;
        width:90%;
        display: flex;
        /* flex-direction: column; */
        margin-top: 32px;
        padding:16px;
        gap: 24px;
       }
       .VisaFinderNotSure_layout_content {
        background: rgba(255, 247, 241, 1);
        padding: 24px;
        width: 85%;
        margin: 0 auto;
        height: auto;
    }
}
      @media screen and (max-width:880px) {
    .VisaFinderNotSure_layout{
        margin: 0 auto;
        width:90%;
        display: flex;
        /* flex-direction: column; */
        margin-top: 32px;
        padding:16px;
        gap: 24px;
       }
       .VisaFinderNotSure_layout_content {
        background: rgba(255, 247, 241, 1);
        padding: 24px;
        width: 50%;
        height: 500px;
    }
    .VisaFinderNotSure_layout_img{
        object-fit: fill;
        border-radius: 20px;
        width: 100%;
        height: 545px;
    
    }
    .VisaFinderNotSure_layout_img_container{
        width: 50%;
        height: 500px;
    }
    
}
    
@media screen and (max-width:880px) {
    .VisaFinderNotSure_layout{
        margin: 0 auto;
        width:90%;
        display: flex;
        /* flex-direction: column; */
        margin-top: 32px;
        padding:16px;
        gap: 24px;
       }
       .VisaFinderNotSure_layout_content {
        background: rgba(255, 247, 241, 1);
        padding: 24px;
        width: 85%;
        margin: 0 auto;
        height: auto;   
    }
    .VisaFinderNotSure_layout_img{
        width: 100%;
        height: 100%;
        margin: 0 auto;
        border-radius: 20px;
    }    
}
@media screen and (max-width:480px) {
    .VisaFinderNotSure_layout{
        margin: 0 auto;
        width:90%;
        display: flex;
        flex-direction: column;
        margin-top: 32px;
        padding:16px;
        gap: 24px;
       }
       .VisaFinderNotSure_layout_content {
        background: rgba(255, 247, 241, 1);
        padding: 24px;
        width: 85%;
        margin: 0 auto;
        height: auto;   
    }
    .VisaFinderNotSure_layout_img {
        width: 340px;
        height: 340px;
        margin: 0 auto;
        border-radius: 20px;
    } 
    .VisaFinderNotSure_layout_title {
        font-family: Mulish;
        font-size: 28px;
        font-weight: 900;
        line-height: 38px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(176, 56, 61, 1);
    }
    .VisaFinderNotSure_layout_desc {
        font-family: Mulish;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(176, 56, 61, 1);
    }
    .VisaFinderNotSure_layout_btn {
        background: rgba(176, 56, 61, 1);
        color: rgba(241, 253, 255, 1);
        width: 159px;
        padding: 10px 20px 10px 20px;
        border-radius: 20px;
        gap: 8px;
        font-family: Mulish;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.0025em;
        text-align: center;
    }
}
.HeroBanner {
    text-align: center;
    margin: 0 auto;
    max-width: 1380px;
    padding:16px 72px 40px 72px;
    background: #FFF7F1;
    overflow: hidden;
}

.Banner-image {
    width: 100%;
    max-width: 1380px;
    height: 500px;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
}

.HeroBannerImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.heroStyling {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.hero-content {
    display: flex;
    flex-direction: column;
    gap: 0px;
    max-width: 1280px;
}

.hero-heading {
    color: #045590;
    font-family: Arial;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%;
    padding-top: 10px;
    text-align: left;
    width: 101%;
}

.hero-heading-Emp {
    color: #045590;
    font-family: Arial;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%;
    padding-top: 10px;
    text-align: left;
    width: 1100px;
}

.hero-subheading {
    color: #045590;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.045px;
    /* text-transform: lowercase; */
    text-align: left;
    /* text-transform: capitalize; */
    /* padding-top: 10px; */
    width: 1170px;
    /* width: 80%; */
}

.hero {
    display: flex;
    justify-content: space-between;
}

.dotted-lines {
    margin-top: -47px;
    width: 226px;
    height: 204px;
    margin-right: -58px;
}

.hero-subheading-Emp {
    color: #014A91;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    width: 100%;
    text-align: left;
}

.connect-button {
    color: #FFF;
    font-family: Arial;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.04px;
    text-transform: uppercase;
    border-radius: 128px;
    background: #B0383D;
    display: flex;
    width: 130px;
    padding: 10px 28px;
    margin-right: -160px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    border: none;
    z-index: 2;
    opacity: 1000000;
    position: relative;
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.04px;
    text-transform: uppercase;
    border-radius: 128px;
    background: #B0383D;
    display: flex;
    width: 171px;
    /* height: 31px; */
    padding: 8px 14px;
    margin-right: -160px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    border: none;
    z-index: 2;
    opacity: 1000000;
    position: relative;
    
}

.connect-buttonEmp {
    color: #FFF;
    font-family: Arial;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.04px;
    text-transform: uppercase;
    border-radius: 128px;
    background: #B0383D;
    display: flex;
    width: 200px;
    padding: 10px 36px;
    margin-right: -170px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: none;
}

.hero-styling {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1290px) {
    .hero-heading {
        font-size: 40px;
    }
    .connect-button {
        color: #FFF;
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.04px;
        text-transform: uppercase;
        border-radius: 128px;
        background: #B0383D;
        display: flex;
        width: 130px;
        padding: 10px 28px;
        margin-right: -160px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        border: none;
        z-index: 2;
        opacity: 1000000;
        position: relative;
        
    }
}


@media screen and (max-width: 1050px) {
    .hero-heading {
        width: 100%;
        font-size: 24px;
    }

    .hero-heading-Emp {
        font-size: 24px;
    }

    .HeroBanner {
        padding: 24px 54px;
    }

    .hero-styling {
        max-width: 1280px;
    }

    .hero-subheading {
        width: 100%;
        margin-top: 0px;
    }

    .hero-subheading-Emp {
        width: 100%;
        margin-top: 0px;
        font-size: 18px;
    }

    .hero-banner {
        height: 300px;
    }

    .dotted-lines {
        margin-top: -80px;
        margin-right: -85px;
    }

    .Banner-image {
        height: 317px;
    }
    .connect-button {
        color: #FFF;
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.04px;
        text-transform: uppercase;
        border-radius: 128px;
        background: #B0383D;
        display: flex;
        width: 130px;
        padding: 10px 28px;
        margin-right: -160px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        cursor: pointer;
        border: none;
        z-index: 2;
        opacity: 1000000;
        position: relative;
        border: none;
        
    }
}

@media screen and (max-width: 660px) {
    .heroStyling {
        gap: 20px;
        flex-direction: column;
        align-items: flex-start;
    }

    .connect-button {
        width: 130px;
        height: 16px;
        padding: 10px 12px;
        font-size: 11px;
        margin-top: -6px;
        z-index: 1;
    }

    .connect-buttonEmp {
       margin-top: 30px;
    }

    .hero-heading {
        font-size: 16px;
        width: 90%;
    }

    .hero-heading-Emp {
        font-size: 16px;
        width: 80%;
    }

    .hero-subheading-Emp {
        font-size: 12px;
    }

    .hero-subheading {
        font-size: 12px;
        padding: 0px;
    }

    .HeroBanner {
        padding: 16px;
    }

    .hero-banner {
        height: 185px;
    }

    .hero-content {
        gap: 4px;
    }

    .dotted-lines {
        margin-top: -100px;
        margin-right: -187px;
    }

    .Banner-image {
        height: 169px;
    }

}
.faq_container{
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 72px;
    padding-bottom: 72px;
    padding-left: 72px;
    padding-right: 72px;

}
.accordian_container{
    display: flex;
    flex-direction: column;
    /* gap: 24px; */
    font-family: Mulish;
}
.faq_container_head{
    max-width: 1210px;
flex-shrink: 0;
padding: 32px;
color: var(--Red, #B0383D);
font-size: 57px;
font-style: normal;
font-weight: 900;
font-family: Mulish;
line-height: 140%; /* 79.8px */
letter-spacing: 0.143px;
border-radius: 20px;
background: #FFF7F1;
}
.faq_accordian_video_contaner{
    display: flex;
    justify-content: space-between;
    align-self: center;
    gap: 22px;
    font-family: Mulish;
    margin-top: 24px;
    margin-bottom: 24px;
}

.accordian-submain {
    width: 749px;
    font-family: Mulish;
    flex-shrink: 0;
    border-radius: 20px;
background: #FFF7F1;
    padding: 32px;
    align-items: center;
    cursor: pointer;
}
.accordian-submain_end{
    max-width:1210px;
    flex-shrink: 0;
    font-family: Mulish;
    border-radius: 20px;
background: #FFF7F1;
    padding: 32px;
    align-items: center;
    cursor: pointer;
    margin-top: 24px;
}


.accordian-heading {
    display: flex;
    justify-content: space-between;
    color: #000;
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.accordian-details {
    color: #848484;
    width: 100%;
    margin-top: 24px;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04px;
}





@media screen and (max-width: 1300px) {
    .layout-content {
        width: 726px;
    }

    .tablist {
        min-width: 180px;
    }
}

@media screen and (max-width: 1280px) {
    .accordian-submain {
        width: 800px;
        padding: 24px;
    }
}

@media screen and (max-width: 1100px) {
    .layout-content {
        width: 626px;
    }

    .accordian-submain {
        width: 730px;
        padding: 24px;
    }
}

@media screen and (max-width: 980px) {
    .layout-content {
        width: 500px;
    }

    .faqpage {
        padding-right: 0px;
    }

    .accordian-submain {
        width: 630px;
        padding: 24px;
    }
    .faq_container {
        max-width: 1280px;
        margin: 0 auto;
        padding-top: 72px;
        padding-bottom: 72px;
        padding-left: 18px;
        padding-right: 18px;
    }
}

@media screen and (max-width: 880px) {
  
    .accordian-submain_end {
        width: 85%;
        flex-shrink: 0;
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        align-items: center;
        cursor: pointer;
        margin-top: 24px;
    }
    .faq_accordian_video_contaner {
        display: flex;
        justify-content: space-between;
        align-self: center;
        gap: 22px;
        margin-top: 24px;
        flex-direction: column;
    }
    .faq_container_head{
        width: 85%;
        flex-shrink: 0;
        padding: 24px;
        color: var(--Red, #B0383D);
        font-size: 32px;
        font-size: 26px;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.143px;
        border-radius: 20px;
        background: #FFF7F1;
    }
    .faq_container{
        width: 100%;
    padding: 18px;
    margin: 0 auto;
    }
    .faq_container {
        max-width: 1280px;
        margin: 0 auto;
        padding-top: 72px;
        padding-bottom: 72px;
        padding-left: 18px;
        padding-right: 18px;
    }
}

@media screen and (max-width: 780px) {
  
    .accordian-submain_end {
        width: 80%;
        flex-shrink: 0;
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        font-family: Mulish;
        align-items: center;
        cursor: pointer;
        margin: 0 auto;
        margin-top: 24px;
    }
    .faq_accordian_video_contaner {
        display: flex;
        justify-content: space-between;
        align-self: center;
        gap: 22px;
        margin-top: 24px;
        flex-direction: column;
    }
    .faq_container_head{
        width: 80%;
        flex-shrink: 0;
        padding: 24px;
        font-size: 28px;
        font-weight: 900;
        line-height: 130%;
        letter-spacing: 0.149px;
        border-radius: 20px;
        background: #FFF7F1;
        color: var(--Red, #B0383D);
        font-family: Mulish;
        margin: 0 auto;
    }
    .faq_container{
        width: 100%;
    padding: 18px;
    margin: 0 auto;
    }
    .faq_container {
        max-width: 1280px;
        margin: 0 auto;
        padding-top: 72px;
        padding-bottom: 72px;
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media screen and (max-width: 750px) {
  
    .accordian-submain_end {
        width: 80%;
        flex-shrink: 0;
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        font-family: Mulish;
        align-items: center;
        cursor: pointer;
        margin: 0 auto;
        margin-top: 24px;
    }
    .faq_container {
        max-width: 1280px;
        margin: 0 auto;
        padding-top: 72px;
        padding-bottom: 72px;
        padding-left: 18px;
        padding-right: 18px;
    }
}

@media screen and (max-width: 660px) {
   
    .accordian-heading{
        font-size: 18px;
    }
    .accordian-details{
        font-size: 14px;
    }
    .accordian-submain_end {
        width: 80%;
        flex-shrink: 0;
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        align-items: center;
        cursor: pointer;
        margin-top: 24px;
    }
    .faq_container {
        max-width: 1280px;
        margin: 0 auto;
        padding-top: 72px;
        padding-bottom: 72px;
        padding-left: 18px;
        padding-right: 18px;
    }
}
@media screen and (max-width: 550px) {
    .accordian-submain_end {
        width: 330px;
        flex-shrink: 0;
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        align-items: center;
        cursor: pointer;
        margin-top: 24px;
    }

    .accordian-submain {
        width: 330px;
        padding: 24px;
    }
    .faq_container {
        max-width: 1280px;
        margin: 0 auto;
        padding-top: 72px;
        padding-bottom: 72px;
        padding-left: 18px;
        padding-right: 18px;
    }
}

@media screen and (max-width: 470px) {
    .accordian-submain_end {
        width: 80%;
        flex-shrink: 0;
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        align-items: center;
        cursor: pointer;
        font-family: Mulish;
        margin: 0 auto;
        margin-top: 24px;
      
    }

    .accordian-submain {
        width: 300px;
        padding: 24px;
    }
    .faq_container {
        max-width: 1280px;
        margin: 0 auto;
        padding-top: 52px;
        padding-bottom: 52px;
        padding-left: 0px;
        padding-right: 0px;
    }
}


@media screen and (max-width: 380px) {
    .accordian-submain_end {
        width: 280px;
        flex-shrink: 0;
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        align-items: center;
        cursor: pointer;
        margin: 0 auto;
        margin-top: 16px;
        
    }
    .faq_container {
        min-width: 300px;
        margin: 0 auto;
        padding-top: 52px;
        padding-bottom: 52px;
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media screen and (max-width: 290px) {
    .accordian-submain {
        width: 330px;
    }
    .accordian-submain_end {
        width: 330px;
        flex-shrink: 0;
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        align-items: center;
        cursor: pointer;
        margin-top: 24px;
    }
    .faq_container {
        max-width: 1280px;
        margin: 0 auto;
        padding-top: 72px;
        padding-bottom: 72px;
        padding-left: 18px;
        padding-right: 18px;
    }
}
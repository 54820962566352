.FamComp-layout {
    margin: 0 auto;
    max-width: 1280px;
    /* padding-top: 72px; */
    padding-bottom: 72px;
}

.FamComp-heading {
    border-radius: 20px;
    background: #F4F4F4;
    margin-bottom: 24px;
}

.FamCompHeading {
    color: #014A91;
      font-family: Mulish;
    font-size: 54px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%;
    letter-spacing: 0.14px;
    padding: 32px;
}
.FamComp-two-desc li{
    font-family: Mulish;
font-size: 16px;
font-weight: 400;
line-height: 23px;
letter-spacing: 0.0025em;
text-align: left;
color: rgba(132, 132, 132, 1);
}
.FamComp-desc{
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.0025em;
    text-align: left;
    color: rgba(132, 132, 132, 1);
}

.FamComp-subHeading {
    color: #3B3B3B;
      font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    /* line-height: 20px; */
    letter-spacing: 0.06px;
}

.FamComp-layout-one {
    max-width: 362px;
    width: 35%;
    border-radius: 20px;
    background: #F4F4F4;
    padding: 32px;
}

.FamComp-heading {
    color: #3B3B3B;
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
    margin-bottom: 24px;
}

.FamComp-desc {
    color: #848484;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.04px;
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid #D9C0AA;
}

.FamComp-heading1 {
    color: #3B3B3B;
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
    margin-bottom: 24px;
}

.FamComp-desc1 {
    color: #848484;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04px;
}

.FamComp-two-service {
    border-radius: 20px;
    background: #F4F4F4;
    padding: 32px;
    max-width: 362px;
    width: 100%;
    height: 412px;
}

.FamComp-two-trans {
    height:412px;
    border-radius: 20px;
    background: #F4F4F4;
    padding: 32px;
    max-width: 362px;
    width: 100%;
}

.FamComp-sublayout2 {
    padding: 32px;
    border-radius: 20px;
    background: #F4F4F4;
}

.FamComp-two-head {
    color: #3B3B3B;
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
    margin-bottom: 24px;
}

.FamComp-two-desc {
    color: #848484;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04px;
}

.FamComp-two-support {
    color: #3B3B3B;
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
    margin-bottom: 24px;
}

.FamComp-two-support-desc {
    color: #848484;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04px;
}
.FamComp-two-support-desc li{
    color: #848484;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.04px;
}

.FamComp-two-sublayout1 {
    display: flex;
    gap: 24px;
}

.FamComp-second-layout {
    display: flex;
    gap: 24px;
    justify-content: space-between;
    margin-bottom: 24px;
    margin-top: 24px;
}

.FamComp-layout-two {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.FamComp-two-sublayout2 {
    padding: 32px;
    border-radius: 20px;
    background: #F4F4F4;
}

@media screen and (max-width: 890px) {
    .FamComp-layout {
        padding: 54px;
    }
    .FamCompHeading {
        font-size: 48px;
        padding: 24px;
    }
    .FamComp-subHeading, .FamComp-heading, .FamComp-heading1, .FamComp-two-head, .FamComp-two-support  {
        font-size: 18px;
    }
    .FamComp-layout-one, .FamComp-two-service, .FamComp-two-trans, .FamComp-sublayout2, .FamComp-two-sublayout2 {
        padding: 24px;
    }
    .FamComp-desc, .FamComp-desc1, .FamComp-two-desc, .FamComp-two-support-desc  {
        font-size: 14px;
    }
    .FamComp-two-service {
        height: auto;
    }
    .FamComp-second-layout{
        flex-direction: column;
    }
    .FamComp-layout-one{
        display: flex;
        max-width: 1280px;
        width: auto;
        height: auto;
    }
    .FamComp-desc{
        padding-bottom: 0px;
        margin-bottom: 0px;
        border-bottom: none;
    }
    .FamComp-layout-ext{
        border-left: 1px solid #D9C0AA;
        padding-left: 32px;
        margin-left: 32px;
        width: 100%;
    }
    .FamComp-layout-exp{
        width: 100%;
    }
}

@media screen and (max-width: 660px) {
    .FamComp-layout {
        padding: 16px;
    }
    .FamCompHeading {
        font-size: 24px;
        padding: 16px;
    }
    .FamComp-subHeading, .FamComp-heading, .FamComp-heading1, .FamComp-two-head, .FamComp-two-support  {
        font-size: 16px;
        margin-bottom: 16px
    }
    .FamComp-layout-one, .FamComp-two-service, .FamComp-two-trans, .FamComp-sublayout2, .FamComp-two-sublayout2 {
        padding: 16px;
    }
    .FamComp-desc, .FamComp-desc1, .FamComp-two-desc, .FamComp-two-support-desc  {
        font-size: 12px;
    }
    .FamComp-two-service, .FamComp-two-trans{
        width: auto;
    }
    .FamComp-two-sublayout1{
        flex-direction: column;
    }
}
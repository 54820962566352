.OptionOverviewStyling {
    padding: 28px;
    height: auto;
}
.modalLayout {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  height: 100vh;
  overflow: scroll; 
}

.OptionTwoLayout {
    border-radius: 20px;
    background: #F4F4F4;
    padding: 28px;
    margin-top: 24px;
}

.OptionTwoDesc {
    color: #3B3B3B;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.06px;
    /* margin-bottom: 24px; */
}

.OptionTwoDesc-two {
    color: #848484;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.04px;
}

.OptionTwoHead {
    color: #3B3B3B;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
    margin-bottom: 24px;
}

.OptionTwoSublayout-one {
    border-radius: 20px;
    background: #F4F4F4;
    padding: 28px;
    margin-top: 24px;
    max-width: 306px;
    width: 100%;
}

.OptionTwoSub-one {
    border-radius: 20px;
    background: #F4F4F4;
    padding: 28px;
    margin-top: 24px;
    max-width: 346px;
    width: 100%;
}

.OptionTwoEliDesc {
    color: #848484;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.04px;
    margin-top: 24px;
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #D9C0AA;
}

.OptionTwoEliDescrip {
    color: #848484;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.04px;
}

.OptionTwoSublayout-two {
    display: flex;
    gap: 24px;
    justify-content: space-between;
}

.OptionTwoSublayout-three {
    border-radius: 20px;
    background: #F4F4F4;
    padding: 28px;
    margin-top: 24px;
    width: auto;
}

.OptionTwoSublayout-main{
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */

}

.OptionTwoSublayout{
    display: flex;
    gap: 24px;
}

@media screen and (max-width: 890px) {
    .OptionTwoLayout {
        padding: 16px;
    }
    .modalLayout::-webkit-scrollbar{
        display: none;
    }
    .OptionTwoDesc {
        font-size: 18px;
    }

    .OptionTwoDesc-two {
        font-size: 14px;
    }

    .OptionTwoHead {
        font-size: 18px;
        margin-bottom: 18px;
    }

    .OptionTwoSublayout-one{
        max-width: 1280px;
        width: auto;
    }

    .OptionTwoSublayout{
        flex-direction: column;
    }

    .OptionOverviewStyling{
        padding: 24px;
    }
}

@media screen and (max-width: 660px) {
    .OptionTwoLayout, .OptionTwoSublayout-one, .OptionTwoSub-one, .OptionTwoSublayout-three {
        padding: 16px;
    }

    .OptionTwoSublayout, .OptionTwoSublayout-two{
        gap: 0px;
    }

    .OptionTwoDesc {
        font-size: 16px;
        margin-bottom: 8px;
    }

    .OptionTwoEliDescrip{
        font-size: 12px;
    }

    .OptionTwoSublayout-two{
        flex-direction: column;
    }

    .OptionTwoSub-one{
        max-width: 1280px;
        width: auto;
    }

    .OptionTwoDesc-two {
        font-size: 12px;
    }

    .OptionTwoHead {
        font-size: 16px;
        margin-bottom: 8px;
    }
    .OptionOverviewStyling{
        padding: 16px;
    }
    .OptionTwoEliDesc{
        font-size: 12px;
        margin-bottom: 16px;
        padding-bottom: 16px;
        margin-top: 0px;
    }
    .modalLayout::-webkit-scrollbar{
        display: none;
    }
}
.modalLayout {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: 100vh;   
}
.modalLayout_contact{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 32px;
}
.Conatct_title{
    font-family: Mulish;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.0025em;
    text-align: left;
    padding: 0px;
    color: rgba(59, 59, 59, 1);
    margin-bottom: 24px;
       
}
.modalLayoutMain_contact{
    width: 942px;
    border-radius: 20px;
    cursor: pointer;
    background: white;
}
.modalLayoutMain {
    width: 942px;
    border-radius: 20px;
    cursor: pointer;
    background: white;
}

.simple-table {
    width: 100%;
    border-collapse: collapse;
}

.simple-table td {
    padding: 16px;
    text-align: center;
}

.modalStyling{
    padding-bottom: 72px;
    padding-left: 72px;
    padding-top: 32px;
    padding-right: 72px;
}

.tableTitle1 {
    color: #3B3B3B;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
    width: 140px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.30);
    border-right: 1px solid rgba(0, 0, 0, 0.30);
}

.tableTitle2 {
    color: #3B3B3B;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.30);
}

.tableSubTitle {
    color: #EB1C24;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04px;
    border-right: 1px solid rgba(0, 0, 0, 0.30);
}

.tableSubDesc {
    color: #848484;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04px;
}
.contact-form-textField{
    border: 1px solid rgba(0, 0, 0, 0.15);
    width:330px;
/* height:46px; */
padding: 14px 19px 14px 19px;
border-radius: 8px;
gap: 10px;
font-family: Mulish;
font-size: 14px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
color: rgba(0, 0, 0, 1);
border: 1px solid rgba(0, 0, 0, 0.4) !important;

}
.Form_field{
    width: 90%;
    gap: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* padding: 32px */
}
.Form_name_field{
    display: flex;
    gap: 24px;
}
@media screen and (max-width: 660px) {
    .modalStyling{
        padding: 16px;
    }
    .simple-table td{
        padding: 8px;
        min-width: 150px;
    }
    .tableTitle1, .tableTitle2{
        font-size: 16px;
    }
    .tableSubDesc{
        font-size: 12px;
        line-height: 16px;
    }
    .tableSubTitle{
        font-size: 12px;
    }
    .tableTitle1{
        width: 95px;
    }
    .modalLayout{
        height: 80vh;
    }
    .Form_name_field {
        display: flex;
        gap: 24px;
        flex-direction: column;
        margin: 0 auto;
    }
}
@media screen and (max-width: 480px) {
.Form_name_field {
    display: flex;
    gap: 24px;
    flex-direction: column;
    margin: 0 auto;
}
.contact-form-textField {
    border: 1px solid rgba(0, 0, 0, 0.15);
    width: 300px;
    /* height: 46px; */
    padding: 14px 19px 14px 19px;
    border-radius: 8px;
    gap: 10px;
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(0, 0, 0, 1);
}
.help-screen-form{
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: flex-start;
}
.modalLayout_contact {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5rem auto;
    padding: 32px;
}
}
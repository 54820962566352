.AboutUsVisa_box_fields_content_457{
    width: 566px;
    height: 436px;
    background: rgba(244, 244, 244, 1);
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 20px;

}
@media screen and (max-width: 660px) {
    .AboutUsVisa_box_fields_content_457{
        width: 80%;
        height: auto;
        background: rgba(244, 244, 244, 1);
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        border-radius: 20px;
        margin: 0 auto;
    
    }
}
@media screen and (max-width: 880px) {
    .AboutUsVisa_box_fields_content_457{
        width: 80%;
        height: auto;
        background: rgba(244, 244, 244, 1);
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        border-radius: 20px;
        margin: 0 auto;
    }
}
@media screen and (max-width: 480px) {
    .AboutUsVisa_box_fields_content_457{
        width: 80%;
        height: auto;
        background: rgba(244, 244, 244, 1);
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        border-radius: 20px;
        margin: 0 auto;
    }   
}
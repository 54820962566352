.JobVacancy_layout{
    margin: 0 auto;
    max-width: 1280px;
}
.JobVacancy_container_fields{
    display: flex;
    gap: 24px;
}
.JobVacancy_container{
    background: rgba(244, 244, 244, 1);
    width: 363px;
    height: 290px;
    padding: 32px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.JobVacancy_container_title{
    color: rgba(59, 59, 59, 1);
    font-family: Mulish;
    font-size: 24px;
    font-weight: 900;
    line-height: 28px;
    letter-spacing: 0.0025em;
    text-align: left;
    width: 100%;
}
.JobVacancy_container_subTitle{
    font-family: Mulish;
font-size: 24px;
font-weight: 500;
line-height: 28px;
letter-spacing: 0.0025em;
text-align: left;
color: rgba(59, 59, 59, 1);
}
.JobVacancy_container_desc{
    color: rgba(132, 132, 132, 1);
    font-family: Mulish;
font-size: 16px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.0025em;
text-align: left;


}
.JobVacancy_container_btn{
    width:191px;
padding: 10px 36px 10px 36px;
border-radius: 128px;
gap: 8px;
background: rgba(176, 56, 61, 1);
font-family: Mulish;
font-size: 16px;
font-weight: 600;
line-height: 20px;
letter-spacing: 0.0025em;
text-align: center;
color: rgba(255, 255, 255, 1);


}

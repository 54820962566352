.International_CantFind_layout{
    margin: 0 auto;
    max-width: 1280px;
    padding: 36px 72px;
}
.International_CantFind_Title{
    background: rgba(244, 244, 244, 1);
    font-family: Mulish;
    font-size: 57px;
    font-weight: 900;
    line-height: 80px;
    letter-spacing: 0.0025em;
    text-align: left;
    padding: 32px;
    border-radius: 20px;
    color: rgba(4, 85, 144, 1);
    
}
.International_CantFind_fields{
    display: flex;
    gap: 24px;
    align-items: center;
    margin-top: 24px;
}
.International_CantFind_Box{
    width: 363px;
    height: 250px;
    background: rgba(244, 244, 244, 1);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    padding: 32px;

}
.International_CantFind_Box_title{
    font-family: Mulish;
font-size: 24px;
/* max-width: 297px; */
font-weight: 500;
line-height: 29px;
letter-spacing: 0.0025em;
text-align: left;
color: rgba(59, 59, 59, 1);

}
.International_CantFind_Box_desc{
    font-family: Mulish;
font-size: 16px;
font-weight: 400;
max-height: 80px;
max-width: 299px;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
color: rgba(132, 132, 132, 1);

}
.International_CantFind_Box_btn{
    /* width:191px; */
padding: 10px 36px 10px 36px;
border-radius: 128px;
gap: 8px;
display: flex;
background: rgba(176, 56, 61, 1);
color: white;
align-items: center;
text-align: center;
justify-content: center;
text-transform: uppercase;
}
@media screen and (max-width: 480px) {
    .International_CantFind_fields{
        display: flex;
        gap: 24px;
        align-items: center;
        margin-top: 24px;
        max-width: 1000px;
        overflow: scroll;
    }
    .International_CantFind_fields::-webkit-scrollbar{
        display: none;
    }
    .International_CantFind_layout{
        margin: 0 auto;
        padding: 16px 16px;
    }
    .International_CantFind_Box_desc {
        font-family: Mulish;
        font-size: 12px;
        font-weight: 400;
        max-height: none;
        max-width: none;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(132, 132, 132, 1);
    }
    .International_CantFind_Box_title {
    background: rgba(244, 244, 244, 1);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: rgba(244, 244, 244, 1);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 18px;
    font-weight: 600;
    }
    .International_CantFind_Box {
        background: rgba(244, 244, 244, 1);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        font-family: Mulish;
        align-items: flex-start;
        padding: 24px;
        height: 250px;
        min-width: 221px;
    }
    .International_CantFind_Title {
        background: rgba(244, 244, 244, 1);
        font-family: Mulish;
        font-size: 28px;
        font-weight: 900;
        line-height: 40px;
        letter-spacing: 0.0025em;
        text-align: left;
        padding: 25px;
        border-radius: 20px;
        color: rgba(4, 85, 144, 1);
    }
    .International_CantFind_Box_btn {
        padding: 8px 18px 8px 18px;
        border-radius: 128px;
        gap: 8px;
        display: flex;
        background: rgba(176, 56, 61, 1);
        color: white;
        font-family: Mulish;
        align-items: center;
        text-align: center;
        justify-content: center;
        text-transform: uppercase;
        font-size: 12px;
    }
}
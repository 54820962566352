.SkilledComp2_layout{
    margin: 0 auto;
    max-width: 1280px;
    /* padding: 36px 72px; */
    display: flex;
    flex-direction: column;
    margin-bottom: 72px;
    gap: 24px;
}
.SkilledComp2_Title{
    background: rgba(244, 244, 244, 1);
    padding: 32px;
    border-radius: 20px;
    font-family: Mulish;
font-size: 56px;
font-weight: 900;
line-height: 78px;
letter-spacing: 0.0025em;
text-align: left;
color: rgba(1, 74, 145, 1);

}
.skilledComp2_Field{
    display: flex;
    justify-content: center;
    gap: 24px;
}
.skilledComp2_box{
    background: rgba(244, 244, 244, 1);
    width: 362px;
    height: 180px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 32px;
    gap: 24px;
    
}
.SkilledComp2_box__end{
    width: 555px;
height: 160px;
border-radius: 15px;
padding: 32px;
display: flex;
justify-content: center;
flex-direction: column;
background: rgba(244, 244, 244, 1);
gap: 24px;
align-items: flex-start;

}
.SkilledComp2_box_Title{
    color: rgba(59, 59, 59, 1);
    font-family: Mulish;
    /* min-height: 56px; */
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.0025em;
    text-align: left;
    max-width: 224px;
}
.SkilledComp2_box_Title_end{
    color: rgba(59, 59, 59, 1);
    font-family: Mulish;
    /* min-height: 56px; */
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.0025em;
    text-align: left;
    min-width: 290px;
}
/* .skilledComp2_Field{
    max-width: 1000px;
    overflow: scroll;
} */
.skilledComp2_Field::-webkit-scrollbar{
    display: none;
}
.SkilledComp2_box_Desc{
    color: rgba(132, 132, 132, 1);
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;
    text-align: left;
    min-width: 279px;
    min-height: 99px;
}
.SkilledComp2_box_Desc_end{
    color: rgba(132, 132, 132, 1);
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;
    text-align: left;
    /* min-width: 480px; */
    min-height: 59px;
}
@media screen and (max-width: 880px) {
    .SkilledComp2_layout{
        margin: 0 auto;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    .SkilledComp2_Title {
        background: rgba(244, 244, 244, 1);
        padding: 16px;
        border-radius: 20px;
        font-family: Mulish;
        font-size: 26px;
        font-weight: 900;
        line-height: 45px;
        letter-spacing: 0.0025em;
        text-align: left;
        color: rgba(1, 74, 145, 1);
        width: 80%;
    }
    .skilledComp2_Field {
        display: flex;
        justify-content: flex-start;
        gap: 24px;
        max-width: 1000px;
        flex-direction: column;
        overflow: scroll;
    }
    .skilledComp2_Field::-webkit-scrollbar{
        display: none;
    }
    .skilledComp2_box{
        padding: 16px;
        width: 80%;
        height: auto;
    
    }
    .SkilledComp2_box_Title {
        color: rgba(59, 59, 59, 1);
        font-family: Mulish;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.0025em;
        text-align: left;
        max-width: none;
    }
    .SkilledComp2_box_Title_end {
        color: rgba(59, 59, 59, 1);
        font-family: Mulish;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.0025em;
        text-align: left;
    
    }
    .SkilledComp2_box_Desc_end {
        color: rgba(132, 132, 132, 1);
        font-family: Mulish;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.0025em;
        text-align: left;
    }
    .SkilledComp2_box__end{
        padding: 24px;
        width: 75%;
        height: auto;
    }
    .SkilledComp2_box_Desc {
        color: rgba(132, 132, 132, 1);
        font-family: Mulish;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.0025em;
        min-width: none;;
        text-align: left;
    }
    }
.visa_consultation_container{
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
}
.VisaPes_Box{
    background: rgba(244, 244, 244, 1);
    padding: 32px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    margin-top: 24px;
}
.VisaPes_Box_title{
    font-family: Mulish;
font-size: 24px;
font-weight: 500;
line-height: 28px;
letter-spacing: 0.0025em;
text-align: left;
color: rgba(59, 59, 59, 1);

}
.VisaPes_Box_desc {
    font-family: Mulish;
font-size: 16px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.0025em;
text-align: left;
color: rgba(132, 132, 132, 1);

}
.VisaPes_Box_desc li{
    font-family: Mulish;
font-size: 16px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.0025em;
text-align: left;
color: rgba(132, 132, 132, 1);

}
.visa_consultation_content{
    width: 556px;
flex-shrink: 0;
border-radius: 20px;
background: #FFF7F1;
padding: 26px;

}
.visa_consultation_title{
    color: #B0383D;

font-size: 48px;
font-style: normal;
font-weight: 900;
line-height: 140%; /* 67.2px */
}
.visa_consultation_description{
    margin-bottom: 24px;
    margin-top: 8px;
    color: #B0383D;

font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 33.6px */
}
.visa-img{
    /* width: 380px; */
height: 380px;
flex-shrink: 0;
border-radius: 20px;
}
.visa-btn{
    display: inline-flex;
    padding: 10px 28px 10px 36px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 72px;
    background: #B0383D;
    border: none;
    color: #F1FDFF;

font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 20px; /* 125% */
letter-spacing: 0.04px;
text-transform: uppercase;
}
@media screen and (max-width:1200px) {
    .VisaPes_Box{
        width: 80%;
        border-radius: 20px;
        margin: 0 auto;
        padding: 24px;
        margin-top: 24px;
    }
    }
@media screen and (max-width:880px) {
.VisaPes_Box{
    width: 80%;
    border-radius: 20px;
    margin: 0 auto;
    padding: 24px;
    margin-top: 24px;
}
.LabourAgreement_section-container_title {
    color: #3B3B3B;
    font-family: Mulish;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
}
}
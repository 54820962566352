.Working_Sub_Title{
    color: rgba(59, 59, 59, 1);
    font-family: Mulish;
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0.0025em;
    text-align: left;
    
}
.Working_desc h4{
    color: rgba(59, 59, 59, 1);
    font-family: Mulish;
font-size: 16px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.0025em;
text-align: left;
text-transform: uppercase;
padding: 0px;
margin: 8px auto;

}
.StudentVisa_fields_container1{
    background: rgba(255, 247, 241, 1);
    padding: 32px;
    width: 410px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 372px;
    border-radius: 20px;
}
.StudentVisa_fields_container2{
    background: rgba(255, 247, 241, 1);
    width: 720px;
    height: 372px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 20px;
}
.WorkingHoliday_Layout{
    margin: 0 auto;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.WorkingHoliday_Layout_Student{
    margin: 0 auto;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
}
.Working_Title{
    background: rgba(255, 247, 241, 1);
    padding: 32px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.Working_Things_Avoid_box{
    padding: 32px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background: rgba(244, 244, 244, 1);
}
.Working_Things_Avoid_box li{
    line-height: 25px;
}
.Working_desc{
    font-family: Mulish;
font-size: 16px;
font-weight: 700;
line-height: 20px;
letter-spacing: 0.0025em;
text-align: left;
color: rgba(132, 132, 132, 1);
font-family: Mulish;
font-size: 16px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0.0025em;
text-align: left;
}
.Working_desc l
li{
    font-family: Mulish;
font-size: 16px;
font-weight: 700;
line-height: 25px;
letter-spacing: 0.0025em;
text-align: left;
color: rgba(132, 132, 132, 1);
font-family: Mulish;
font-size: 16px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.0025em;
text-align: left;
}
.visaFinder_extrayLay{
    padding: 32px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    background: rgba(255, 247, 241, 1);
}
.student_double_field{
    display: flex;
    gap: 24px;
}
.student_tri_field{
    display: flex;
    gap: 24px;
}
.student_double_box{
    background: rgba(255, 247, 241, 1);
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 556px;
    height: 335px;
    border-radius: 20px;
}
.student_tri_box{
    width: 362px;
height: 285px;
margin-top: 24px;
margin-bottom: 24px;
background: rgba(255, 247, 241, 1);
padding: 32px;
display: flex;
flex-direction: column;
gap: 24px;
border-radius: 20px;

}
.student_tri_box_Visa{
    width: 362px;
    height: 140px;
    margin-top: 24px;
    margin-bottom: 24px;
    background: rgba(255, 247, 241, 1);
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 20px;
}
@media screen and (max-width:1280px) {
    .WorkingHoliday_Layout{
        margin: 0 auto;
        width: 90%;
    }
    .WorkingHoliday_Layout_Student{
        margin: 0 auto;
        width: 90%;
    }
    .Tss_Eligibility_fields {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        gap: 24px;
    }
    .StudentVisa_fields_container1{
        background: rgba(255, 247, 241, 1);
        padding: 32px;
        width: 80%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        height: 100%;
        border-radius: 20px;
    }
    .StudentVisa_fields_container2{
        background: rgba(255, 247, 241, 1);
        width: 80%;
        height: 100%;
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        border-radius: 20px;
    }
    .visaFinder_extrayLay {
        padding: 24px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        background: rgba(255, 247, 241, 1);
        width: 85%;
        margin: 0 auto;
    }
    .student_tri_field{
        display: flex;
        flex-direction: column;
        /* gap: 24px; */
    }
    .student_tri_box {
        /* width: 362px; */
        /* height: 285px; */
        margin: 0 auto;
        margin-top: 24px;
        margin-bottom: 24px;
        background: rgba(255, 247, 241, 1);
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        border-radius: 20px;
        width: 80%;
        height: auto;
    }
    .student_tri_box_Visa{
        margin: 0 auto;
        margin-top: 24px;
        margin-bottom: 24px;
        background: rgba(255, 247, 241, 1);
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        border-radius: 20px;
        width: 80%;
        height: auto;
    }
    .student_double_field {
        display: flex;
        gap: 24px;
        flex-direction: column;
        margin: 0 auto;
    }
    .student_double_box {
        background: rgba(255, 247, 241, 1);
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 80%;
        height: auto;
        margin: 0 auto;
        border-radius: 20px;
    }
    
}
@media screen and (max-width:890px) {
    .WorkingHoliday_Layout{
        margin: 0 auto;
        /* width: 80%; */
    }
    .WorkingHoliday_Layout_Student{
        margin: 0 auto;
        /* width: 90%; */
    }
    .student_tri_field{
        display: flex;
        flex-direction: column;
        gap: 0px;
    }
    .student_tri_box {
        /* width: 362px; */
        /* height: 285px; */
        margin: 0 auto;
        margin-top: 24px;
        margin-bottom: 24px;
        background: rgba(255, 247, 241, 1);
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        border-radius: 20px;
        width: 80%;
    }
    .student_tri_box_Visa{
        margin: 0 auto;
        margin-top: 24px;
        margin-bottom: 24px;
        background: rgba(255, 247, 241, 1);
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        border-radius: 20px;
        width: 80%;
    }
    .student_double_field {
        display: flex;
        gap: 24px;
        flex-direction: column;
        margin: 0 auto;
    }
    .student_double_box {
        background: rgba(255, 247, 241, 1);
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 80%;
        height: auto;
        margin: 0 auto;
        border-radius: 20px;
    }
    .Working_Title {
        background: rgba(255, 247, 241, 1);
        padding: 24px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 93%;
        margin: 0 auto;
    }
}
@media screen and (max-width:660px) {
    .student_tri_box {
        /* width: 362px; */
        /* height: 285px; */
        margin: 0 auto;
        margin-top: 24px;
        margin-bottom: 24px;
        background: rgba(255, 247, 241, 1);
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        border-radius: 20px;
        width: 80%;
    }
    .student_tri_box_Visa{
        margin: 0 auto;
        margin-top: 24px;
        margin-bottom: 24px;
        background: rgba(255, 247, 241, 1);
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        border-radius: 20px;
        width: 80%;
    }
}
@media screen and (max-width:480px) {
    .WorkingHoliday_Layout{
        margin: 0 auto;
        /* width: 80%; */
    }
    .WorkingHoliday_Layout_Student{
        margin: 0 auto;
        /* width: 90%; */
    }
    .Working_Sub_Title {
        color: rgba(59, 59, 59, 1);
        font-family: Mulish;
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0.0025em;
        text-align: left;
    }
    .Working_desc li{
        font-family: Mulish;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.0025em;
        text-align: left;
        color: rgba(132, 132, 132, 1);
        font-family: Mulish;
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0.0025em;
        text-align: left;
    }
    .student_tri_box {
        height: auto;
        width: 80%;
        background: rgba(255, 247, 241, 1);
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        border-radius: 20px;
    }
    .student_tri_box_Visa{
        height: auto;
        width: 80%;
        background: rgba(255, 247, 241, 1);
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        border-radius: 20px;  
    }
    .student_double_box {
        background: rgba(255, 247, 241, 1);
        padding: 24px;
        width: 80%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        height: auto;
        border-radius: 20px;
    }
    .Working_Title {
        background: rgba(255, 247, 241, 1);
        padding: 24px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 80%;
        margin: 0 auto;
    }
}

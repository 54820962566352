.Points_layout{
    margin: 0 auto;
    max-width: 1280px;
    padding: 32px 72px;
}
.points_head_box{
    background: rgba(244, 244, 244, 1);
    border-radius: 20px;
    max-width: 1280px;
    padding: 32px;

}
.heading_points{
    font-family: Mulish;
font-size: 57px;
font-weight: 900;
line-height: 80px;
letter-spacing: 0.0025em;
text-align: left;
color: rgba(4, 85, 144, 1);

}
.point_connect{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 24px;
    font-family: Mulish;
font-size: 24px;
font-weight: 500;
line-height: 29px;
letter-spacing: 0.0025em;
text-align: left;

}
.subhead_point{
    font-family: Mulish;
font-size: 24px;
font-weight: 400;
line-height: 34px;
letter-spacing: 0.0025em;
text-align: left;
color: rgba(4, 85, 144, 1);

}
.points_form_container{
    /* margin: 0 auto; */
    max-width: 1280px;
    padding: 32px 72px;
    background: rgba(244, 244, 244, 1);
    border-radius: 20px;
 
}
.radio{
    width: 20px;
    height: 20px;
    margin-top: 8px;
    border: 1px solid #ccc;
    border-radius: 15px;
    color: #000;
}

  
.radio_fields{
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
}
.radio_labels{
    font-family: Mulish;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(83, 83, 83, 1);    
height: 20px;

}
.points_question p{
    font-family: Mulish;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.0025em;
    text-align: left;
color: rgba(59, 59, 59, 1);    
}
.points_form_summary{
    /* margin: 0 auto; */
    max-width: 1280px;
    padding: 32px 72px;
    background: rgba(255, 247, 241, 1);
    border-radius: 20px; 
}
.points_cotainer1{
   display: flex;
   flex-direction: column;
   gap: 32px;
   margin-bottom: 72px;
   max-width: 1280px;
}
.points_cotainer2{
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 1280px;
}
.reset_btn{
border: 1px solid rgba(176, 56, 61, 1);
width: 227px;
height:40px;
padding: 10px 36px 10px 36px;
border-radius: 128px;
gap: 8px;
text-transform: uppercase;
background: none;
cursor: pointer;
color: rgba(176, 56, 61, 1);
}
.reset_btn:hover{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.lets_conncet{
    border: none;
width: 185px;
height:28px;
padding: 8px 14px 8px 14px;
border-radius: 128px;
cursor: pointer;
display: flex;
justify-content: space-between;
align-items: center;
gap: 4px;
font-size: 16px;
text-transform: uppercase;
color: rgba(255, 255, 255, 1);
background: rgba(176, 56, 61, 1);
font-weight: 700;
}
.points_btn{
    display: flex;
    justify-content: flex-start;
    gap: 24px;
    margin-top: 52px;
}
.points_form_fields{
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}
.points_table td{
    font-family: Mulish;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;
    color: rgba(132, 132, 132, 1);
}
.sub_question_field{
    display: flex;
    flex-direction: column;
}
.summary_label{
    font-family: Mulish;
font-size: 24px;
font-weight: 500;
line-height: 29px;
letter-spacing: 0.0025em;
text-align: left;
color: 
rgba(59, 59, 59, 1);
}
.points_form_fields.has_sub_questions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: flex-start; */
  }
  
  .points_form_fields.sub_question {
     /* Adjust the indentation of sub-questions */
     margin-top: 18px;
  }
  
@media screen and (max-width: 880px) {
    .Points_layout {
        margin: 0 auto;
        padding: 16px;
    }
    .points_form_container {
        padding: 18px;
       
    }
    .points_btn{
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 24px;
    }
}
@media screen and (max-width: 480px) {
    .Points_layout {
        margin: 0 auto;
        padding: 16px;
    }
    .points_form_container {
        padding: 18px;
    
    }
    .points_form_summary {
        padding: 18px;
    }
    .summary_label {
        font-size: 18px !important;
    }
    .radio_labels{
        font-size: 16px;
    }
    .points_btn{
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 24px;
    }
    .points_question 
    p{
        font-family: Mulish;
        font-size: 18px;  
    }
    .heading_points {
        font-family: Mulish;
        font-size: 28px;
        font-weight: 900;
        line-height: 32px;
        letter-spacing: 0.0025em;
        text-align: left;
        color: rgba(4, 85, 144, 1);
    }
    .points_head_box {
        background: rgba(244, 244, 244, 1);
        border-radius: 20px;
        max-width: 1280px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 18px;
    }
    .subhead_point {
        font-family: Mulish;
        font-size: 20px;
        font-weight: 400;
        line-height: 34px;
        letter-spacing: 0.0025em;
        text-align: left;
        color: rgba(4, 85, 144, 1);
    }
    .point_connect {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 24px;
        font-family: Mulish;
        font-size: 20px;
        font-weight: 500;
        line-height: 29px;
        letter-spacing: 0.0025em;
        text-align: left;
    }
}


.AboutUsVisa_Box{
    border-radius: 20px;
background: #F4F4F4;
flex-shrink: 0;
font-family: Mulish;
padding: 32px;
margin-top: 24px;
}
.AboutUsVisa_Box li{
    color: #3B3B3B;
font-size: 24px;
font-style: normal;
font-weight: 500;
font-family: Mulish;
line-height: 140%;
letter-spacing: 0.06px;
/* text-decoration-line: underline; */
}
.AboutUsVisa_box_desc span{
    color:  #3B3B3B;
font-size: 16px;
font-style: normal;
font-weight: 700;
font-family: Mulish;
line-height: 42px; /* 125% */
letter-spacing: 0.04px;
padding-bottom: 24px;
padding-top: 24px;
}
.AboutUsVisa_box_fields{
    display: flex;
    gap: 24px;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 24px;

}
.AboutUsVisa_box_title{
    color: #3B3B3B;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    font-family: Mulish;
    width: 100%;
    line-height: 28px; /* 116.667% */
    letter-spacing: 0.06px;
}
.AboutUsVisa_box_desc{
    color: #848484;
font-size: 16px;
font-style: normal;
font-weight: 400;
font-family: Mulish;
line-height: 28px; /* 175% */
letter-spacing: 0.04px;
}
.AboutUsVisa_box_fields_content{
    border-radius: 20px;
background: #F4F4F4;
padding: 32px;
gap: 24px;
font-family: Mulish;
display: flex;
flex-direction: column;
align-items: flex-start;

}
@media screen and (max-width: 1024px) {

}
@media screen and (max-width: 880px) {
.AboutUsVisa_box_fields{
    display: flex;
    flex-direction: column;
}
.VisaStream_container li {
    color: #3B3B3B;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.06px;
 
}
.AboutUsVisa_box_fields_content {
    border-radius: 20px;
    background: #F4F4F4;
    padding: 24px;
    gap: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
    width: 83%;
}
.VisaStream_container {
    border-radius: 20px;
    background: #F4F4F4;
    /* width: 1280px; */
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex-shrink: 0;
    width: 80%;
    margin: 0 auto;
}
.VisaStream_container_holders {
    border-radius: 20px;
    background: #FFF7F1;
    padding: 24px;
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}
.AboutUsVisa_Box {
    border-radius: 20px;
    background: #F4F4F4;
    flex-shrink: 0;
    padding: 24px;
    margin-top: 24px;
    width: 80%;
    margin: 0 auto;
    margin-top: 24px;
}
.AboutUsVisa_Box li {
    color: #3B3B3B;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.06px;
    /* text-decoration-line: underline; */
}
.AboutUsVisa_box_title {
    color: #3B3B3B;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    font-family: Mulish;
    width: 100%;
    line-height: 28px;
    letter-spacing: 0.06px;
}
}
@media screen and (max-width: 480px) {
    .AboutUsVisa_box_fields{
        display: flex;
        flex-direction: column;
    }
    .VisaStream_container li {
        color: #3B3B3B;
        
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.06px;
     
    }
    .VisaStream_container {
        border-radius: 20px;
        background: #F4F4F4;
        /* width: 1280px; */
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        flex-shrink: 0;
        width: 80%;
        margin: 0 auto;
    }
    .AboutUsVisa_box_fields_content {
        border-radius: 20px;
        background: #F4F4F4;
        padding: 24px;
        gap: 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0 auto;
        width: 80%;
    }
    .AboutUsVisa_Box {
        border-radius: 20px;
        background: #F4F4F4;
        flex-shrink: 0;
        padding: 24px;
        margin-top: 24px;
        width: 80%;
        margin: 0 auto;
        margin-top: 24px;
    }
    .VisaStream_container_holders {
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        width: 80%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
    }
    .AboutUsVisa_Box li {
        color: #3B3B3B;
       
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.06px;
        /* text-decoration-line: underline; */
    }
    .AboutUsVisa_box_title {
        color: #3B3B3B;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        font-family: Mulish;
        width: 100%;
        line-height: 28px;
        letter-spacing: 0.06px;
    }
}

.helpYouMainLayout {
    margin: 0 auto;
    max-width: 1280px;
    padding-left: 72px;
    padding-top: 36px;
    padding-bottom: 36px;
    padding-right: 0px;
    padding: 36px;
}

.helpYouHeadingBox {
    border-radius: 20px;
    /* background: #F4F4F4; */
    padding: 32px;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
}

.helpYouTitle {
    /* color: #045590; */
    font-family:Mulish;
    font-size: 56px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%;
    letter-spacing: 0.14px;
}

.helpYouDesc {
    border-radius: 20px 0px 0px 20px;
    background: #F4F4F4;
    padding: 36px 72px;
    max-width: 650px;
    overflow: scroll;
}

.helpYouDesc::-webkit-scrollbar {
    display: none;
}

.helpOuterBox {
    display: flex;
}

.helpYouLayout {
    display: flex;
    gap: 24px;
}

.helpDescHeading {
    color: #3B3B3B;
    font-family:Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
    width: 330px;
}

.helpDescContent {
    color: #848484;
    font-family:Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    margin-top: 16px;
    margin-bottom: 52px;
    width: 90%;
    min-height: 2px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    overflow: hidden;
    white-space: normal;
    -webkit-box-orient: vertical;
}

.helpDescLearn {
    color: #B0383D;
    font-family:Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.04px;
    border-bottom: 1px solid #B0383D;
    width: fit-content;
    cursor: pointer;
}

.helpYouDesc {
    display: flex;
    align-items: center;
}

.helpSliderBox {
    display: flex;
    gap: 24px;
    align-items: center;
    padding-right: 32px;
    width: 90%;
}
.helpOuterBox{
    display: flex;
    gap: 24px;
    align-items: center;
    padding-right: 32px;
    overflow-y: auto; /* or overflow-y: scroll; */
    max-height: 400px;

}
.helpOuterBox::-webkit-scrollbar{
    display: none;
}
.helpYou-Image{
    width: 330px;
    height: 100%;
    border-radius: 20px;
}

@media screen and (max-width:1180px) {
    .helpYouLayout {
        flex-direction: column;
    }

    .helpYouMainLayout {
        padding: 0px;
    }

    .helpYouHeadingBox {
        margin: 54px;
    }

    .helpYou-Image {
        width: 332px;
        height: 315px;
    }

    .helpYouDesc {
        max-width: 1180px;
    }

    .helpDescHeading {
        width: 300px;
        font-size: 19px;
    }
    
}

@media screen and (max-width:950px) {
    .helpYouHeadingBox{
        margin: 24px;
    }
    .helpYouDesc {
        border-radius: 20px 0px 0px 20px;
        background: #F4F4F4;
        padding: 16px 16px;
        overflow: hidden;
        width: 100%;
        margin: 0 auto;
    }
}

@media screen and (max-width:730px) {
    .helpSliderBox {
        flex-direction: column;
        display: flex;
        gap: 16px;
        padding: 0px;
        align-items: flex-start;
        justify-content: center;
        margin: 0 auto;
    
    }

    .helpYou-Image {
        width: 250px;
        height: auto;
        border-radius: 20px !important;
    }
    .helpYouHeadingBox{
        margin: 16px;
    }
    .helpYouDesc{
        padding: 16px;
        border-radius: 0px;
    }
    .helpDescContent{
        margin-bottom: 16px;
        max-width: 300px;
        line-height: 20px;
    }
    .helpYouTitle{
        font-size: 24px;
    }
    .helpYouHeadingBox{
        padding: 16px;
    }
    .helpYouLayout{
        gap: 0px;
    }
    .helpYouDesc {
        border-radius: 20px 0px 0px 20px;
        background: #F4F4F4;
        padding: 16px 16px;
        overflow: hidden;
        width: 100%;
        margin: 0 auto;
    }
}
@media screen and (max-width:480px) {
.helpYouDesc {
    border-radius: 20px 0px 0px 20px;
    background: #F4F4F4;
    padding: 16px 16px;
    max-width: 650px;
    overflow: hidden;
}
.helpYou-Image {
    width: 280px;
    height: auto;
    border-radius: 20px;
}
.helpDescContent {
    /* margin-bottom: 16px;
    max-width: 165px;
    line-height: 17px; */
    font-size: 14px;
}
.helpDescHeading {
    /* width: 166px; */
}
.helpYouDesc {
    border-radius: 20px 0px 0px 20px;
    background: #F4F4F4;
    padding: 24px 24px !important;
    /* max-width: 650px; */
    width: 330px;
    display: flex;
    align-items: center;
    overflow: hidden;
}
.helpOuterBox {
    display: flex;
    gap: 32px;
    align-items: flex-start;
    padding-right: 16px;
    overflow-y: auto;
    max-height: 418px;
}
}
.VisaFamily_Box{
    border-radius: 20px;
background: #F4F4F4;
padding: 32px;
display: flex;
justify-content: center;
align-items: flex-start;
gap: 16px;
flex-direction: column;
color: #3B3B3B;
font-family: Mulish;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 33.6px */
letter-spacing: 0.06px;
margin-top: 24px;
}
.VisaFamily_Box 
span
li{
    font-size: 16px;
    color: rgba(132, 132, 132, 1);
    font-family: Mulish;
font-size: 16px;
font-weight: 400;
line-height: 25px;
text-decoration: underline;
cursor: pointer;
letter-spacing: 0.0025em;
text-align: left;


}
.container_layout{
    margin-top: 24px;
    margin-bottom: 72px;
}
.VisaFamily_Box li{
    color: #3B3B3B;
font-family: Mulish;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 33.6px */
letter-spacing: 0.06px;
}
.VisaFamily_Box_fields{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    font-family: Mulish;
    gap:24px;
}
.VisaFamily_Box_desc li{
    color: #848484;
    font-family: Mulish;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 30px; 
letter-spacing: 0.04px;
}
.VisaFamily_Box_fields_container{
    width: 345px;
    border-radius: 20px;
background: #F4F4F4;
padding: 32px;
display: flex;
height: 420px;
flex-direction: column;
align-items: flex-start;
gap: 16px;
flex-shrink: 0;
}
@media screen and (max-width: 880px) {
    .VisaFamily_Box_fields {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 24px;
        flex-direction: column;
        gap: 24px;
    }
    .VisaFamily_Box {
        border-radius: 20px;
        background: #F4F4F4;
        padding: 24px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        flex-direction: column;
        color: #3B3B3B;
        
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.06px;
        margin-top: 24px;
        width: 80%;
        margin: 0 auto;
        margin-top: 24px;
    }
    .VisaFamily_Box li {
        color: #3B3B3B;
        font-family: Mulish;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.06px;
    }
}
@media screen and (max-width: 480px) {
    .VisaFamily_Box_fields {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 24px;
        flex-direction: column;
        gap: 24px;
    }
    .VisaFamily_Box {
        border-radius: 20px;
        background: #F4F4F4;
        padding: 24px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        flex-direction: column;
        color: #3B3B3B;
        font-family: Mulish;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.06px;
        margin-top: 24px;
        width: 80%;
        margin: 0 auto;
        margin-top: 24px;
    }
    .VisaFamily_Box li {
        color: #3B3B3B;
        font-family: Mulish;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.06px;
    }
}
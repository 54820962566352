.SkilledComp3_layout{
    margin: 0 auto;
    max-width: 1280px;
    /* padding: 36px 72px; */
    display: flex;
    margin-bottom: 72px;
    flex-direction: column;
    gap: 24px;
}
.SkilledComp3_Title{
    background: rgba(255, 247, 241, 1);
    font-family: Mulish;
    font-size: 56px;
    font-weight: 900;
    line-height: 78px;
    letter-spacing: 0.0025em;
    text-align: left;
    color: rgba(176, 56, 61, 1);
    border-radius: 20px;
    padding: 32px;
}
.SkilledComp3_fields{
    display: flex;
    /* justify-content: center; */
    gap: 24px;
}
.SkilledComp3_fields_Box{
    background: rgba(255, 247, 241, 1);
    padding: 32px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 20px;
    align-items: flex-start;
    gap: 24px;
    width: 360px;

}
.SkilledComp3_box_Title{
    font-family: Mulish;
font-size: 24px;
font-weight: 500;
line-height: 28px;
letter-spacing: 0.0025em;
text-align: left;
color: rgba(59, 59, 59, 1);
max-width: 292px;
}
.SkilledComp3_box_Desc{
    max-width: 292px;
    font-family: Mulish;
font-size: 16px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.0025em;
text-align: left;
color: rgba(132, 132, 132, 1);
}
@media screen and (max-width: 880px) {
    .SkilledComp3_layout{
        margin: 0 auto;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    .SkilledComp3_Title {
        background: rgba(255, 247, 241, 1);
        font-family: Mulish;
        font-size: 28px;
        font-weight: 900;
        line-height: 48px;
        letter-spacing: 0.0025em;
        text-align: left;
        color: rgba(176, 56, 61, 1);
        border-radius: 20px;
        padding: 24px;
    }
    }
    @media screen and (max-width: 480px) {
        .SkilledComp3_layout{
            margin: 0 auto;
            padding: 16px;
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
        .SkilledComp3_fields {
            display: flex;
            max-width: 600px;
            overflow: scroll;
            flex-direction: column;
            gap: 24px;
        }
        .SkilledComp3_fields::-webkit-scrollbar{
            display: none;
        }
       
        .SkilledComp3_fields_Box {
            background: rgba(255, 247, 241, 1);
            padding: 24px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            border-radius: 20px;
            align-items: flex-start;
            gap: 24px;
            width: 80%;
        }
        .SkilledComp3_box_Title {
            font-family: Mulish;
            font-size: 22px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0.0025em;
            text-align: left;
            color: rgba(59, 59, 59, 1);
            min-width: 280px
        }
        .SkilledComp3_box_Desc {
            font-family: Mulish;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.0025em;
            text-align: left;
            color: rgba(132, 132, 132, 1);
        }
        .SkilledComp3_Title {
            background: rgba(255, 247, 241, 1);
            font-family: Mulish;
            font-size: 24px;
            font-weight: 900;
            line-height: 42px;
            letter-spacing: 0.0025em;
            text-align: left;
            color: rgba(176, 56, 61, 1);
            border-radius: 20px;
            padding: 22px;
            width: 80%;
        }
        }
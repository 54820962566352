.privacy_container_css{
    margin: 0 auto;
    padding: 24px 32px;
}
.Privacy-data{
    gap: 24px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: baseline;
   /* margin: 0 72px; */
   max-width: 1280px;
   margin: 0 auto;
}
.PrivacyPolicy{
    border-radius: 20px;
    background: var(--Grey-BG, #F4F4F4);
    padding: 32px;
    width: -webkit-fill-available;
    text-align: left;
    color: var(--Blue, #014A91);
    font-family: Mulish;
    font-size: 56px;
    font-style: normal;
    font-weight: 900;
    letter-spacing: 0.14px;
}
.privacy-disclaimer{
    flex-shrink: 0;
    border-radius: 20px;
    background: #F4F4F4;
    color: #3B3B3B;
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; 
    letter-spacing: 0.06px;
    text-align: left;
    padding: 32px;
    flex-wrap: wrap;
    width: -webkit-fill-available;
}
.privacy-subheading{
    color: #3B3B3B;
font-family: Mulish;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 120%;
letter-spacing: 0.06px;
text-align: left;
flex-wrap: wrap;
}
.privacy-content{
    color: #848484;
font-family: Mulish;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
text-align: left;
flex-wrap: wrap;
}
.privacy-content1{
    color: #848484;
font-family: Mulish;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
text-align: start;
flex-wrap: wrap;
}
.privacy-affiliation{
    display: flex;
    flex-direction: column;
padding: 32px ;
justify-content: left;
gap: 24px;
border-radius: 20px;
background: #F4F4F4;
width: -webkit-fill-available;

}
.privacy-certification{
    display: flex;
    flex-direction: column;
padding: 32px 225px 32px 32px;
justify-content: left;
gap: 24px;
border-radius: 20px;
background: #F4F4F4;
width: -webkit-fill-available;

}
.privacy-rights{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 24px;
}
.privacyIntel{
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 20px;
    background: #F4F4F4;
    flex: 1;
}
.privacyIntel1{
    display: flex;
    padding: 32px 32px 40px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 20px;
    background: #F4F4F4;
    flex: 1;
    min-height: 192px;
}
.privacyIntel2{
    display: flex;
    padding: 32px 32px 40px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 20px;
    background: #F4F4F4;
    flex: 1;
    min-height: 302px;
}
.privacyIntel3{
    display: flex;
    padding: 32px 32px 40px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 20px;
    background: #F4F4F4;
    flex: 1;
    min-height: 100px;
}
.privacyIntel4{
    display: flex;
    padding: 32px 32px 40px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 20px;
    background: #F4F4F4;
    flex: 1;
    min-height: 245px;
}
.privacyIntel5{
    display: flex;
    padding: 32px 32px 40px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 20px;
    background: #F4F4F4;
    flex: 1;
    min-height: 192px;
}
.privacyIntel6{
    display: flex;
    padding: 32px 32px 40px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 20px;
    background: #F4F4F4;
    flex: 1;
    min-height: 142px;
}
.privacy-usenews{
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 24px;
    width: -webkit-fill-available;
}
.privacy-use{
    display: flex;
    padding: 32px 32px 60px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 20px;
    background: #F4F4F4;
}
.privacy-limitation1{
    display: flex;
    padding: 32px ;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 20px;
    background: #F4F4F4;
    width: -webkit-fill-available;
}
.privacy-limitation2{
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 20px;
    background: #F4F4F4;
    width: -webkit-fill-available;
}
.privacy-list{
    display: flex;
    gap: 308px;
}
.ul-list{
    padding-left: 24px;
    text-align: left;
}
.privacy-copyright {
    display: flex;
    gap: 23px; 
    flex-direction: row;
}
.privacy-copydesc{
    box-sizing: border-box;
    padding: 32px; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 20px;
    background: #F4F4F4;
}
.privacy-copyright1 {
    display: flex;
    gap: 23px; 
    flex-wrap: wrap;
}
.privacy-copydesc1{
    width: calc(33.33% - 16px); 
    box-sizing: border-box;
    padding: 32px; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 20px;
    background: #F4F4F4;
}
.privacy-copyright2 {
    display: flex;
    gap: 23px; 
    flex-wrap: wrap;
}
.privacy-copydesc2{
    width: calc(50% - 16px);
    box-sizing: border-box;
    padding: 32px; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 20px;
    background: #F4F4F4;
}
.ol-list{
    padding-left: 24px;
    text-align: justify;
    display: flex;
    gap: 50px;
}
@media screen and (min-width:901px)and (max-width:1080px){
    .privacy-list {
        display: flex;
        gap: 37px;
    }
    .privacyIntel1 {
        min-height: 253px;
    }
    .privacyIntel2 {
        min-height: 340px;
    }
    .privacyIntel3 {
        min-height: 130px;
    }
    .privacyIntel4 {
        min-height: 344px;
    }
    .privacyIntel5 {
        min-height: 253px;
    }
    .privacyIntel6 {
        min-height: 155px;
    }
    .privacy-copyright1 {
        display: flex;
        gap: 23px; 
        flex-wrap: wrap;
    }
    .privacy-copydesc1{
        width: calc(33.33% - 16px); 
        box-sizing: border-box;
        padding: 32px; 
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        border-radius: 20px;
        background: #F4F4F4;
    }
    .privacy-copyright {
        display: flex;
        gap: 23px; 
        flex-wrap: wrap;
    }
    .privacy-copydesc{
        width: calc(33.33% - 16px); 
        box-sizing: border-box;
        padding: 32px; 
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        border-radius: 20px;
        background: #F4F4F4;
    }
    
}
@media screen and (max-width: 900px) {
    .privacy-copydesc1 {
        width: calc(50% - 16px);
        box-sizing: border-box;
        padding: 32px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        border-radius: 20px;
        background: #F4F4F4;
    }
    .privacyIntel1 {
        min-height: 382px;
    }  
    .privacyIntel2 {
        min-height: 555px;
    }   
    .privacyIntel3 {
        min-height:180px;
    } 
    .privacyIntel4 {
        min-height: 473px;
    }
    .privacyIntel5 {
        min-height: 302px;
    }
    .privacyIntel6 {
        min-height: 258px;
    }
    .privacy-certification {
        display: flex;
        flex-direction: column;
        padding: 32px;
        justify-content: left;
        gap: 24px;
        border-radius: 20px;
        background: #F4F4F4;
    }
    .privacy-list {
        display: flex;
        gap: 30px;
    }
    .privacy-rights {
        flex-direction: column;
        gap: 24px;
    }
    .privacy-usenews {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 23px;
    }
    .privacy-use {
        flex: 0 1 calc(50% - 16px);
        box-sizing: border-box;
        min-height: 200px;
    }
    .privacy-copyright {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 23px;
    }
    .privacy-copydesc {
        flex: 0 1 calc(50% - 16px);
        box-sizing: border-box;
    }
    .PrivacyPolicyName {
        font-size: 50px;
        text-align: left;
    }
}

@media screen and (max-width: 599px) {
    .privacyIntel1 , .privacyIntel2 , .privacyIntel3 , .privacyIntel4 , .privacyIntel5 , .privacyIntel6 {
        min-height: fit-content;
        gap: 15px;
    }
    .Privacy-data {
        gap: 24px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: baseline;
        /* margin: 0px 40px; */
    }
    .PrivacyPolicy {
        padding: 32px;
        font-size: 37px;
    }
    .PrivacyPolicyName {
        font-size: 40px;
    }
    .privacy-disclaimer {
        font-size: 18px;
        padding: 32px;
    }

    .privacy-subheading {
        font-size: 18px;
        max-height: 50px;
    }

    .privacy-content {
        font-size: 14px;
        line-height: 18px;
    }

    
    .privacy-certification,
    .privacy-use,
    
    .privacyIntel,
    .privacy-usenews {
        padding: 32px;
        min-width: -webkit-fill-available;
        gap: 20px;
    }
    .privacy-limitation1,
    .privacy-limitation2{
        padding: 32px;
        min-width: -webkit-fill-available;
        gap: 12px;
    }
    .privacy-affiliation{
        padding: 32px;
        min-width: -webkit-fill-available;
        gap: 20px;
    }
    .privacy-list {
        flex-direction: column;
        gap: 15px;
    }
    .privacy-copyright {
        flex-direction: column;
        gap: 15px;
    }
    .privacy-copydesc {
        flex: 1 1 100%;
        padding: 32px;
    }
    .privacy-rights {
        flex-direction: column;
        gap: 15px;
    }
    .privacy-usenews {
        flex-direction: column;
        gap: 15px;
        padding: 0px;
    }
    .ol-list {
        gap: 10px;
        padding-left: 24px;
        text-align: left;
        display: flex;
        flex-direction: column;
        padding-left: 16px;
    }
    .privacy-copydesc1 {
        width: auto;
        box-sizing: border-box;
        padding: 32px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 14px;
        border-radius: 20px;
        background: #F4F4F4;
    }
    .privacy-copyright2 {
        display: flex;
        gap: 23px;
        flex-wrap: wrap;
        flex-direction: column;
    }
    .privacy-copydesc2 {
        width: auto;
        box-sizing: border-box;
        padding: 32px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0px;
        border-radius: 20px;
        background: #F4F4F4;
    }
}
@media screen and (max-width:350px)
{
    .privacy-subheading {
        font-size: 18px;
        max-height: 50px;
    }
    .privacyIntel1 {
        min-height: 240px;
        gap: 4px;
    }
    .privacyIntel4 {
        min-height: 300px;
    }
    .privacyIntel5 {
        min-height: 150px;
    }
    .privacyIntel2 {
        min-height: 200px;
    }
    .privacyIntel3 {
        min-height: 110px;
    }
    .privacy-copydesc {
        flex: 1 1 100%;
        padding: 24px;
        gap: 0px;
    }
    .privacy-copydesc1 {
        width: auto;
        box-sizing: border-box;
        padding: 32px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0px;
        border-radius: 20px;
        background: #F4F4F4;
    }
    .privacy-affiliation, .privacy-certification, .privacy-use, .privacy-limitation1, .privacy-limitation2, .privacyIntel {
        padding: 24px;
        min-width: -webkit-fill-available;
        gap: 10px;
    }
    .privacy-usenews{
        padding: 0px 0px;
    }
}



    

.whyus-layout {
    margin: 0 auto;
    max-width: 1280px;
    /* padding: 36px 72px; */
}

.whyus-heading {
    height: auto;
    border-radius: 20px;
    background: #FFF7F1;
    padding: 32px;
    margin-bottom: 24px;
}

.why-us-heading {
    color: #B0383D;
    font-family: Arial;
    font-size: 56px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%;
    letter-spacing: 0.14px;
}

.why-layout-one {
    max-width: 299px;
    height: auto;
    border-radius: 20px;
    background: #FFF7F1;
    padding: 32px;
}

.why-heading {
    color: #3B3B3B;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
    margin-bottom: 24px;
}

.why-desc {
    color: #848484;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.04px;
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid #D9C0AA;
}
.contentBoadyOverview_end{
    max-width: 1280px;
flex-shrink: 0;
line-height: 25px;
background: #FFF7F1;
padding: 32px;
margin: 0 auto;
border-radius: 20px;
margin-top: 24px;
}

.why-heading1 {
    color: #3B3B3B;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
    margin-bottom: 24px;
}

.why-desc1 {
    color: #848484;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.04px;
    width: 300px;
}

.why-two-service {
    height: auto;
    border-radius: 20px;
    background: #FFF7F1;
    padding: 32px;
}

.why-two-trans {
    height: auto;
    border-radius: 20px;
    background: #FFF7F1;
    padding: 32px;
}

.why-two-sublayout2 {
    padding: 32px 32px;
    border-radius: 20px;
    background: #FFF7F1;
    height: 125px;
}

.why-two-head {
    color: #3B3B3B;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
    margin-bottom: 24px;
}

.why-two-desc {
    color: #848484;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.04px;
}

.why-two-support {
    color: #3B3B3B;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
    margin-bottom: 24px;
}

.why-main-layout2{
    display: none;
}

.why-two-support-desc {
    color: #848484;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.04px;
}

.why-two-sublayout1 {
    display: flex;
    gap: 24px;
}

.why-main-layout {
    display: flex;
    gap: 24px;
    justify-content: space-between;
}

.why-layout-two {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    gap: 24px;
}

@media screen and (max-width: 1050px) {
    .whyus-layout{
        padding: 24px 32px;
    }
}

@media screen and (max-width: 960px) {
    .why-main-layout {
        flex-direction: column;
    }

    .whyus-layout{
        padding: 24px 32px;
    }

    .why-layout-one {
        max-width: 830px;
        display: flex;
    }

    .why-desc {
        padding: 0px;
        margin: 0px;
        border-bottom: none;
    }

    .why-layout-ext {
        padding-left: 32px;
        border-left: 1px solid #D9C0AA;
        margin-left: 32px;
    }
    .why-two-service {
        height: auto;
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
    }
}

@media screen and (max-width: 660px) {
    .whyus-heading{
        padding: 16px;
        width: 90%;

    }
    .why-us-heading{
        font-size: 24px;
    }
    .whyus-layout{
        padding: 24px 16px;
    }
    .why-main-layout{
        display: none;
    }
    .why-main-layout2{
        display: flex;
        gap: 16px;
        max-width: 1000px;
        overflow: scroll;
    }
    .why-main-layout2::-webkit-scrollbar {
        display: none;
    }
    .why-two-desc{
        width: 300px;
    }
    .why-two-service {
        height: auto;
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
    }
}

@media screen and (max-width: 400px) {
    .why-two-desc{
        width: 260px;
    }
    .why-two-service {
        height: auto;
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
    }
    
    
}
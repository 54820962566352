.OverSeaLayout{
    margin: 0 auto;
    padding: 72px 72px 0px 72px;
    display: flex;
    max-width: 1280px;
    flex-direction: column;
    gap: 24px;
}
.OverSea_box{
    width: 556px;
height: 100px;
border-radius: 20px;
padding: 32px;
font-size: 16px;
line-height: 23px;
font-family: Mulish;
background: rgba(244, 244, 244, 1);

}
.OverSea_des{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.Oversea_Why_Title{
    color: rgba(1, 74, 145, 1);
    font-family: Mulish;
font-size: 56px;
padding: 32px;
font-weight: 900;
line-height: 78px;
letter-spacing: 0.0025em;
text-align: left;
border-radius: 20px;
background: rgba(244, 244, 244, 1);


}
.OverSea_des p{
    font-family: Mulish;
font-size: 16px;
font-weight: 400;
font-family: Mulish;
line-height: 22px;
letter-spacing: 0.0025em;
text-align: left;

}
.Oversea_Why_Content{
    display: flex;
    gap: 24px; font-family: Mulish;
    align-items: center;
}
.OverSeaWhyVideo {
    width: 100%;
    height: 100%;
    object-fit: cover;
 font-family: Mulish;
    border-radius: 20px;
  }
.Oversea_Why_Content{
    /* background: rgba(244, 244, 244, 1); */
    padding: 24px;
    border-radius: 20px;
}
@media screen and (max-width:950px) {
    .Oversea_Why_Content{
        display: flex;
        gap: 24px;
        flex-direction: column;
        align-items: center;
    }
    .OverSeaWhyVideo {
        Width: 300px;
        Height: auto;
        border-radius: 20px;
    }
    .OverSeaLayout {
        margin: 0 auto;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    .Oversea_Why_Title {
        color: rgba(1, 74, 145, 1);
        font-family: Mulish;
        font-size: 28px;
        padding: 24px;
        font-weight: 900;
        line-height: 40px;
        letter-spacing: 0.0025em;
        text-align: left;
        border-radius: 20px;
        background: rgba(244, 244, 244, 1);
    }
}
@media screen and (max-width:480px) {
    .Oversea_Why_Content{
        display: flex;
        gap: 24px;
        flex-direction: column;
        align-items: center;
    }
    .OverSeaWhyVideo {
        Width: 300px;
        Height: auto;
        border-radius: 20px;
    }
    .OverSeaLayout {
        margin: 0 auto;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    .Oversea_Why_Title {
        color: rgba(1, 74, 145, 1);
        font-family: Mulish;
        font-size: 28px;
        padding: 24px;
        font-weight: 900;
        line-height: 40px;
        letter-spacing: 0.0025em;
        text-align: left;
        border-radius: 20px;
        background: rgba(244, 244, 244, 1);
    }
}
.country-layout {
    text-align: center;
    margin: 0 auto;
    max-width: 1280px;
    padding: 36px 72px;
    overflow-x: hidden;
}

.country-layout::-webkit-scrollbar {
    display: none;
}

.country-scroll {
    display: flex;
    gap: 24px;
    width: 2000px;
}

.country {
    width: 300px;
    height: 60px;
    border-radius: 15px;
    background: #FFF7F1;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;

}
.country_country{
    width: 300px;
    height: 60px;
    border-radius: 15px;
    background: #FFF7F1;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
}
.btn_country{
    background: #B0383D;
    color: white;
    font-size: 12px;
    border: none;
    width: 150px;
    height: 30px;
    display: flex;
    gap: 4px;
    justify-content: center;
    border-radius: 20px;
    cursor: pointer;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
}
.row1_country{
    display: flex;
    position: absolute;
    gap: 16px;
    max-width: 363px;
    height: 260px;
    flex-wrap: wrap;
    padding-left: 16px;
    /* height: 50px; */
    background-color: #FFF7F1;
}
.country_country:hover {
    /* height: 212px; */
    height: 500px;
}

.country-name {
    color: #B0383D;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    cursor: pointer;
    line-height: 28px;
    letter-spacing: 0.06px;
    text-align: left;
    padding: 16px;
   
}

.row1 {
    display: flex;
    position: absolute;
    gap: 16px;
    max-width: 363px;
    height: 260px;
    flex-wrap: wrap;
    padding-left: 16px;
    /* height: 50px; */
    background-color: #FFF7F1;
}
.row4{
    display: flex;
    position: absolute;
    gap: 12px;
    max-width: 363px;
    /* height: 370px; */
    flex-wrap: wrap;
    padding-left: 16px;
    /* height: 50px; */
    background-color: #FFF7F1;
}

.country:hover {
    /* height: 212px; */
    height: 280px;
}

.country:hover .row1 {
    display: flex;
    height: 50px;
}

.info-button {
    color:rgba(176, 56, 61, 1);
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    cursor: pointer;
    letter-spacing: 0.035px;
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #FFF;
    border: none;
}
@media screen and (max-width: 880px) {
.country-layout {
    text-align: center;
    margin: 0 auto;
    max-width: 1280px;
    padding: 32px 10px;
    overflow-x: scroll;
}
}
@media screen and (max-width: 660px) {
    .country-scroll{
        flex-direction: column;
        max-width: 660px;
        width: 100%;
    }

    .country-name{
        font-size: 20px;
    }

    .country-layout{
        padding: 16px;
    }

    .country{
        width: 100%;
    }
    .country_country{
        width: 100%;
    }
    .row1{
        max-width: 550px;
    }
   
}
.Tabs_container_layout{
    max-width: 1280px;
    margin: 0 auto;

}
.TabsContainer {
    display: flex;
    justify-content:flex-start;
    align-items: center;
    gap: 24px;
    font-family: Mulish;
    max-width: 1280px;
    margin: 0 auto;
    padding: 36px 72px;
    overflow: scroll;
    
  }
  .Tab {
    color: rgba(0, 0, 0, 0.25);
    font-family: Mulish;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px; 
    letter-spacing: 0.09px;
    cursor: pointer;
  }
  .Tab.isActive {
    color: #B0383D;
    font-family: Mulish;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px; 
    letter-spacing: 0.09px;
    border-bottom: 2px solid #B0383D;
    cursor: pointer;
  }
  .ConsultationComponent_field{
    display: flex;
    gap: 24px;
    max-width: 1280px;
    margin: 0 auto;
    margin-top: 72px;
    /* margin-bottom: 24px; */
  }
  .Overview_faq_container{
    max-width: 1280px;
    margin: 0 auto;
    margin-bottom: 72px;

}
.overview_accordian_container{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.Overview_faq_container_head{
    width: 1210px;
flex-shrink: 0;
padding: 32px;
color: #014A91;

font-size: 56px;
font-style: normal;
font-weight: 900;
line-height: 140%; /* 78.4px */
letter-spacing: 0.14px;
border-radius: 20px;
background: #F4F4F4;
}
.faq_accordian_video_contaner{
    display: flex;
    justify-content: space-between;
    align-self: center;
    gap: 22px;
    margin-top: 24px;
}

.overview_accordian-submain {
    width: 556px;
    flex-shrink: 0;
    border-radius: 20px;
background: #FFF7F1;
    padding: 32px;
    align-items: center;
    cursor: pointer;
}

.overview_accordian-heading {
    color: #3B3B3B;
   
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 116.667% */
    letter-spacing: 0.06px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.overview_accordian-details {
    color: #848484;
   
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
    letter-spacing: 0.04px;
    width: 334px;
    height: 100px;
    margin-top: 24px;
}
.overview-img {
    width: 628px;
    height: 450px;
    flex-shrink: 0;
    border-radius: 20px;
}





@media screen and (max-width: 1300px) {
    .layout-content {
        width: 726px;
    }

    .tablist {
        min-width: 180px;
    }
}

@media screen and (max-width: 1024px) {
    .overview_accordian-submain {
        width: 556px;
        padding: 24px;
    }
    .Overview_faq_container {
        max-width: 1280px;
        margin: 0 auto;
        padding: 24px 32px;
    }
    .overview-img {
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        border-radius: 20px;
    }
    .Overview_faq_container_head {
        padding: 24px;
        flex-shrink: 0;
     
    }
    .ConsultationComponent_field {
        display: flex;
        gap: 24px;
        max-width: 1280px;
        margin: 0 auto;
        padding: 24px 32px;
    }
}

@media screen and (max-width: 1100px) {
    .layout-content {
        width: 626px;
    }

    .overview_accordian-submain {
        width: 556px;
        padding: 24px;
    }
    
    
}


@media screen and (max-width: 980px) {
    .layout-content {
        width: 500px;
    }

    .faqpage {
        padding-right: 0px;
    }

    .overview_accordian-submain {
        width: 90%;
        padding: 24px;
    }
    .ConsultationComponent_field{
        display: flex;
        gap: 24px;
        max-width: 1280px;
        margin: 0 auto;
        max-width: 1000px;
        overflow: scroll;
      }
      .ConsultationComponent_component {
        width: 330px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        gap: 14px;
        padding: 18px;
        padding: 23px;
        border-radius: 15px;
        background: #F4F4F4;
    }
    .ConsultationComponent_field::-webkit-scrollbar{
        display: none;
    }
    .Overview_faq_container_head {
          padding: 24px;
        flex-shrink: 0;
        padding: 24px;
        color: #014A91;
       
        font-size: 32px;
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.14px;
        border-radius: 20px;
        background: #F4F4F4;
    }
    .overview-img {
        width: 370px;
        /* height: 462px; */
        flex-shrink: 0;
        border-radius: 20px;
    }
    .TabsContainer {
        display: flex;
        justify-content:space-between;
        align-items: center;
        gap: 24px;
        max-width: 1000px;
        margin: 0 auto;
        margin-bottom: 72px;
        margin-top: 72px;
        overflow: scroll;
      }
      .Tab {
        color: rgba(0, 0, 0, 0.25);
       
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; 
        letter-spacing: 0.06px;
      }
      .Tab.isActive {
        color: #B0383D;
       
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; 
        letter-spacing: 0.06px;
        border-bottom: 2px solid #B0383D;
      }
}

@media screen and (max-width: 880px) {
  
    
    .faq_accordian_video_contaner {
        display: flex;
        justify-content: space-between;
        align-self: center;
        gap: 22px;
        margin-top: 24px;
        flex-direction: column;
    }
    .faq_container_head{
        width: 80%;
    flex-shrink: 0;
    padding: 18px;
    color: var(--Red, #B0383D);
   
    font-size: 25px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%; 
    letter-spacing: 0.143px;
    border-radius: 20px;
    background: #FFF7F1;
    }
    .Overview_faq_container{
        /* width: 100%; */
    padding: 16px;
    margin: 0 auto;
    }
    .ConsultationComponent_field{
        display: flex;
        gap: 24px;
        max-width: 1280px;
        margin: 0 auto;
        max-width: 1000px;
        overflow: scroll;
        padding: 24px 32px;
      }
      .ConsultationComponent_component {
        width: 330px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        gap: 14px;
        padding: 18px;
        padding: 23px;
        border-radius: 15px;
        background: #F4F4F4;

    }
    .ConsultationComponent_field::-webkit-scrollbar{
        display: none;
    }
    .Overview_faq_container_head {
        padding: 24px;
        flex-shrink: 0;
        padding: 24px;
        color: #014A91;
       
        font-size: 32px;
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.14px;
        border-radius: 20px;
        background: #F4F4F4;
    }
    .overview-img {
        width: 95%;
        flex-shrink: 0;
        border-radius: 20px;
        padding: 8px;
        margin: 0 auto;    
    }
    .TabsContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0px;
        max-width: 1000px;
        margin: 0 auto;
        margin-bottom: 28px;
        margin-top: 28px;
        padding: 0px;
        overflow: scroll;
      }
      .Tab {
        color: rgba(0, 0, 0, 0.25);
       
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; 
        letter-spacing: 0.06px;
      }
      .Tab.isActive {
        color: #B0383D;
       
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; 
        letter-spacing: 0.06px;
        border-bottom: 2px solid #B0383D;
      }
}


@media screen and (max-width: 780px) {
    
    .overview_accordian-submain {
        width: 80%;
        padding: 24px;
        margin: 0 auto;
    }
    .faq_container_image{
        display: none;
    }
    .ConsultationComponent_field {
        display: flex;
        gap: 12px;
        max-width: 1280px;
        margin: 0 auto;
        max-width: 1280px;
        overflow: scroll;
        padding: 32px;
    }
    .ConsultationComponent_component {
        width: 330px;
        padding: 24px;
    }
    
    .Overview_faq_container_head {
        /* width: 330px; */
        flex-shrink: 0;
        padding: 24px;
        color: #014A91;
       
        font-size: 32px;
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.14px;
        border-radius: 20px;
        background: #F4F4F4;
    }
    .overview-img {
        width: 85%;
        height: 280px;
        flex-shrink: 0;
        border-radius: 20px;
        padding: 8px;
    }
    .overview_accordian-details {
        color: #848484;
       
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0.04px;
        width: 260px;
        height: 100px;
        margin-top: 24px;
    }
    .whyus-heading {
        height: auto;
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        margin-bottom: 24px;
        width: 85%;
    }
    .overview_accordian_container {
        display: flex;
        gap: 24px;
    }
    .why-us-heading{
font-size: 32px;
    }
    .TabsContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0px;
        max-width: 1000px;
        margin: 0 auto;
        margin-bottom: 24px;
        margin-top: 24px;
        overflow: scroll;
      }
      .Tab {
        color: rgba(0, 0, 0, 0.25);
        font-family: Mulish;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; 
        letter-spacing: 0.06px;
      }
      .Tab.isActive {
        color: #B0383D;
       
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; 
        letter-spacing: 0.06px;
        border-bottom: 2px solid #B0383D;
      }
}


@media screen and (max-width: 750px) {
    .ConsultationComponent_field{
        display: flex;
        gap: 24px;
        max-width: 1280px;
        margin: 0 auto;
        max-width: 1000px;
        overflow: scroll;
      }
      .ConsultationComponent_component {
        width: 330px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        gap: 14px;
        padding: 18px;
        padding: 23px;
        border-radius: 15px;
        background: #F4F4F4;
    }
    .Overview_faq_container_head {
        /* width: 330px; */
        flex-shrink: 0;
        padding: 24px;
        color: #014A91;
       
        font-size: 32px;
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.14px;
        border-radius: 20px;
        background: #F4F4F4;
    }
    .overview-img {
        width: 370px;
        /* height: 462px; */
        flex-shrink: 0;
        border-radius: 20px;
    }
    .TabsContainer {
        display: flex;
        justify-content:space-between;
        align-items: center;
        gap: 24px;
        max-width: 1000px;
        margin: 0 auto;
        margin-bottom: 72px;
        margin-top: 72px;
        overflow: scroll;
      }
      .Tab {
        color: rgba(0, 0, 0, 0.25);
       
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; 
        letter-spacing: 0.06px;
      }
      .Tab.isActive {
        color: #B0383D;
       
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; 
        letter-spacing: 0.06px;
        border-bottom: 2px solid #B0383D;
      }
      .overview_accordian_container {
        display: flex;
        gap: 24px;
        margin: 0 auto;

    }
    
}

@media screen and (max-width: 660px) {
   
    .overview_accordian-heading{
        font-size: 18px;
    }
    .overview_accordian-details{
        font-size: 14px;
    }
    .ConsultationComponent_field{
        display: flex;
        gap: 24px;
        max-width: 1280px;
        margin: 0 auto;
        max-width: 1000px;
        overflow: scroll;
      }
      .ConsultationComponent_component {
        width: 330px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        gap: 14px;
        padding: 18px;
        padding: 23px;
        border-radius: 15px;
        background: #F4F4F4;
    }
    .Overview_faq_container_head {
        /* width: 330px; */
        flex-shrink: 0;
        padding: 24px;
        color: #014A91;
       
        font-size: 32px;
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.14px;
        border-radius: 20px;
        background: #F4F4F4;
    }
    .overview-img {
        width: 370px;
        /* height: 462px; */
        flex-shrink: 0;
        border-radius: 20px;
    }
    .TabsContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0px;
        max-width: 1000px;
        margin: 0 auto;
        margin-bottom: 16px;
        margin-top: 16px;
        overflow: scroll;
      }
      .Tab {
        color: rgba(0, 0, 0, 0.25);
       
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; 
        letter-spacing: 0.06px;
      }
      .Tab.isActive {
        color: #B0383D;
       
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; 
        letter-spacing: 0.06px;
        border-bottom: 2px solid #B0383D;
      }
    
}
@media screen and (max-width: 550px) {
    

    .overview_accordian-submain {
        width: 330px;
        padding: 24px;
    }
    .ConsultationComponent_field{
        display: flex;
        gap: 24px;
        max-width: 1280px;
        margin: 0 auto;
        max-width: 1000px;
        overflow: scroll;
      }
      .ConsultationComponent_component {
        width: 330px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        gap: 14px;
        padding: 18px;
        padding: 23px;
        border-radius: 15px;
        background: #F4F4F4;
    }
    .Overview_faq_container_head {
        width: 330px;
        flex-shrink: 0;
        padding: 24px;
        color: #014A91;
       
        font-size: 32px;
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.14px;
        border-radius: 20px;
        background: #F4F4F4;
    }
    .overview-img {
        width: 370px;
        /* height: 462px; */
        flex-shrink: 0;
        border-radius: 20px;
    }
    .TabsContainer {
        display: flex;
        justify-content:space-between;
        align-items: center;
        gap: 24px;
        max-width: 1000px;
        margin: 0 auto;
        margin-bottom: 72px;
        margin-top: 72px;
        overflow: scroll;
      }
      .Tab {
        color: rgba(0, 0, 0, 0.25);
       
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; 
        letter-spacing: 0.06px;
      }
      .Tab.isActive {
        color: #B0383D;
       
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; 
        letter-spacing: 0.06px;
        border-bottom: 2px solid #B0383D;
      }
}

@media screen and (max-width: 470px) {
    

    .overview_accordian-submain {
        width: 85%;
        padding: 24px;
        margin: 0 auto;
    
    }
    .ConsultationComponent_field{
        display: flex;
    gap: 24px;
    max-width: 1280px;
    margin: 0 auto;
    max-width: 1000px;
    padding: 16px;
    overflow: scroll;
    margin-top: 24px;
      }
      .ConsultationComponent_component {
        width: 330px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        gap: 14px;
        padding: 18px;
        padding: 23px;
        border-radius: 15px;
        background: #F4F4F4;
    }
    .Overview_faq_container_head {
        width: 330px;
        flex-shrink: 0;
        padding: 24px;
        color: #014A91;
       
        font-size: 32px;
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.14px;
        border-radius: 20px;
        background: #F4F4F4;
    }
    .overview-img {
        width: 370px;
        /* height: 462px; */
        flex-shrink: 0;
        border-radius: 20px;
        display: none;
    }
    .TabsContainer {
        display: flex;
        justify-content:space-between;
        align-items: center;
        gap: 24px;
        max-width: 1000px;
        margin: 0 auto;
        margin-bottom: 32px;
        margin-top: 32px;
        overflow: scroll;
      }
      .Tab {
        color: rgba(0, 0, 0, 0.25);    
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; 
        letter-spacing: 0.06px;
        min-width: 80px;
      }
      .Tab.isActive {
        color: #B0383D;    
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; 
        letter-spacing: 0.06px;
        border-bottom: 2px solid #B0383D;
        min-width: 80px;
      }
}

@media screen and (max-width: 380px) {
    .ConsultationComponent_field{
        display: flex;
        gap: 24px;
        max-width: 1280px;
        margin: 0 auto;
        max-width: 1000px;
        overflow: scroll;
      }
      .ConsultationComponent_component {
        width: 330px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        gap: 14px;
        padding: 18px;
        padding: 23px;
        border-radius: 15px;
        background: #F4F4F4;
    }
    .Overview_faq_container_head {
        width: 330px;
        flex-shrink: 0;
        padding: 24px;
        color: #014A91;
       
        font-size: 32px;
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.14px;
        border-radius: 20px;
        background: #F4F4F4;
    }
    .overview-img {
        width: 330px;
        /* height: 462px; */
        flex-shrink: 0;
        border-radius: 20px;
    }
    .TabsContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0px;
        max-width: 1000px;
        margin: 0 auto;
        overflow: scroll;
        margin-top: 24px;
        margin-bottom: 24px;
    
      }
      .Tab {
        color: rgba(0, 0, 0, 0.25);
        font-family: Mulish;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; 
        letter-spacing: 0.06px;
      }
      .Tab.isActive {
        color: #B0383D;
        font-family: Mulish;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; 
        letter-spacing: 0.06px;
        border-bottom: 2px solid #B0383D;
      }
}

@media screen and (max-width: 290px) {
    .overview_accordian-submain {
        width: 330px;
    }
    .ConsultationComponent_field{
        display: flex;
        gap: 24px;
        max-width: 1280px;
        margin: 0 auto;
        max-width: 1000px;
        overflow: scroll;
      }
      .ConsultationComponent_component {
        width: 330px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        gap: 14px;
        padding: 18px;
        padding: 23px;
        border-radius: 15px;
        background: #F4F4F4;
    }
    .Overview_faq_container_head {
        width: 330px;
        flex-shrink: 0;
        padding: 24px;
        color: #014A91;
       
        font-size: 32px;
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.14px;
        border-radius: 20px;
        background: #F4F4F4;
    }
    .overview-img {
        width: 370px;
        /* height: 462px; */
        flex-shrink: 0;
        border-radius: 20px;
    }
    .ConsultationComponent_component {
        width: 330px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;
        padding: 24px;
        border-radius: 15px;
        background: #F4F4F4;
    }
    .TabsContainer {
        display: flex;
        justify-content:space-between;
        align-items: center;
        gap: 24px;
        max-width: 1000px;
        margin: 0 auto;
        margin-bottom: 72px;
        margin-top: 72px;
        overflow: scroll;
      }
      .Tab {
        color: rgba(0, 0, 0, 0.25);
       
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; 
        letter-spacing: 0.06px;
      }
      .Tab.isActive {
        color: #B0383D;
       
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; 
        letter-spacing: 0.06px;
        border-bottom: 2px solid #B0383D;
      }
      
}
.TabsContainer::-webkit-scrollbar {
    display: none;
}

.VisaStream_container{
    border-radius: 20px;
background: #F4F4F4;
/* width: 1280px; */
padding: 32px;
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 16px;
flex-shrink: 0;
}
.VisaStream_container li{
    color: #3B3B3B;
font-size: 24px;
font-family: Mulish;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 33.6px */
letter-spacing: 0.06px;
max-width:1072px;
}
.VisaStream_title{
    color: #3B3B3B;
font-size: 24px;
font-family: Mulish;
font-style: normal;
font-weight: 500;
line-height: 28px; /* 116.667% */
letter-spacing: 0.06px;
}
.VisaStream_desc{
    color: #848484;
font-size: 16px;
font-family: Mulish;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 175% */
letter-spacing: 0.04px;
}
.VisaStream_desc_light li{
    color: #848484;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
    letter-spacing: 0.04px;
}
.VisaStream_container_fields{
    display: flex;
 margin-top: 24px;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;
}
.VisaStream_container_holders{
    border-radius: 20px;
background: #FFF7F1;
padding: 32px;
}

.EmpOverViewLayout {
    margin: 0 auto;
    max-width: 1280px;
    padding-bottom: 72px;
    /* padding: 32px 72px 32px 72px; */
}

.empOverViewHeadBox {
    padding: 32px;
    border-radius: 20px;
    background: #F4F4F4;
}

.empOVboxone {
    padding: 32px;
    border-radius: 20px;
    background: #F4F4F4;
    width: 562px;
    height: 160px;
}

.empOVlayout-two {
    display: flex;
    gap: 24px;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 24px;
}

.empOVlayout-three {
    padding: 32px;
    border-radius: 20px;
    background: #F4F4F4;
}

.empOverViewHeadBox {
    color: #014A91;
    font-family: Mulish;
    font-size: 56px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%;
    letter-spacing: 0.14px;
}

.empOVsubHead {
    color: #3B3B3B;
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
    margin-bottom: 24px;
}

.empOVDesc {
    color: #848484;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.04px;
}

@media screen and (max-width:890px) {
    .empOVlayout-two {
        flex-direction: column;
    }

    .empOVboxone {
        max-width: 800px;
        width: -webkit-fill-available;
        height: auto;
    }

    .empOverViewHeadBox {
        font-size: 48px;
    }

    .EmpOverViewLayout {
        padding: 54px;
    }
}

@media screen and (max-width:660px) {
    .EmpOverViewLayout {
        padding: 16px;
    }

    .empOverViewHeadBox {
        padding: 16px;
        font-size: 24px;
    }

    .empOVlayout-two {
        gap: 18px;
        margin-top: 18px;
        margin-bottom: 18px;
    }

    .empOVboxone {
        padding: 16px;
    }

    .empOVsubHead {
        font-size: 18px;
        margin-bottom: 12px;
    }

    .empOVDesc {
        font-size: 12px;
        line-height: 24px;
    }

    .empOVlayout-three {
        padding: 16px;
    }
}
.Visa191HeadComp_Layout{
    margin: 0 auto;
    max-width: 1280px;
    display: flex;
    gap: 24px;
    flex-direction: column;

}
.Visa191HeadComp_Title{
    padding: 32px;
    border-radius: 20px;
    font-family: Mulish;
font-size: 56px;
font-weight: 900;
line-height: 78px;
letter-spacing: 0.0025em;
text-align: left;


}
.Visa191HeadComp_Desc{ 
    padding: 32px;
    font-family: Mulish;
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0.0025em;
    text-align: left;
    border-radius: 20px;
}
.Visa191HeadComp_Desc li{
    font-family: Mulish;
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0.0025em;
    text-align: left;
    color: rgba(59, 59, 59, 1);
    
}
@media screen and (max-width:1280px) {
    .Visa191HeadComp_Title {
        padding: 24px;
        border-radius: 20px;
        font-family: Mulish;
        font-size: 32px;
        font-weight: 900;
        line-height: 39px;
        letter-spacing: 0.0025em;
        text-align: left;
        width: 80%;
        margin: 0 auto;
    }
    .Visa191HeadComp_Desc {
        padding: 24px;
        font-family: Mulish;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.0025em;
        text-align: left;
        border-radius: 20px;
        width: 80%;
        margin: 0 auto;
    }
}
@media screen and (max-width:880px) {
    .Visa191HeadComp_Title {
        padding: 24px;
        border-radius: 20px;
        font-family: Mulish;
        font-size: 32px;
        font-weight: 900;
        line-height: 39px;
        letter-spacing: 0.0025em;
        text-align: left;
        width: 80%;
        margin: 0 auto;
    }
    .Visa191HeadComp_Desc {
        padding: 24px;
        font-family: Mulish;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.0025em;
        text-align: left;
        border-radius: 20px;
        width: 80%;
        margin: 0 auto;
    }
    .Visa191HeadComp_Desc li{
        font-family: Mulish;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.0025em;
        text-align: left;
        color: rgba(59, 59, 59, 1);
        
    }
}
@media screen and (max-width:480px) {
    .Visa191HeadComp_Title {
        padding: 24px;
        border-radius: 20px;
        font-family: Mulish;
        font-size: 32px;
        font-weight: 900;
        line-height: 39px;
        letter-spacing: 0.0025em;
        text-align: left;
        width: 80%;
        margin: 0 auto;
    }
    .Visa191HeadComp_Desc {
        padding: 24px;
        font-family: Mulish;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.0025em;
        text-align: left;
        border-radius: 20px;
        width: 80%;
        margin: 0 auto;
    }
    .Visa191HeadComp_Desc li{
        font-family: Mulish;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.0025em;
        text-align: left;
        color: rgba(59, 59, 59, 1);
        
    }
}
.login2_container {
    display: flex; 
    justify-content: center;
    align-items: center;
    height: 100vh; 
    width: 100%;
    /* max-width: 1280px; */
  }
  .login2_form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .login_left {
    flex: 1; /* This will make login_left take 50% of the container width */
    background-color: #f0f0f0; /* Adjust the background color as needed */
    padding: 20px; /* Add padding as needed */
    box-sizing: border-box; /* Include padding and border in the total width and height */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
  }
  
  .login_right {
    flex: 1; /* This will make login_right take 50% of the container width */
    padding: 20px; /* Add padding as needed */
    box-sizing: border-box; /* Include padding and border in the total width and height */
    background: rgba(9, 111, 185, 1);
    height: 100vh;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 768px) {
    .login2_container {
      flex-direction: column;
    }
  
    .login_left,
    .login_right {
      flex: 1;
    }
    .textfield {
        width: 320px;
        background: rgba(255, 255, 255, 1);
        padding: 14px 19px 14px 19px;
        margin-bottom: 10px;
        height: 54px;
        box-sizing: border-box;
        border: 1px solid #ccc;
        border-radius: 5px;
        outline: none;
    }
  }
  .css-1bn53lx {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    cursor: text;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    border-radius: 4px;
    padding-right: 14px;
    outline: none;
    border: none;
    border-radius: 128px;
}
.css-igs3ac{
  border: none;
}
.Tss_Overview_container{
    border-radius: 20px;
background: #FFF7F1;
padding: 32px;
}
.Tss_Overview_container li{
    color: #3B3B3B;
    font-family: Mulish;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 33.6px */
letter-spacing: 0.06px;
}
.TssOption1_heading{
    border-radius: 20px;
background: #FFF7F1;
padding: 32px;
color: #B0383D;
font-family: Mulish;
font-size: 57px;
font-style: normal;
font-weight: 900;
line-height: 140%; /* 79.8px */
letter-spacing: 0.143px;
}
.TabsContainer_option1{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    margin-top: 32px;
    margin-bottom: 32px;
}
.Tss_Eligibility_Container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    gap: 24px;
}
.Tss_Eligibility_fields{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
}
.Tss_Eligibility_fields_container{
    border-radius: 20px;
background: #FFF7F1;
width: 556px;
height:  300px;;
flex-shrink: 0;
padding: 32px;
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 24px;
color: #848484;
font-family: Mulish;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
letter-spacing: 0.04px;
}
.Tss_Eligibility_fields_end{
    border-radius: 20px;
background: #FFF7F1;
padding: 32px;
display: flex;
flex-direction: column;
gap: 24px;
width: 95%;
color: #848484;
font-family: Mulish;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
letter-spacing: 0.04px;

}
@media screen and (max-width: 1024px) {
    .TssOption1_heading {
        border-radius: 20px;
        background: #FFF7F1;
        padding: 28px;
        color: #B0383D;
        
        font-size: 42px;
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.143px;
        width: 80%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 880px) {
    .TabsContainer_option1 {
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 12px;
        max-width: 1000px;
        margin: 0 auto;
        margin-bottom: 42px;
        margin-top: 42px;
        overflow: scroll;
      }
      .Tss_Eligibility_fields {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        flex-direction: column;
    }
    .Tss_Eligibility_fields_container {
        padding: 24px;
        border-radius: 20px;
        background: #FFF7F1;
        width: 80%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }
    .Tss_Eligibility_fields_end {
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 80%;
    }
      .Tab {
        color: rgba(0, 0, 0, 0.25);
        
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 50px; 
        letter-spacing: 0.06px;
      }
      .Tab.isActive {
        color: #B0383D;
        
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 50px; 
        letter-spacing: 0.06px;
        border-bottom: 2px solid #B0383D;
      }
      .TabsContainer_option1::-webkit-scrollbar{
        display: none;
    }.TssOption1_heading {
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        color: #B0383D;
        
        font-size: 32px;
    
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.143px;
        width: 80%;
        margin: 0 auto;
    }
    .Tss_Overview_container {
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        width: 80%;
        margin: 0 auto;
    }
    .Tss_Overview_container li {
        color: #3B3B3B;
        
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.06px;
    }
}
@media screen and (max-width: 780px) {
.TssOption1_heading {
    border-radius: 20px;
    background: #FFF7F1;
    padding: 24px;
    color: #B0383D;
    
    font-size: 32px;

    font-style: normal;
    font-weight: 900;
    line-height: 140%;
    letter-spacing: 0.143px;
    width: 80%;
    margin: 0 auto;
}.Tss_Overview_container {
    border-radius: 20px;
    background: #FFF7F1;
    padding: 24px;
    width: 80%;
    margin: 0 auto;
}
.Tss_Overview_container li {
    color: #3B3B3B;
    
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.06px;
}
.Tss_Eligibility_fields {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    flex-direction: column;
}
.Tss_Eligibility_fields_container {
    padding: 24px;
    border-radius: 20px;
    background: #FFF7F1;
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}
.Tss_Eligibility_fields_end {
    border-radius: 20px;
    background: #FFF7F1;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 80%;
}
}
@media screen and (max-width: 750px) {
    .TssOption1_heading {
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        color: #B0383D;
        
        font-size: 32px;
    
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.143px;
        width: 80%;
        margin: 0 auto;
    }
    .Tss_Overview_container {
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        width: 80%;
        margin: 0 auto;
    }
    .Tss_Overview_container li {
        color: #3B3B3B;
        
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.06px;
    }
    .Tss_Eligibility_fields {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        flex-direction: column;
    }
    .Tss_Eligibility_fields_container {
        padding: 24px;
        border-radius: 20px;
        background: #FFF7F1;
        width: 80%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }
    .Tss_Eligibility_fields_end {
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 80%;
    }
}
@media screen and (max-width: 660px) {
    .TssOption1_heading {
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        color: #B0383D;
        
        font-size: 32px;
    
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.143px;
        width: 80%;
        margin: 0 auto;
    }
    .Tss_Overview_container {
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        width: 80%;
        margin: 0 auto;
    }
    .Tss_Overview_container li {
        color: #3B3B3B;
        
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.06px;
    }
    .Tss_Eligibility_fields {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        flex-direction: column;
    }
    .Tss_Eligibility_fields_container {
        padding: 24px;
        border-radius: 20px;
        background: #FFF7F1;
        width: 80%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }
    .Tss_Eligibility_fields_end {
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 80%;
    }
}
@media screen and (max-width: 550px) {
    .TssOption1_heading {
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        color: #B0383D;
        
        font-size: 32px;
    
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.143px;
        width: 80%;
        margin: 0 auto;
    }
    .Tss_Overview_container {
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        width: 80%;
        margin: 0 auto;
    }
    .Tss_Overview_container li {
        color: #3B3B3B;
        
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.06px;
    }
    .Tss_Eligibility_fields {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        flex-direction: column;
    }
    .Tss_Eligibility_fields_container {
        padding: 24px;
        border-radius: 20px;
        background: #FFF7F1;
        width: 80%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }
    .Tss_Eligibility_fields_end {
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 80%;
    }
}
@media screen and (max-width: 480px) {
    .TssOption1_heading {
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        color: #B0383D;
        
        font-size: 32px;
    
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.143px;
        width: 80%;
        margin: 0 auto;
    }
    .Tss_Overview_container {
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        width: 80%;
        margin: 0 auto;
    }
    .Tss_Overview_container li {
        color: #3B3B3B;
        
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.06px;
    }
    .Tss_Eligibility_fields {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        flex-direction: column;
    }
    .Tss_Eligibility_fields_container {
        padding: 24px;
        border-radius: 20px;
        background: #FFF7F1;
        width: 80%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }
    .Tss_Eligibility_fields_end {
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 80%;
    }
}
.Tools_layout{
    margin: 0 auto;
    max-width: 1280px;
    padding: 52px 72px 72px 72px;
}
.Rms_layout{
    margin: 0 auto;
    max-width: 1280px;
    padding: 52px 72px 72px 72px;
}

.TabsContainer_Layout{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* flex-direction: column; */
    gap: 24px;
    font-family: Mulish;
    max-width: 1136px;
    margin: 0 auto;
    height: auto;
    border-radius: 20px;
    background: rgba(255, 247, 241, 1);
    padding: 36px 72px;
    margin-top: 32px;
}
.TabsContainer_Layout_Container{
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    flex-direction: column;
    gap: 24px;
    font-family: Mulish;
    max-width: 1136px;
    margin: 0 auto;
    height: auto;
    border-radius: 20px;
    background: rgba(255, 247, 241, 1);
    padding: 36px 72px;
    margin-top: 32px;
}
.Tools_gms_TextArea{
    font-family: Mulish;
font-size: 16px;
font-weight: 400;
line-height: 28px;
letter-spacing: 0.0025em;
text-align: left;
color: rgba(132, 132, 132, 1);
}
table {
    border-collapse: collapse;
    width: 100%;
  }
  
  th, td {
    text-align: left;
    padding: 12px; /* Adjust padding as needed */
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;
    color: rgba(59, 59, 59, 1); /* Adjust text color as needed */
    border: 0px solid #dddddd; /* Add borders for cells */
  }
  
  th {
    background-color: rgba(255, 247, 241, 1); /* Add a background color for header */
    font-size: 18px; /* Adjust header font size */
    font-weight: 500;
    font-family: Mulish;
font-size: 24px;
font-weight: 500;
line-height: 28px;
letter-spacing: 0.0025em;
color: rgba(59, 59, 59, 1);


  }
  .Table_Head_Title{
    color: rgba(59, 59, 59, 1);
    font-family: Mulish;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.0025em;
    text-align: left;
    margin-top: 24px;
    margin-bottom: 24px;
    
  }
  td{
    font-family: Mulish;
font-size: 16px;
font-weight: 400;
line-height: 20px;
width: 80%;
letter-spacing: 0.0025em;
color: rgba(132, 132, 132, 1);

  }
  
  thead::after {
    content: '';
    display: table-row;
    border-bottom: 2px solid #dddddd;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  
  
.TabsContainer_Tools{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    width: 100%;
    font-family: Mulish;
   
}
.Tabs_container_layout_Field{
    width: 100%;
}
.Tools_Title{
    color: rgba(59, 59, 59, 1);
    font-family: Mulish;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.0025em;
    text-align: left;
    
}
.Table_layout{
    margin: 0 auto;
   display: flex;
   gap: 20px;
}
.Table_column{
    margin-top: 24px;
    width: 345px;
    height: 100%;
    padding: 32px;
    border-radius: 20px;
    background: rgba(255, 247, 241, 1);
}
.Table_container{
    width:280px;
    height: 120px;
}
.table_head{
    color: rgba(59, 59, 59, 1);
    font-family: Mulish;
    font-size: 20px;
    /* padding: 24px; */
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.0025em;
    text-align: center;
    /* width: 299px; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 8px;
    border-radius: 20px;
    margin-top: 24px;
    background: rgba(255, 247, 241, 1);
    margin-top: 24px;
    width: 345px;
    height: 100%;
    padding: 32px;
    border-radius: 20px;
    background: rgba(255, 247, 241, 1);

    
}
.table_Title{
    font-family: Mulish;
font-size: 24px;
font-weight: 500;
line-height: 28px;
letter-spacing: 0.0025em;
text-align: left;
padding: 24px;
color: rgba(59, 59, 59, 1);


}
.table_desc{
    color: rgba(132, 132, 132, 1);
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;
    text-align: left;
    width: 266px;
    padding: 24px;
}
.Table_layout {
    display: flex;
    max-width: 1280px;
    overflow: scroll;
}
.Table_layout::-webkit-scrollbar{
    display: none;
}
.Table_content{
    display: flex;
    flex-direction: column;
}
@media screen and (max-width: 1280px) {
    .Rms_layout {
        margin: 0 auto;
        padding: 24px;
    }
    .table_desc {
        color: rgba(132, 132, 132, 1);
        font-family: Mulish;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.0025em;
        text-align: left;
        width: 100%;
        padding: 8px;
    }
    .Table_layout::-webkit-scrollbar{
        display: none;
    }
}

@media screen and (max-width: 1280px) {
    .Rms_layout {
        margin: 0 auto;
        padding: 32px 6px 0px 4px;
    }
    .table_desc {
        color: rgba(132, 132, 132, 1);
        font-family: Mulish;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.0025em;
        text-align: left;
        width: 100%;
        padding: 8px;
    }
    .Table_layout::-webkit-scrollbar{
        display: none;
    }
    .TabsContainer_Layout_Container {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 24px;
        font-family: Mulish;
        max-width: 1136px;
        margin: 0 auto;
        height: auto;
        border-radius: 20px;
        background: rgba(255, 247, 241, 1);
        padding: 16px;
        width: 90%;
        margin-top: 32px;
}
.TabsContainer_Tools {
    display: flex;
    justify-content:space-between;
    align-items: center;
    gap: 24px;
    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: 24px;
    margin-top: 24px;
    overflow: scroll;
  }
.Tab {
color: rgba(0, 0, 0, 0.25);
font-family: Mulish;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 50px;
letter-spacing: 0.09px;
cursor: pointer;
text-align: center;
max-width: 200px;
/* min-width: 225px; */
}
.Tab.isActive {
color: #B0383D;
font-family: Mulish;
font-size: 16px;
min-width: 200px;
font-style: normal;
font-weight: 700;
text-align: center;
line-height: 50px;
letter-spacing: 0.09px;
border-bottom: 2px solid #B0383D;
cursor: pointer;
/* max-width: 225px; */
}
}
@media screen and (max-width: 880px) {
    .TabsContainer_Layout_Container {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 24px;
        font-family: Mulish;
        max-width: 1136px;
        margin: 0 auto;
        height: auto;
        border-radius: 20px;
        background: rgba(255, 247, 241, 1);
        padding: 16px;
        width: 90%;
        margin-top: 32px;
    }
    .Table_layout{
        margin: 0 auto;
       display: flex;
       gap: 12px;
    }
    .Rms_layout {
        margin: 0 auto;
        padding: 16px;
    }
    .Table_layout::-webkit-scrollbar{
        display: none;
    }
    .Table_column {
        width: 318px;
        height: 100%;
        padding: 24px;
        padding: 16px;
        border-radius: 20px;
        background: rgba(255, 247, 241, 1);
    }
    .TabsContainer_Tools {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        max-width: 1000px;
        margin: 0 auto;
        overflow: scroll;
    }
    .TabsContainer_Tools::-webkit-scrollbar{
        display: none;
    }
    th {
        background-color: rgba(255, 247, 241, 1);
        font-size: 18px;
        font-weight: 500;
        font-family: Mulish;
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0.0025em;
        color: rgba(59, 59, 59, 1);
    }
    .table_desc {
        color: rgba(132, 132, 132, 1);
        font-family: Mulish;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.0025em;
        text-align: left;
        width: 100%;
        padding: 8px;
    }
    .Table_container {
        width: 100%;
        height: 135px;
    }
        
    .table_Title {
        font-family: Mulish;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0.0025em;
        text-align: left;
        padding: 0px;
        color: rgba(59, 59, 59, 1);
    }
    .table_head {
        color: rgba(59, 59, 59, 1);
        font-family: Mulish;
        font-size: 20px;
        /* padding: 24px; */
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0.0025em;
        text-align: center;
        /* width: 299px; */
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        padding: 8px;
        border-radius: 20px;
        margin-top: 24px;
        background: rgba(255, 247, 241, 1);
        margin-top: 24px;
        width: 300px;
        height: 100%;
        padding: 24px;
        border-radius: 20px;
        background: rgba(255, 247, 241, 1);
    }
}
@media screen and (max-width: 660px) {
    .Table_layout{
        margin: 0 auto;
       display: flex;
       gap: 12px;
    }
    .Rms_layout {
        margin: 0 auto;
        padding: 16px;
    }
    .Table_column {
        margin-top: 24px;
        width: 200px;
        height: 100%;
        padding: 16px;
        border-radius: 20px;
        background: rgba(255, 247, 241, 1);
    }
    .table_desc {
        color: rgba(132, 132, 132, 1);
        font-family: Mulish;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.0025em;
        text-align: left;
        width: 100%;
        padding: 8px;
    }
    .Table_container {
        width: 100%;
        height: 160px;
    }
        
    .table_Title {
        font-family: Mulish;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0.0025em;
        text-align: left;
        padding: 0px;
        color: rgba(59, 59, 59, 1);
    }
    .table_head {
        color: rgb(59, 59, 59);
        font-family: Mulish;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0.0025em;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 220px;
        height: 100%;
        padding: 16px;
        border-radius: 20px;
        background: rgb(255, 247, 241);
    }
}
@media screen and (max-width: 480px) {
    .Table_layout{
        margin: 0 auto;
       display: flex;
       gap: 12px;
    }
    .Rms_layout {
        margin: 0 auto;
        padding: 16px;
    }
    .Table_layout::-webkit-scrollbar{
        display: none;
    }
    .Table_column {
        margin-top: 24px;
        width: 225px;
        height: 100%;
        padding: 16px;
        border-radius: 20px;
        background: rgba(255, 247, 241, 1);
    }
    .table_desc {
        color: rgba(132, 132, 132, 1);
        font-family: Mulish;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.0025em;
        text-align: left;
        width: 100%;
        padding: 8px;
    }
    .Table_container {
        width: 115px;
        height: 366px;
    }
        
    .table_Title {
        font-family: Mulish;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0.0025em;
        text-align: left;
        padding: 0px;
        color: rgba(59, 59, 59, 1);
    }
}

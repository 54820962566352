.Family_extrayLay{
    border-radius: 20px;
background: #FFF7F1;
padding: 32px;
font-family: Mulish;
margin-top: 24px;
color: #3B3B3B;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 33.6px */
letter-spacing: 0.06px;
}

@media screen and (max-width: 880px) {
.Family_extrayLay {
    border-radius: 20px;
    background: #FFF7F1;
    padding: 24px;
    font-family: Mulish;
    margin-top: 24px;
    color: #3B3B3B;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.06px;
    width: 80%;
    margin: 0 auto;
    margin-top: 24px;
    
}
.family_comp_left_fields_extraly {
    gap: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 20px;
    background: #FFF7F1;
    padding: 24px;
    width: 80%;
    margin: 0 auto;
}
.family_comp_left_fields {
    display: flex;
    gap: 24px;
    /* flex-direction: column; */
    margin: 0 auto;
}
.family_comp_left_box {
    width: 300px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #FFF7F1;
    padding: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 24px;
    margin: 0 auto;
}
.family_layout_comp_container_right {
    width: 80%;
    height: auto;
    padding: 24px;
}
.family_layout_comp_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    margin-top: 24px;
    margin-bottom: 72px;
    flex-direction: column;
}
.family_layout_comp_container_right {
    width: 80%;
    height: auto;
    padding: 24px;
}
}
@media screen and (max-width: 480px) {
    .Family_extrayLay {
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        font-family: Mulish;
        margin-top: 24px;
        color: #3B3B3B;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.06px;
        width: 80%;
        margin: 0 auto;
        margin-top: 24px;
    }
    .family_layout_comp_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        margin-top: 24px;
        margin-bottom: 72px;
        flex-direction: column;
    }
    }
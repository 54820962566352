.Visa187Subclass_fields{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 24px;
    margin-top: 24px;
  
}
.Visa187Subclass_container1{
    width: 266px;
height: 120px;
flex-shrink: 0;
border-radius: 20px;
background: #F4F4F4;
padding: 32px;
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 24px;
/* margin-top: 24px; */
}
.Visa187Subclass_container2{
    width:  846px;
height: 120px;
flex-shrink: 0;
border-radius: 20px;
background: #F4F4F4;
padding: 32px;
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 24px;
/* margin-top: 24px; */
}
@media screen and (max-width: 880px) {
    .Visa187Subclass_fields{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 24px;
        flex-direction: column;
      
    }
    .Visa187Subclass_container2 {
        width: 80%;
        height: auto;
        flex-shrink: 0;
        border-radius: 20px;
        background: #F4F4F4;
        padding: 32px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }
    .Visa187Subclass_container1 {
        width: 80%;
        height: auto;
        flex-shrink: 0;
        border-radius: 20px;
        background: #F4F4F4;
        padding: 32px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        margin-top: 24px;
    }
}
@media screen and (max-width: 480px) {
    .Visa187Subclass_fields{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 24px;
        flex-direction: column;
      
    }
    .Visa187Subclass_container2 {
        width: 80%;
        height: auto;
        flex-shrink: 0;
        border-radius: 20px;
        background: #F4F4F4;
        padding: 32px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }
    .Visa187Subclass_container1 {
        width: 80%;
        height: auto;
        flex-shrink: 0;
        border-radius: 20px;
        background: #F4F4F4;
        padding: 32px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        margin-top: 24px;
    }
}
.clients-layout {
    margin: 0 auto;
    max-width: 1280px;
    padding: 36px 72px;
}

.client-layout-heading {
    border-radius: 20px;
    background: #FFF7F1;
    padding: 32px;
    margin-bottom: 24px;
}

.client-heading-main {
    color: #B0383D;
    font-family: Arial;
    font-size: 56px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%;
    letter-spacing: 0.14px;
}

.client-layout-one {
    max-width: 299px;
    max-height: 800px;
    border-radius: 20px;
    background: #FFF7F1;
    padding: 32px;
    display: flex;
    flex-direction: column;
}

.client-heading {
    color: #303030;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 28px;
    letter-spacing: 0.06px;
    width: 30px;
}

.client-desc {
    color: #848484;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04px;
    padding-bottom: 32px;
    border-bottom: 1px solid #D9C0AA;
}

.client-heading1 {
    color: #303030;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 28px;
    letter-spacing: 0.06px;
    width: 30px;
}

.client-desc1 {
    color: #848484;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04px;
}

.clientName {
    display: flex;
    align-items: center;
    gap: 25px;
    margin-bottom: 8px;
}

.client-layout-two {
    display: flex;
    gap: 24px;
}

.client-layout-raj {
    margin-top: 32px;
}

.client-layout-rahul {
    height: fit-content;
    border-radius: 20px;
    background: #FFF7F1;
    padding: 32px;
}

.client-layout-kriti {
    height: auto;
    border-radius: 20px;
    background: #FFF7F1;
    padding: 32px;
}

.client-desc2 {
    color: #7D7D7D;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.client-main-layout {
    display: flex;
    gap: 24px;
}

.client-layout-three {
    height: fit-content;
    border-radius: 20px;
    background: #FFF7F1;
    padding: 32px;
    display: flex;
    justify-content: space-between;
}

.client-layout-two-main {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: space-between;
}

.client-layout-santosh {
    padding-right: 32px;
    border-right: 1px solid #707070;
}

.client-layout-preeti {
    padding-left: 32px;
}

.clientScroll {
    display: none;
}

@media screen and (max-width:1050px) {
    .clients-layout {
        padding: 24px 32px;
    }
}

@media screen and (max-width: 960px) {
    .client-main-layout {
        flex-direction: column-reverse;
    }

    .client-layout-one {
        flex-direction: row;
        justify-content: space-between;
        max-width: fit-content;
    }

    .client-desc {
        padding-bottom: 0px;
        padding-right: 32px;
        border-bottom: none;
    }

    .client-layout-raj {
        margin: 0px;
        padding-left: 32px;
        border-left: 1px solid #D9C0AA;
    }
}

@media screen and (max-width: 670px) {
    .client-main-layout {
        display: none;
    }
    
    .client-heading-main{
        font-size: 24px;
    }

    .client-layout-heading{
        padding: 16px;
    }

    .client-layout-rahul{
        padding: 16px;
    }

    .client-heading{
        font-size: 20px;
    }

    .clientScroll {
        display: flex;
        gap: 24px;
        max-width: 600px;
        overflow: scroll;
    }

    .client-layout-rahul {
        height: auto;
    }

    .clients-layout {
        padding: 24px 16px;
    }

    .clientScroll::-webkit-scrollbar {
        display: none;
    }

    .clientName {
        width: 370px;
    }
}

@media screen and (max-width:470px) {
    .clientName {
        width: 280px;
    }
}

@media screen and (max-width:360px) {
    .clientName {
        width: 210px;
    }
}
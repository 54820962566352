.Visa489_Single_Box{
    background: rgba(255, 247, 241, 1);
    padding: 32px;
    border-radius: 20px;
    margin-top: 24px;
    font-family: Mulish;
font-size: 16px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0.0025em;
text-align: left;
}
.Visa489_Single_Box_layout{
    padding-bottom: 72px;
}
.Visa489_Single_Box li{
    color: rgba(59, 59, 59, 1);
    font-family: Mulish;
font-size: 16px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0.0025em;
text-align: left;
}
.Visa489_Content_box{
    background: rgba(255, 247, 241, 1);
    padding: 32px;
    border-radius: 20px;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.Visa489_Content_box_title{
    color: rgba(59, 59, 59, 1);
    width: 216px;
    font-family: Mulish;
font-size: 24px;
font-weight: 500;
line-height: 28px;
letter-spacing: 0.0025em;
text-align: left;

}
.Visa489_Content_box_desc{
    font-family: Mulish;
font-size: 16px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0.0025em;
text-align: left;
color: rgba(132, 132, 132, 1);

}
.Visa489_Double_Box_field{
    display: flex;
    margin: 0 auto;
    gap: 24px;
    margin-top: 24px;
}
.Visa489_Double_Box{
    width: 560px;
height: 120px;
border-radius: 20px;
padding: 32px;
border-radius: 20px;
background: rgba(255, 247, 241, 1);
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 24px;

}
@media screen and (max-width: 1280px) {
    .Visa489_Single_Box {
        background: rgba(255, 247, 241, 1);
        padding: 24px;
        border-radius: 20px;
        margin-top: 24px;
        font-family: Mulish;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.0025em;
        text-align: left;
        width: 80%;
        margin: 0 auto;
        margin-top: 24px;
    }
    .Visa489_Double_Box_field{
     flex-direction: column;
     margin: 0 auto;
     margin-top: 24px;
    }
    .Visa489_Double_Box {
        width: 80%;
        height: auto;
        border-radius: 20px;
        padding: 24px;
        border-radius: 20px;
        background: rgba(255, 247, 241, 1);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        margin: 0 auto;
    }
    .Visa489_Content_box {
        background: rgba(255, 247, 241, 1);
        padding: 24px;
        border-radius: 20px;
        margin: 0 auto;
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 80%;
    }
    .Visa489_Content_box_title{
        font-size: 22px;
    }
}
@media screen and (max-width: 880px) {
    .Visa489_Single_Box {
        background: rgba(255, 247, 241, 1);
        padding: 24px;
        border-radius: 20px;
        margin-top: 24px;
        font-family: Mulish;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.0025em;
        text-align: left;
        width: 80%;
        margin: 0 auto;
        margin-top: 24px;
    }
    .Visa489_Double_Box_field{
     flex-direction: column;
     margin: 0 auto;
     margin-top: 24px;
    }
    .Visa489_Double_Box {
        width: 80%;
        height: auto;
        border-radius: 20px;
        padding: 24px;
        border-radius: 20px;
        background: rgba(255, 247, 241, 1);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        margin: 0 auto;
    }
    .Visa489_Content_box {
        background: rgba(255, 247, 241, 1);
        padding: 24px;
        border-radius: 20px;
        margin: 0 auto;
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 80%;
    }
    .Visa489_Content_box_title{
        font-size: 22px;
    }
}
@media screen and (max-width: 480px) {
    .Visa489_Single_Box {
        background: rgba(255, 247, 241, 1);
        padding: 24px;
        border-radius: 20px;
        margin-top: 24px;
        font-family: Mulish;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.0025em;
        text-align: left;
        width: 80%;
        margin: 0 auto;
        margin-top: 24px;
    }
    .Visa489_Double_Box_field{
     flex-direction: column;
     margin: 0 auto;
     margin-top: 24px;
    }
    .Visa489_Double_Box {
        width: 80%;
        height: auto;
        border-radius: 20px;
        padding: 24px;
        border-radius: 20px;
        background: rgba(255, 247, 241, 1);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        margin: 0 auto;
    }
    .Visa489_Content_box {
        background: rgba(255, 247, 241, 1);
        padding: 24px;
        border-radius: 20px;
        margin: 0 auto;
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 80%;
    }
    .Visa489_Content_box_title{
        font-size: 22px;
    }
}
/* Base styles for ConsultationComponent */
.ConsultationComponent_component {
    width: 363px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    font-family: Mulish;
    align-items: flex-start;
    padding: 24px;
    border-radius: 15px;
    background: #F4F4F4;
  }
  .ConsultationComponent_component_Tools{
    width: 363px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
    font-family: Mulish;
    align-items: flex-start;
    padding: 24px;
    height: 180px;
    border-radius: 15px;
    background: #F4F4F4;
  }
  
  .ConsultationComponent_component_title {
    color: #3B3B3B;
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
  }
  
  .ConsultationComponent_component_description {
    width: 85%; /* Adjusted width for responsiveness */
    flex-shrink: 0;
    color: #848484;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04px;
  }
  
  .ConsultationComponent_component_button {
    display: inline-flex;
    padding: 10px 28px 10px 36px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 128px;
    background: #B0383D;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    cursor: pointer;
    letter-spacing: 0.04px;
    text-transform: uppercase;
  }
  
  /* Responsive styles using media queries */
  @media screen and (max-width: 1024px) {
    .ConsultationComponent_component {
        width: 250px;
        padding: 24px;
    }
  }
  
  @media screen and (max-width: 960px) {
    .ConsultationComponent_component {
        width: 280px;
        padding: 24px;
    }
  }
  
  @media screen and (max-width: 660px) {
    .ConsultationComponent_component {
        width: 70%;
    padding: 24px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .ConsultationComponent_component {
        width: 70%;
    padding: 24px;
    }
    .ConsultationComponent_component_Tools {
      width: 240px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      gap: 24px;
      font-family: Mulish;
      align-items: flex-start;
      padding: 24px;
      height: auto;
      border-radius: 15px;
      background: #F4F4F4;
  }
    .ConsultationComponent_component_title {
      color: #3B3B3B;
      font-family: Mulish;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0.06px;
  }
  .ConsultationComponent_component_button {
    display: inline-flex;
    padding: 6px 20px 6px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 128px;
    background: #B0383D;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    cursor: pointer;
    letter-spacing: 0.04px;
    text-transform: uppercase;
}
  }
  
.SkilledComp1_Layout{
    margin: 0 auto;
    max-width: 1280px;
    /* padding: 36px 72px; */
    display: flex;
    flex-direction: column;
    /* margin-top: 72px; */
    margin-bottom: 72px;
    gap: 24px;
}
.SkilledComp1_Title{
    /* background: rgba(255, 247, 241, 1); */
    font-family: Mulish;
    font-size: 56px;
    font-weight: 900;
    line-height: 78px;
    letter-spacing: 0.0025em;
    text-align: left;
    /* color: rgba(176, 56, 61, 1); */
    border-radius: 20px;
    padding: 32px;
}
.SkilledComp1_Content1{
    /* background: rgba(255, 247, 241, 1); */
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    border-radius: 20px;
    letter-spacing: 0.0025em;
    text-align: left;
    color: rgba(59, 59, 59, 1);
    padding: 32px;
    
}
.SkilledComp1_Content2{
    /* background: rgba(255, 247, 241, 1); */
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 20px;
    letter-spacing: 0.0025em;
    text-align: left;
    color: rgba(59, 59, 59, 1);
    padding: 32px;
    
}
.skilledComp1_Desc{
    font-family: Mulish;
font-size: 16px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.0025em;
text-align: left;
color: rgba(132, 132, 132, 1);

}
.skilledComp1_Title{
    font-family: Mulish;
font-size: 24px;
font-weight: 500;
line-height: 28px;
letter-spacing: 0.0025em;
text-align: left;
color: rgba(59, 59, 59, 1);

}

@media screen and (max-width: 880px) {
.SkilledComp1_Layout {
    margin: 0 auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.SkilledComp1_Title {
    /* background: rgba(255, 247, 241, 1); */
    font-family: Mulish;
    font-size: 26px;
    font-weight: 900;
    line-height: 48px;
    letter-spacing: 0.0025em;
    text-align: left;
    color: rgba(176, 56, 61, 1);
    border-radius: 20px;
    padding: 24px;
}
.SkilledComp1_Content1 {
    font-size: 16px;
    padding: 24px;
}
.SkilledComp1_Content2{
    padding: 24px;
}
.skilledComp1_Title {
    font-family: Mulish;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.0025em;
    text-align: left;
    color: rgba(59, 59, 59, 1);
}
.skilledComp1_Desc {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;
    text-align: left;
    color: rgba(132, 132, 132, 1);
}
}
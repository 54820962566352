.SkilledComp4_layout{
    margin: 0 auto;
    max-width: 1280px;
    /* padding: 36px 72px; */
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.skilledComp4_Box_Head{
    background: rgba(244, 244, 244, 1);
    padding: 32px;
    border-radius: 20px;
    font-family: Mulish;
font-size: 56px;
font-weight: 900;
line-height: 78px;
letter-spacing: 0.0025em;
text-align: left;
color: rgba(1, 74, 145, 1);

}
.skilledComp4_Box_Fields{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
}
.skilledComp4_Box{
    background: rgba(244, 244, 244, 1);
    padding: 32px;
    display: flex;
    border-radius: 20px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 24px;
    width: 50%;

}
.skilledComp4_Box_title{
    font-family: Mulish;
font-size: 24px;
font-weight: 500;
line-height: 28px;
letter-spacing: 0.0025em;
text-align: left;
color: rgba(59, 59, 59, 1);
max-width: 351.72px;
}
.skilledComp4_Box_Desc{
    font-family: Mulish;
font-size: 16px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.0025em;
text-align: left;
color: rgba(132, 132, 132, 1);
max-width: 458px;
}
.skilledComp4_Box_btn{
    background: rgba(176, 56, 61, 1);
    padding: 10px 36px 10px 36px;
    color: white;
    font-family: Mulish;
font-size: 16px;
border-radius: 20px;
font-weight: 700;
line-height: 20px;
letter-spacing: 0.0025em;
text-align: left;
cursor: pointer;

}
@media screen and (max-width: 880px) {
    .SkilledComp4_layout{
        margin: 0 auto;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    .skilledComp4_Box{
        background: rgba(244, 244, 244, 1);
        padding: 24px;
        display: flex;
        border-radius: 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        width: 320px;
        height: auto;
    
    }
    .skilledComp4_Box_Head {
        background: rgba(244, 244, 244, 1);
        padding: 24px;
        border-radius: 20px;
        font-family: Mulish;
        font-size: 28px;
        font-weight: 900;
        line-height: 48px;
        letter-spacing: 0.0025em;
        text-align: left;
        color: rgba(1, 74, 145, 1);
    }
    .skilledComp4_Box_Fields {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        max-width: 900px;
        overflow: scroll;
    }
    .skilledComp4_Box_Fields::-webkit-scrollbar{
    display: none;
    }
    .skilledComp4_Box_title {
        font-family: Mulish;
        font-size: 22px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.0025em;
        text-align: left;
        color: rgba(59, 59, 59, 1);
        min-width: 280px;
    }
    .skilledComp4_Box_Desc {
        font-family: Mulish;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.0025em;
        text-align: left;
        color: rgba(132, 132, 132, 1);
    }
    .skilledComp4_Box_btn {
        background: rgba(176, 56, 61, 1);
        padding: 10px 26px 10px 26px;
        color: white;
        font-family: Mulish;
        font-size: 16px;
        border-radius: 20px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.0025em;
        text-align: left;
    }
    }
    @media screen and (max-width: 480px) {
        .SkilledComp4_layout{
            margin: 0 auto;
            padding: 16px;
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
        .skilledComp4_Box{
            background: rgba(244, 244, 244, 1);
            padding: 24px;
            display: flex;
            border-radius: 20px;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            width: 320px;
            height: auto;
        
        }
        .skilledComp4_Box_Head {
            background: rgba(244, 244, 244, 1);
            padding: 24px;
            border-radius: 20px;
            font-family: Mulish;
            font-size: 28px;
            font-weight: 900;
            line-height: 48px;
            letter-spacing: 0.0025em;
            text-align: left;
            color: rgba(1, 74, 145, 1);
        }
        .skilledComp4_Box_Fields {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 24px;
            max-width: 600px;
            overflow: scroll;
        }
        .skilledComp4_Box_Fields::-webkit-scrollbar{
        display: none;
        }
        .skilledComp4_Box_title {
            font-family: Mulish;
            font-size: 22px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0.0025em;
            text-align: left;
            color: rgba(59, 59, 59, 1);
            min-width: 200px;
        }
        .skilledComp4_Box_Desc {
            font-family: Mulish;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.0025em;
            text-align: left;
            color: rgba(132, 132, 132, 1);
            min-width: 290px;
        }
        .skilledComp4_Box_btn {
            background: rgba(176, 56, 61, 1);
            padding: 10px 26px 10px 26px;
            color: white;
            font-family: Mulish;
            font-size: 16px;
            border-radius: 20px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0.0025em;
            text-align: left;
        }
        }
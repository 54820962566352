.Family_Layout{
    margin: 0 auto;
    max-width: 1280px;
    padding: 0px 0 0px 0;
    margin-top: 24px;
}
.DividerVertical{
    height: 100%; /* Adjust the height as needed */
    width: 1px;   /* Adjust the width as needed */
    background-color: #d9c0aa00; /* Adjust the color as needed */
    margin: 0 10px;
}
.Family_Layout_sub_container{
    display: flex;
    gap: 24px;
    align-items: center;
    margin: 0 auto;
    padding: 32px 0px 0px 0px;
    max-width: 1280px;
}
.Family_Layout_Divider_box{
    padding: 32px;
    width: 363px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 250px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #FFF7F1;
}
.Family_Layout_sub_container2{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.Family_Layout_sub_container2_box_fields{
    display: flex;
    gap: 24px;
}
.Divider_container_family{
    display: flex;
}
.Family_Layout_sub_container3{
    display: flex;
   gap: 24px;
   flex-shrink: 0;
border-radius: 20px;
background: #FFF7F1;
padding: 32px;
height: 80px;
 
}
.Family_Layout_sub_container3_Box{
    display: flex;
    flex-direction: column;
    /* width: 749px; */
/* height: 156px; */
/* flex-shrink: 0;
border-radius: 20px;
background: #FFF7F1; */
/* padding: 32px; */
}

.Family_Layout_sub_container2_box{
    width: 340px;
/* height: 176px; */
flex-shrink: 0;
border-radius: 20px;
background: #FFF7F1;
padding: 32px;
}

@media screen and (max-width: 890px) {
   .Family_Layout {
    padding: 0px 16px 16px 16px;
    }
    .Family_Layout_sub_container {
        display: flex;
        gap: 24px;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        padding: 32px 0px 32px 0px;
        max-width: 1280px;
    }
    .Family_Layout_sub_container2_box_fields {
        display: flex;
        gap: 24px;
        /* flex-direction: column; */
    }
    .Family_Layout_sub_container3 {
        display: flex;
        gap: 24px;
        flex-shrink: 0;
        flex-direction: column;
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        height: auto;
    }
    .Family_Layout_sub_container2_box {
        width: 40%;
        height: auto;
        flex-shrink: 0;
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        margin: 0 auto;
    }
    .Family_Layout_Divider_box {
        padding: 24px;
        width: 90%;
        height: auto;
        flex-shrink: 0;
        border-radius: 20px;
        background: #FFF7F1;
    }
}
@media screen and (max-width: 660px) {
   .Family_Layout {
        padding: 0px 16px 16px 16px;
    }
    .Family_Layout_sub_container {
        display: flex;
        gap: 24px;
        flex-direction: column;
        align-items: flex-start;
        margin: 0 auto;
        /* padding: 16px; */
    }
    .Family_Layout_sub_container2_box_fields {
        display: flex;
        gap: 24px;
        flex-direction: column;
    }
    .Family_Layout_sub_container3 {
        display: flex;
        gap: 24px;
        flex-shrink: 0;
        flex-direction: column;
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        height: auto;
        width: 85%;
    }
    .Family_Layout_sub_container2_box {
        width: 85%;
        height: auto;
        flex-shrink: 0;
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
    }
    .Family_Layout_Divider_box {
        padding: 24px;
        width: 85%;
        height: auto;
        flex-shrink: 0;
        border-radius: 20px;
        background: #FFF7F1;
    }
}
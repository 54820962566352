.English_comp_layout{
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 72px;
    max-width: 1280px;

}
.English_Comp_title{
background: rgba(244, 244, 244, 1);
padding: 32px;
border-radius: 20px;
color: rgba(1, 74, 145, 1);
font-family: Mulish;
font-size: 56px;
font-weight: 900;
line-height: 78px;
letter-spacing: 0.0025em;
text-align: left;

}
.English_Comp_desc{
    background: rgba(244, 244, 244, 1);
    padding: 32px;
    border-radius: 20px;
    color: rgba(59, 59, 59, 1);
    font-family: Mulish;
font-size: 24px;
font-weight: 500;
line-height: 29px;
letter-spacing: 0.0025em;
text-align: left;

}
@media screen and (max-width: 880px) {
    .English_Comp_desc{
        padding: 24px;
    }
    .English_Comp_title{
        padding: 24px;
        font-size: 28px;
    }
    .English_comp_layout {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 72px;
        max-width: 1280px;
        width: 90%;
    }

}
@media screen and (max-width: 480px) {
 .English_Comp_desc{
    padding: 24px;
    font-size: 18px;

    }
    .English_Comp_title{
        padding: 16px;
    font-size: 28px;
    line-height: 38px;
    }
    .English_comp_layout {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 72px;
        max-width: 1280px;
        width: 90%;
    }
}
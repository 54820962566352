.InternationalWhy_head{
    background: rgba(255, 247, 241, 1);
    padding: 32px;
    font-family: Mulish;
font-size: 56px;
font-weight: 900;
line-height: 78px;
letter-spacing: 0.0025em;
text-align: left;
border-radius: 20px;
margin-bottom: 24px;
color: rgba(176, 56, 61, 1);
}
.InternationalWhy_layout{
    margin: 0 auto;
    max-width: 1280px;
    padding-left: 72px;
    padding-top: 72px;
    padding-bottom: 36px;
    padding-right: 72px;
}
.InternationalWhy_desc{
    font-family: Mulish;
font-size: 24px;
font-weight: 500;
line-height: 34px;
letter-spacing: 0.0025em;
text-align: left;
background: rgba(255, 247, 241, 1);
padding: 32px;
border-radius: 20px;
}
.InternationalWhy_container1{
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 24px;
    font-family: Mulish;
}
.InternationalWhy_container1_fields{
    width: 345px;
height: 230px;
flex-shrink: 0;
border-radius: 20px;
background: #FFF7F1;
padding: 32px;
display: flex;
flex-direction: column;
gap: 24px;
align-items: flex-start;
margin-top: 24px;
margin-bottom: 24px;
}
.InternationalWhy_container1_title{
    color: #3B3B3B;
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 116.667% */
    letter-spacing: 0.06px;
}
.InternationalWhy_container1_desc{
    color: #848484;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: 0.04px;
    max-width: 299px;
}
@media screen and (max-width: 1024px) {
    .InternationalWhy_container1{
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1000px;
        overflow: scroll;
        gap: 24px;
    }
    .InternationalWhy_layout {
        margin: 0 auto;
        max-width: 1280px;
        padding: 16px;
    }
    .InternationalWhy_container1::-webkit-scrollbar {
        display: none;
    }
    
}
@media screen and (max-width: 890px) {
    .InternationalWhy_container1{
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1000px;
        overflow: scroll;
        gap: 24px;
    }
    .InternationalWhy_layout {
        margin: 0 auto;
        max-width: 1280px;
        padding: 16px;
    }
    .InternationalWhy_container1::-webkit-scrollbar {
        display: none;
    }
    
}
@media screen and (max-width: 660px) {
    .InternationalWhy_container1{
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1000px;
        overflow: scroll;
        gap: 24px;
    }
    .InternationalWhy_layout {
        margin: 0 auto;
        max-width: 1280px;
        padding: 16px;
    }
    .InternationalWhy_container1::-webkit-scrollbar {
        display: none;
    }
}
@media screen and (max-width: 480px) {
    .InternationalWhy_head {
        background: rgba(255, 247, 241, 1);
        padding: 16px;
        font-family: Mulish;
        font-size: 28px;
        font-weight: 900;
        line-height: 78px;
        letter-spacing: 0.0025em;
        text-align: left;
        border-radius: 20px;
        margin-bottom: 24px;
        color: rgba(176, 56, 61, 1);
    }
    .InternationalWhy_desc {
        font-family: Mulish;
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.0025em;
        text-align: left;
        background: rgba(255, 247, 241, 1);
        padding: 24px;
        border-radius: 20px;
    }
    .InternationalWhy_container1{
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1000px;
        overflow: scroll;
        gap: 24px;
        
    }
    .InternationalWhy_container1_fields {
        width: 230px;
        height: 275px;
        flex-shrink: 0;
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .InternationalWhy_layout {
        margin: 0 auto;
        max-width: 1280px;
        padding: 16px;
    }
    .InternationalWhy_container1::-webkit-scrollbar {
        display: none;
    }
    
}
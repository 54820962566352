.navbar {
    max-width: 1380px;
    margin: 0 auto;
    background: #FFF7F1;
    padding: 16px 72px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
}
.close-icon{
    cursor: pointer;
    padding: 16px;
}
.side-panel {
    position: fixed;
    top: 0;
    left: 0; 
    width: 100vw;
    height: 100vh;
    background-color: #FFF7F1; 
    transition: left 0.3s ease; 
    z-index: 50; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .nav-buttons_mobile{
    padding: 16px;
  }
  
  .side-panel.open {
    left: 0; 
  }

  
.menu_layout_Immigration{
    background-color: white;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 8px;
    position: absolute;
    top: 72px;
    box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.25);
    left: 60%;
    z-index: 1;
}
.menu_items{
    color: black;
font-family: Mulish;
font-size: 14px;
font-weight: 400;
line-height: 18px;
letter-spacing: -0.01em;
text-align: left;

}
.menu_layout{
    background-color: white;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 8px;
    position: absolute;
    top: 72px;
    box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.25);
    left: 1040px;
    z-index: 1;
    background-color: white;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 8px;
    position: absolute;
    top: 72px;
    box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.25);
    left: 85%;
    z-index: 1;
}
.nav-buttons {
    color: #023255;
    font-family: Arial;
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

.list-icon {
    display: none;
}

.nav-content {
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: flex-end;
    width: 80%;
}

.navcontentButtons {
    display: none;
}

.navbar-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 618px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 72px;
    background: rgb(78 78 78 / 4%);
    padding: 6px 30px;
    align-items: center;
}

.search-field {
    padding: 6px 16px;
    display: flex;
    align-items: center;
    width: 176px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 72px;
    border: none;
    background: none;
    color: #023255;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    display: none;
}

.nav {
    display: none;
}

.search-bar {
    position: relative;
    display: flex;
    align-items: center;
}

.search-field:focus {
    outline: none;
    border-color: transparent;
}

.search-field::placeholder {
    color: #023255;
}

.search-bar {
    position: relative;
    display: inline-flex;
}

.search-icon {
    position: absolute;
    right: 10px;
    padding: 12px;
    color: #023255;
    top: 50%;
    transform: translateY(-50%);
}

.search-field {
    padding-right: 60px;
}

.expand-icon {
    color: #023255;
    padding-left: 4px;
}

@media screen and (max-width: 2100px) {
    .menu_layout{
        background-color: white;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        border-radius: 8px;
        position: absolute;
        top: 72px;
        box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.25);
        left: 75%;
        z-index: 1;
    }
    }
@media screen and (max-width: 2000px) {
.menu_layout{
    background-color: white;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 8px;
    position: absolute;
    top: 72px;
    box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.25);
    left: 76%;
    z-index: 1;
}
}
@media screen and (max-width: 1900px) {
    .menu_layout{
        background-color: white;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        border-radius: 8px;
        position: absolute;
        top: 72px;
        box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.25);
        left: 80%;
        z-index: 1;
    }
    }
@media screen and (max-width: 1800px) {
    .menu_layout{
        background-color: white;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        border-radius: 8px;
        position: absolute;
        top: 72px;
        box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.25);
        left: 79%;
        z-index: 1;
    }
    }
    @media screen and (max-width: 1800px) {
        .menu_layout{
            background-color: white;
            padding: 16px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            border-radius: 8px;
            position: absolute;
            top: 72px;
            box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.25);
            left: 84%;
            z-index: 1;
        }
        }
        

@media screen and (max-width: 1200px) {
    .navbar-content {
        display: none;
    }

    .visible {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: none;
        max-width: 800px;
        width: 100%;
        height: auto;
        gap: 20px;
        background: rgba(78, 78, 78, 0.19);
        border-radius: 72px;
        padding: 12px 54px;
    }

    .navbar {
        padding: 12px 54px;
        gap: 8px;
        align-items: start;
    }

    .list-icon {
        display: inline-block;
        padding-right: 16px;
    }

    .HopeLogo {
    height: 32px;
    width: 83px !important;
    }

    .logo {
        display: flex;
        padding-top: 10px;
    }

    .search-field {
        width: 600px;
    }

    .nav-content {
        flex-direction: column-reverse;
        align-items: center;
    }
    .menu_layout_Immigration {
        left: 30%; /* Adjust as needed for smaller screens */
    }
    .menu_layout{
        left: 30%;
    }
}

/* @media screen and (max-width: 1300px) {
.menu_layout_Immigration {
    background-color: white;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 8px;
    position: absolute;
    top: 23%;
    left: 50%;
}
.menu_layout {
    background-color: white;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 8px;
    position: absolute;
    top: 23%;
    box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.25);
    left: 83%;
    z-index: 1;
}
} */
 @media screen and (max-width: 1280px) {
.menu_layout_Immigration {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 1px 0 4px 0 #00000040;
    display: flex;
    flex-direction: column;
    gap: 16px;
    left: 55%;
    padding: 16px;
    position: absolute;
    top: 72px;
    z-index: 1;
}
.menu_layout {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 1px 0 4px 0 #00000040;
    display: flex;
    flex-direction: column;
    gap: 16px;
    left: 1040px;
    left: 80%;
    padding: 16px;
    position: absolute;
    top: 72px;
    z-index: 1;
}
 }
@media screen and (max-width: 950px) {
    .search-field {
        width: 420px;
    }

    .visible {
        display: none;
    }

    .navcontentButtons {
        display: flex;
        justify-content: space-between;
        padding: 12px 32px;
        background: #FFF7F1;
        /* border-radius: 72px; */
        /* margin-top: 12px; */
    }

    .navLayout {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 690px) {
    .search-field {
        width: 240px;
        display: none;
    }
}
@media screen and (max-width: 880px){
    .menu_layout {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 1px 0 4px 0 #00000040;
        display: flex;
        flex-direction: column;
        gap: 16px;
        left: 0;
        padding: 16px;
        position: relative;
        top: 0;
        z-index: 1;
    }
    .HopeLogo {
        height: 32px;
        width: 83px !important;
        }
    
    .menu_layout_Immigration {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 1px 0 4px 0 #00000040;
        display: flex;
        flex-direction: column;
        gap: 16px;
        left: -24px;
        padding: 16px;
        position: relative;
        z-index: 1;
        top: 32px;
    }
    }
@media screen and (max-width: 480px){
    .navbar{
        position:sticky;
         top: 0 ;
         z-index:1000;
        }

.menu_layout {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 1px 0 4px 0 #00000040;
    display: flex;
    flex-direction: column;
    gap: 16px;
    left: 0;
    padding: 16px;
    position: relative;
    top: 0;
    z-index: 1;
}
.HopeLogo {
    height: 32px;
    width: 83px !important;
    }

.menu_layout_Immigration {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 1px 0 4px 0 #00000040;
    display: flex;
    flex-direction: column;
    gap: 16px;
    left: -24px;
    padding: 16px;
    position: relative;
    z-index: 1;
    top: 32px;
}
}

@media screen and (max-width: 580px) {
    .navbar {
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
    }

    .nav-content {
        align-items: flex-start;
        width: 0px;
    }

    .search-icon {
        padding: 22px;
    }

    .search-field {
        width: 380px;
        display: none;
    }

    .navcontentButtons {
        gap: 24px;
        display: flex;
        flex-direction: column;
        height: 400000000000vh;
        justify-content: flex-start;
        
    }

    .navbar {
        padding: 12px 16px;
    }
}

@media screen and (max-width: 470px) {
    .search-field {
        width: 300px;
        display: none;
    }
}

@media screen and (max-width: 380px) {
    .search-field {
        width: 260px;
        display: none;
    }
}

@media screen and (max-width: 300px) {
    .search-field {
        width: 175px;
        display: none;
    }
}






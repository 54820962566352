.Visa491_Eligibility_Box_field{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.AboutUsVisa_box_fields_content_Skilled{
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 363px;
    background: rgba(244, 244, 244, 1);
height: 160px;
border-radius: 20px

}

.AboutUsVisa_box_fields_content_Skilled {
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 363px;
    background: rgba(244, 244, 244, 1);
    height: 160px;
    border-radius: 20px;
}

@media screen and (max-width:880px) {
    .AboutUsVisa_box_fields_content_Skilled {
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 80%;
        background: rgba(244, 244, 244, 1);
        height: auto;
        border-radius: 20px;
    }
    .AboutUsVisa_box_fields_content_Skilled {
        padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 80%;
    margin: 0 auto;
    background: rgba(244, 244, 244, 1);
    height: auto;
    border-radius: 20px;
    }
}
@media screen and (max-width:480px) {

.AboutUsVisa_box_fields_content_Skilled {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 80%;
    background: rgba(244, 244, 244, 1);
    height: auto;
    border-radius: 20px;
}
.AboutUsVisa_box_fields_content_Skilled {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 80%;
    background: rgba(244, 244, 244, 1);
    height: auto;
    border-radius: 20px;
}
}
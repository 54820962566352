.Family_header_title{
    color: #014A91;
    font-size: 56px;
    font-style: normal;
    font-family: Mulish;
    font-weight: 900;
    line-height: 140%; 
    letter-spacing: 0.14px;
}
.Family_header_Box{
    border-radius: 20px;
background: #F4F4F4;
padding: 32px;
font-family: Mulish;
}
.Family_header_Image{
    display: flex;
    gap: 24px;
    padding: 24px 0px 72px 0px;
    max-width: 1280px;
}
.Family_header_img{
    Width: 556px;
    height: auto;
}
.Family_header_description{
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    gap: 48px;
    align-items: flex-start;
    border-radius: 20px;
    background: #F4F4F4;
    padding: 32px;
    border-radius: 20px;
    background: #F4F4F4;
    color: #3B3B3B;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    font-family: Mulish;
    line-height: 28px;
    letter-spacing: 0.06px;
    /* width: 556px; */
    height: auto;
}
.family_header_desc_btn{
    border-radius: 128px;
background: #B0383D;
display: flex;
width: 191px;
color: white;
border: none;
font-size: 16px;
text-transform: uppercase;
padding: 10px 36px;
justify-content: center;
align-items: center;
gap: 8px;
cursor: pointer;
}
@media screen and (max-width: 890px) {
    .Family_header_Box{
        border-radius: 20px;
        background: #F4F4F4;
        font-family: Mulish;
        padding: 24px;
        margin: 0 auto;
        width: 90%;
    } 
    .Family_header_Image {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 16px;
        margin: 0 auto;
    }
    .Family_header_img{
        width: 100%;
    }
    .Family_header_title {
        color: #014A91;
        font-family: Mulish;
        font-size: 32px;
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.14px;
    }
}
@media screen and (max-width: 660px) {

    .Family_header_Box {
        font-family: Mulish;
        padding: 16px;
        margin: 0 auto;
        width: 85%;
    }
    .Family_header_Image {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 16px;
      margin: 0 auto;
    }
    .Family_header_img{
        width: 100%;
    }
    .Family_header_description {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;
        border-radius: 20px;
        background: #F4F4F4;
        padding: 24px;
        border-radius: 20px;
        background: #F4F4F4;
        color: #3B3B3B;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        font-family: Mulish;
        line-height: 28px;
        letter-spacing: 0.06px;
        /* width: 556px; */
        height: auto;
    }
    .Family_header_title {
        color: #014A91;
        font-family: Mulish;
        font-size: 28px;
        font-style: normal;
        font-weight: 900;
        line-height: 130%;
        letter-spacing: 0.14px;
    }
}
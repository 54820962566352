.Divider{
    background: #d9c0aa00;
    width: 98%;
    height: 0.5px;
}
.Eligibility_box_fields_content_Eligibility{
    border-radius: 20px;
    background: #F4F4F4;
    padding: 32px;
    display: flex;
    gap: 24px;
    flex-direction: column;
    align-items: flex-start;
    width: 362px;
    height: 180px;
}
.Eligibility_box_fields_content_Eligibility2{
    border-radius: 20px;
    background: #F4F4F4;
    padding: 32px;
    gap: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 765px;
    height: 180px;
}
.Eligibility_box_fields{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
}
.Eligibility_box_fields_content{
    border-radius: 20px;
background: #F4F4F4;
padding: 32px;
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 24px;
height: 190px;
}
@media screen and (max-width: 880px) {
.Eligibility_box_fields {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    width: 90%;
    margin: 0 auto;
}}
@media screen and (max-width: 480px) {
    .Eligibility_box_fields {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        gap: 24px;
        width: 90%;
        margin: 0 auto;
    }}
.option3_head{
    padding: 32px;
    border-radius: 20px;
background: #FFF7F1;
margin: 0 auto;
color: #B0383D;

font-size: 57px;
font-style: normal;
font-weight: 900;
line-height: 140%; /* 79.8px */
letter-spacing: 0.143px;
}
.option3_layout{
    max-width: 1280px;
    margin: 0 auto;
    margin-top: 72px;
    margin-bottom: 72px;
}

@media screen and (max-width: 880px) {
.option3_head {
    padding: 24px;
    border-radius: 20px;
    background: #FFF7F1;
    margin: 0 auto;
    color: #B0383D;
    
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%;
    letter-spacing: 0.143px;
    margin: 0 auto;
    width: 80%;
}
}
@media screen and (max-width: 780px) {
    .option3_head {
        padding: 24px;
        border-radius: 20px;
        background: #FFF7F1;
        margin: 0 auto;
        color: #B0383D;
        
        font-size: 32px;
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.143px;
        margin: 0 auto;
        width: 80%;
    }
    }
    @media screen and (max-width: 750px) {
        .option3_head {
            padding: 24px;
            border-radius: 20px;
            background: #FFF7F1;
            margin: 0 auto;
            color: #B0383D;
            
            font-size: 32px;
            font-style: normal;
            font-weight: 900;
            line-height: 140%;
            letter-spacing: 0.143px;
            margin: 0 auto;
            width: 80%;
        }
        }
        @media screen and (max-width: 660px) {
            .option3_head {
                padding: 24px;
                border-radius: 20px;
                background: #FFF7F1;
                margin: 0 auto;
                color: #B0383D;
                
                font-size: 32px;
                font-style: normal;
                font-weight: 900;
                line-height: 140%;
                letter-spacing: 0.143px;
                margin: 0 auto;
                width: 80%;
            }
            }
            @media screen and (max-width: 550px) {
                .option3_head {
                    padding: 24px;
                    border-radius: 20px;
                    background: #FFF7F1;
                    margin: 0 auto;
                    color: #B0383D;
                    
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 140%;
                    letter-spacing: 0.143px;
                    margin: 0 auto;
                    width: 80%;
                }
                }
                @media screen and (max-width: 480px) {
                    .option3_head {
                        padding: 24px;
                        border-radius: 20px;
                        background: #FFF7F1;
                        margin: 0 auto;
                        color: #B0383D;
                        
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 900;
                        line-height: 140%;
                        letter-spacing: 0.143px;
                        margin: 0 auto;
                        width: 80%;
                    }
                    }

/* Your base styles */
.including_family_content {
    width: 1210px;
    padding: 32px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #FFF7F1;
    color: #3B3B3B;
    font-family: Mulish; 
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.06px;
}

.including_family_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    gap: 24px;
    margin-top: 24px;
}

@media screen and (max-width: 768px) {
    .including_family_content {
        width: 95%;
        padding: 16px; 
        font-size: 18px; 
    }
    
    .including_family_container {
        gap: 16px;
        margin-top: 16px;
        width: 90%;
    }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .including_family_content {
        width: 80%; /* Adjust width for tablets */
        padding: 24px; /* Adjust padding for tablets */
        font-size: 20px; /* Adjust font size for tablets */
    }
    
    .including_family_container {
        gap: 20px; /* Adjust gap for tablets */
        margin-top: 20px; /* Adjust margin-top for tablets */
    }
}

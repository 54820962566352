.Deatiled_blog_layout{
    margin: 0 auto;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 72px;
}
.Deatiled_Blog_double_fields{
    display: flex;
    gap: 24px;
}
.Deatiled_blog_fields{
    display: flex;
    gap: 24px;
    align-items: flex-start;
    flex-direction: column;
    width: 556px;
    border-radius: 20px;
    height: 185px;
    padding: 32px;
    background: rgba(244, 244, 244, 1);
}
.Deatiled_blog_title{
    font-family: Mulish;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0.0025em;
    text-align: left;
    color: rgba(59, 59, 59, 1);
    
}
.Deatiled_blog_desc{
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(132, 132, 132, 1);
    
}
@media screen and (max-width: 1280px) {
    .Deatiled_blog_layout{
        margin: 0 auto;
        /* width: 80%; */
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding-bottom: 52px;
    }
    .Deatiled_blog_fields {
        display: flex;
        gap: 24px;
        align-items: flex-start;
        flex-direction: column;
        width: 556px;
        border-radius: 20px;
        height: auto;
        padding: 32px;
        background: rgba(244, 244, 244, 1);
    }
}
@media screen and (max-width: 880px) {
    .Deatiled_Blog_double_fields {
        display: flex;
        gap: 24px;
        flex-direction: column;
    }
    .Deatiled_blog_fields {
        display: flex;
        gap: 24px;
        align-items: flex-start;
        flex-direction: column;
        width: 80%;
        border-radius: 20px;
        height: auto;
        margin: 0 auto;
        padding: 24px;
        background: rgba(244, 244, 244, 1);
    }
}
@media screen and (max-width: 480px) {

}
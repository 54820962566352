.modalLayout {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    /* overflow: scroll; */
    height: auto;
}

.EligibilitymodalLayout {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    /* height: 200vh; */
}

.modalLayoutMain {
    width: 1030px;
    border-radius: 20px;
    cursor: pointer;
    height: 100vh;
    max-height: 100vh;
    overflow: scroll;
    background: white;
}
.modalLayoutMain::-webkit-scrollbar{
display: none;
}

.OptionOverviewContentLayout {
    display: flex;
    gap: 16px;
    justify-content: space-between;
}

.OptionOverviewStyling {
    padding: 32px;
    /* border: 1px solid black; */
}

.OptionOverviewHeading {
    color: #3B3B3B;
    font-family: Arial;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.08px;
}

.OptionOverviewContentBox {
    border-radius: 20px;
    background: #F4F4F4;
    padding: 32px;
    margin-top: 40px;
}

.OptionOverviewContentBox1 {
    border-radius: 20px;
    background: #F4F4F4;
    padding: 32px;
    margin-top: 40px;
    width: 347px;
    height: auto;
}

.OptionOverviewContentBox2 {
    border-radius: 20px;
    background: #F4F4F4;
    padding: 32px;
    margin-top: 40px;
    width: 709px;
    height: auto;
}

.OptionOverviewContentBox3 {
    border-radius: 20px;
    background: #F4F4F4;
    padding: 32px;
    margin-top: 40px;
    width: auto;
    height: auto;
}

.OptionOverviewContentBox4 {
    border-radius: 20px;
    background: #F4F4F4;
    padding: 32px;
    margin-top: 40px;
    width: 528px;
    height: auto;
}

.OptionOverviewContentDesc {
    color: #3B3B3B;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.06px;
}

.OptionOverviewContentHead {
    color: #3B3B3B;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
    margin-bottom: 24px;
}

.OptionOverviewDesc {
    color: #848484;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.04px;
}

@media screen and (max-width:1020px) {
    .OptionOverviewContentLayout {
        gap: 0px;
        flex-direction: column;
    }

    .OptionOverviewContentBox1,
    .OptionOverviewContentBox2,
    .OptionOverviewContentBox4 {
        width: auto;
    }
}

@media screen and (max-width: 890px) {
    .OptionOverviewStyling {
        padding: 16px;
    }

    .OptionOverviewHeading {
        font-size: 28px;
    }

    .OptionOverviewContentBox {
        padding: 16px;
        margin-top: 24px;
    }

    .OptionOverviewContentBox1,
    .OptionOverviewContentBox2,
    .OptionOverviewContentBox3,
    .OptionOverviewContentBox4 {
        padding: 16px;
        margin-top: 24px;
    }

    .OptionOverviewContentDesc,
    .OptionOverviewContentHead {
        font-size: 18px;
    }

    .OptionOverviewDesc {
       font-size: 14px;
    }
}

@media screen and (max-width: 880px) {
.modalLayoutMain {
    width: 1030px;
    border-radius: 20px;
    cursor: pointer;
    height: auto;
    max-height: 100vh;
    overflow: scroll;
    background: white;
}
.modalLayout::-webkit-scrollbar{
    display: none;
}
}

@media screen and (max-width: 660px) {
    .OptionOverviewStyling {
        padding: 16px;
    }

    .EligibilitymodalLayout{
        /* height: 300vh; */
    }

    .modalLayout {
        height: 80vh;
    }

    .OptionOverviewHeading {
        font-size: 16px;
    }

    .OptionOverviewContentBox {
        padding: 16px;
    }

    .OptionOverviewContentHead{
        margin-bottom: 8px;
    }

    .OptionOverviewContentDesc {
        font-size: 12px;
    }

    .OptionOverviewContentBox1,
    .OptionOverviewContentBox2,
    .OptionOverviewContentBox3,
    .OptionOverviewContentBox4 {
        margin-top: 16px;
    }
    
    .modalLayoutMain {
        width: 95%;
        border-radius: 20px;
        cursor: pointer;
        height: auto;
        background: white;
    }
    
}
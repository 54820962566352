.pathway-layout {
    margin: 0 auto;
    max-width: 1280px;
    padding: 36px 72px;
}

.patway-heading-main {
    padding: 32px;
    border-radius: 20px;
    background: #FFF7F1;
    margin-bottom: 24px;
}

.patway-heading {
    color: #B0383D;
    font-family: Arial;
    font-size: 50px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%;
    letter-spacing: 0.14px;
}
.patway-heading_Oversea{
    padding: 32px;
    border-radius: 20px;
    background:  rgba(244, 244, 244, 1);
    color: rgba(1, 74, 145, 1);
    font-family: Mulish;
font-size: 56px;
font-weight: 900;
line-height: 78px;
letter-spacing: 0.0025em;
text-align: left;
margin-bottom: 24px;


}
.pathway-questions_Oversea{
    padding: 32px;
    border-radius: 20px;
    background:  rgba(244, 244, 244, 1);
    /* cursor: pointer; */
    width: 628px;
    height: 80px;
}
.pathway-layout_oversea {
    margin: 0 auto;
    max-width: 1280px;
    padding: 72px 72px;
}
.pathway-desc_Oversea {
    display: flex;
    color: #848484;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* margin-bottom: 40px; */
    /* margin-top: 24px; */
}
.pathway-questions {
    padding: 32px;
    border-radius: 20px;
    background: #FFF7F1;
    cursor: pointer;
    /* width: 545px; */
    width: 625px;
}

.pathway-sublayout-ques {
    color: #3B3B3B;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
}

.pathway-desc {
    display: flex;
    color: #848484;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 40px;
    margin-top: 24px;
}

.pathway-button {
    display: flex;
    padding: 8px 18px 8px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 198px;
    height: 40px;
    border-radius: 128px;
    background: #B0383D;
    color: #F1FDFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.04px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
}

.team-layout-two-main{
    display: none;
}

.pathway-sublayout-one {
    display: flex;
    flex-direction: column;
    max-width: 556px;
    gap: 24px;
    /* justify-content: space-around; */
}

.pathway-image {
    width: 44%;
    max-width: 1280px;
    height: 480px;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
}

.pathwayMapImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pathway-layout-one {
    display: flex;
    gap: 24px;
    justify-content: space-between;
}

.pathway-map-image {
    height: 480px;
}

@media screen and (max-width: 1050px) {
    .pathway-layout-one {
        flex-direction: column-reverse;
    }

    .pathway-image {
       width: 100%;
    }

    .pathway-map-image {
        width: 100%;
        height: 450px;
    }

    .pathway-layout {
        padding: 24px 32px;
    }
    .pathway-layout_oversea {
        padding: 24px 32px;
    }

    .pathway-sublayout-one {
        max-width: 1050px;
    }
}

@media screen and (max-width: 660px) {
    .pathway-layout {
        padding: 24px 16px;
    }
    .pathway-layout_oversea {
        padding: 24px 16px;
    }
    .patway-heading-main {
        padding: 16px;
    }

    .patway-heading {
        font-size: 24px;
    }

    .pathway-image {
        height: 320px;
    }

    .pathway-map-image {
        width: 100%;
        height: 360px;
    }

    .pathway-sublayout-ques {
        font-size: 20px;
    }

    .pathway-desc {
        font-size: 14px;
        margin-bottom: 29px;
    }

    .pathway-button {
        font-weight: 600;
        font-size: 14px;
        display: flex;
        gap: 12px;
        justify-content: center;
    }

    .pathway-questions {
        padding: 16px;
        width: 90%;
    }
    .pathway-questions_Oversea{
         padding: 16px;
        width: 90%;
        height: auto;
    }
    .patway-heading_Oversea {
        padding: 16px;
        border-radius: 20px;
        background: rgba(244, 244, 244, 1);
        color: rgba(1, 74, 145, 1);
        font-family: Mulish;
        font-size: 24px;
        font-weight: 900;
        line-height: 78px;
        letter-spacing: 0.0025em;
        text-align: left;
        margin-bottom: 24px;
    }
}

@media screen and (max-width: 500px) {
    .pathway-map-image {
        height: 250px;
    }
    .patway-heading_Oversea {
        padding: 16px;
        border-radius: 20px;
        background: rgba(244, 244, 244, 1);
        color: rgba(1, 74, 145, 1);
        font-family: Mulish;
        font-size: 24px;
        font-weight: 900;
        line-height: 78px;
        letter-spacing: 0.0025em;
        text-align: left;
        margin-bottom: 24px;
    }
    .pathwayMapImage{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.OptionOneContentBox1 {
    border-radius: 20px;
    background: hsl(0, 0%, 96%);
    padding: 32px;
    margin-top: 40px;
}

.OptionOneContentBox {
    border-radius: 20px;
    background: #F4F4F4;
    padding: 32px;
    margin-top: 24px;
}


.OptionOneContentBox2 {
    border-radius: 20px;
    background: #F4F4F4;
    padding: 32px;
    margin-top: 24px;
    max-width: 528px;
    width: 100%;
}

.OptionOneContentBox-layout {
    display: flex;
    gap: 24px;
    justify-content: space-between;
}

.OptionOneFamHead {
    color: #3B3B3B;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
    margin-bottom: 24px;
}

.OptionOneFamDesc {
    color: #848484;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.04px;
}

@media screen and (max-width: 890px) {
    .OptionOneContentBox1, .OptionOneContentBox, .OptionOneContentBox2  {
        padding: 24px;
        margin-top: 24px;
    }

    .OptionOneFamHead {
      font-size: 18px;
      margin-bottom: 16px;
    }

    .OptionOneFamDesc {
       font-size: 14px;
    }
}

@media screen and (max-width: 660px) {
    .OptionOneContentBox1, .OptionOneContentBox, .OptionOneContentBox2  {
        padding: 16px;
        margin-top: 16px;
    }

    .OptionOneFamHead {
      font-size: 16px;
      margin-bottom: 8px;
    }

    .OptionOneContentBox2{
        width: auto;
    }

    .OptionOneFamDesc {
       font-size: 12px;
    }

    .OptionOneContentBox-layout{
        flex-direction: column;
        gap: 0px;
    }
}
.OverSeaLayout{
    margin: 0 auto;
    padding: 32px 72px;
    display: flex;
    max-width: 1280px;
    flex-direction: column;
    gap: 24px;
}
.oversea_title{
    color: rgba(176, 56, 61, 1);
    font-family: Mulish;
font-size: 56px;
padding: 32px;
font-weight: 900;
line-height: 78px;
letter-spacing: 0.0025em;
text-align: left;
border-radius: 20px;
background: rgba(255, 247, 241, 1);

}
.Oversea_Box{
    border-radius: 20px;
    padding: 32px;
    background: rgba(255, 247, 241, 1);
    color: rgba(59, 59, 59, 1);
}
@media screen and (max-width: 960px) {
.OverSeaLayout {
    margin: 0 auto;
    padding: 32px;
    display: flex;
    max-width: 1280px;
    flex-direction: column;
    gap: 24px;
}
}
@media screen and (max-width: 480px) {
    .OverSeaLayout {
        margin: 0 auto;
        padding: 16px;
        display: flex;
        max-width: 1280px;
        flex-direction: column;
        gap: 24px;
    }
    .oversea_title{
        color: rgba(176, 56, 61, 1);
        font-family: Mulish;
    font-size: 32px;
    padding: 16px;
    
    }
    .Oversea_Box{
        padding: 24px;
    }
    }
.visaHolderMainLayout {
    margin: 0 auto;
    max-width: 1280px;
    padding: 0px 72px 0px 72px;
}
.Visa186Overview_layout{
    margin: 0 auto;
    max-width: 1280px;
    padding-top: 52px;
}
.visaHolderHeadBox {
    border-radius: 20px;
    background: #FFF7F1;
    padding: 32px;
    margin-bottom: 24px;
}

.visaHolderHeading {
    color: #B0383D;
      font-family: Mulish;
    font-size: 57px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%;
    letter-spacing: 0.143px;
}

.visaHolderContentLayout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
}

.visaHolderContentBox {
    border-radius: 20px;
    background: #FFF7F1;
    padding: 32px;
    width: 556px;
    height: 268px;
    color: #3B3B3B;
      font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.06px;
}

@media screen and (max-width:890px) {
    .visaHolderMainLayout {
        padding: 0px 54px 54px 54px;
    }
    .visaHolderHeadBox{
        padding: 24px;
    }
    .visaHolderHeading{
        font-size: 48px;
    }
    .visaHolderContentBox{
        padding: 24px;
        height: 176px;
        font-size: 18px;
    }
}

@media screen and (max-width:660px) {
    .visaHolderMainLayout {
        padding: 0px 16px 16px 16px;
    }
    .visaHolderHeadBox{
        padding: 16px;
        margin-bottom: 16px;
    }
    .visaHolderHeading{
        font-size: 24px;
    }
    .visaHolderContentBox{
        padding: 16px;
        font-size: 12px;
        height: auto;
        width:-webkit-fill-available;
    }
    .visaHolderContentLayout{
        flex-direction: column;
    }
}
.applicant494-layout {
    margin: 0 auto;
    max-width: 1280px;
    /* padding: 72px; */
    /* padding-bottom: 72px; */
}

.applicant494-headbox {
    border-radius: 20px;
    background: #F4F4F4;
    padding: 32px;
}

.applicant494-heading {
    color: #014A91;
    font-family: Arial;
    font-size: 56px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%;
    letter-spacing: 0.14px;
}

.applicants494-content {
    width: 363px;
    height: auto;
    border-radius: 20px;
    background: #F4F4F4;
    padding: 32px;
    display: flex;
    flex-direction: column;
}

.applicant494-contentbox {
    display: flex;
    margin-top: 24px;
    justify-content: space-between;
    gap: 24px;
}

.applicant-content-head {
    color: #3B3B3B;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
    margin-bottom: 24px;
}

.applicant-content-desc {
    color: #848484;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.04px;
}

.applicant-box-two {
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid #D9C0AA;
}

@media screen and (max-width: 890px) {
    .applicant494-layout {
        padding: 54px;
    }

    .applicant494-headbox {
        padding: 24px;
    }

    .applicant494-heading {
        font-size: 48px;
    }

    .applicants494-content {
        width: auto;
        padding: 24px;
    }

    .applicant494-contentbox {
        flex-direction: column;
    }

    .applicant-content-head {
        font-size: 18px
    }

    .applicant-content-desc {
        font-size: 14px;
    }

    .applicant-box-two {
        padding-bottom: 24px;
        margin-bottom: 24px;
    }
}

@media screen and (max-width: 660px) {
    .applicant494-layout {
        padding: 16px;
    }

    .applicant494-headbox {
        padding: 16px;
    }

    .applicant494-heading {
       font-size: 24px
    }

    .applicants494-content {
       padding: 16px;
    }

    .applicant494-contentbox {
        margin-top: 16px;
        gap: 16px;
    }

    .applicant-content-head {
        font-size: 16px;
        margin-bottom: 16px;
    }

    .applicant-content-desc {
        font-size: 12px;
    }

    .applicant-box-two {
        padding-bottom: 16px;
        margin-bottom: 16px;
    }
}
.shortTermLayout {
    margin: 0 auto;
    max-width: 1280px;
    /* padding: 36px 72px; */
}

.shortTermHeading {
    padding: 32px;
    border-radius: 20px;
    background: #FFF7F1;
    margin-bottom: 24px;
}

.shortHeading {
    color: #B0383D;
    font-family: Arial;
    font-size: 57px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%;
    letter-spacing: 0.143px;
}

.shortTermDescBox {
    padding: 32px;
    border-radius: 20px;
    background: #FFF7F1;
    margin-bottom: 24px;
}

.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(41, 44, 51, 0.5);
}

.shortTermDesc {
    color: #3B3B3B;
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.06px;
}

.shortTermGeneralBox {
    padding: 32px;
    font-family: Mulish;
    border-radius: 20px;
    background: #FFF7F1;
    width: 363px;
}

.shortGeneralHead {
    color: #3B3B3B;
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
    padding-bottom: 24px;
}

.shortGeneralDesc {
    color: #848484;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.04px;
}

.shortTermContentBox {
    display: flex;
    gap: 24px;
    max-width: 1280px;
    font-family: Mulish;
    justify-content: space-between;
}

.stayPeriodBox {
    padding: 32px;
    border-radius: 20px;
    background: #FFF7F1;
    margin-top: 24px;
}

.stayPeriodHead {
    color: #3B3B3B;
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
    margin-bottom: 24px;
}

.stayPeriodContent {
    color: #848484;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.04px;
}

.infoShortTermIcon {
    display: flex;
    align-items: center;
    gap: 4px;
}

.subClassHoverBoxStyle {
    max-width: 1280px;
    display: flex;
    justify-content: flex-end;
    padding-right: 32px;
}

.subClassHoverBox {
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px;
    max-width: 366px;
    height: auto;
    /* cursor: pointer; */
    margin-top: -30px;
    margin-right: -80px;
    margin-bottom: -140px;
}

.mainSubClass:hover .subClassHoverBox {
    display: flex;
}

.subClassHoverContent {
    color: #B0383D;
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04px;
}
.shortTermDesc {
    color: #848484;
    font-family: Mulish;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.06px;
}
@media screen and (max-width: 1024px) {
    .shortTermLayout {
        padding: 24px 32px;
    }

    .shortTermContentBox {
        flex-direction: column;
    }

    .shortTermGeneralBox {
        width: auto;
    }

    .subClassHoverBoxStyle {
        justify-content: start;
        padding-left: 32px;
    }

    .subClassHoverBox {
        margin-top: 4px;
        margin-bottom: 0px;
        margin-left: 0px;
        margin-right: 0px;
    }
}

@media screen and (max-width: 660px) {
    .shortTermLayout {
        padding: 24px 16px;
    }

    .shortTermHeading,
    .shortTermDescBox,
    .shortTermGeneralBox,
    .stayPeriodBox {
        padding: 16px;
    }

    .subClassHoverBoxStyle {
        padding-left: 12px;
    }

    .shortHeading {
        font-size: 24px;
    }

    .shortTermDesc,
    .shortGeneralHead,
    .stayPeriodHead {
        font-size: 18px;
    }

    .shortGeneralHead {
        padding-bottom: 12px;
    }

    .shortGeneralDesc,
    .stayPeriodContent {
        font-size: 12px;
    }

    .subClassHoverBox {
        gap: 8px;
        padding: 16px;
        max-width: 260px;
        margin-top: 4px;
        margin-bottom: 0px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .subClassHoverContent {
        font-size: 12px;
    }
    .shortTermDesc {
        color: #848484;
        font-family: Mulish;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.06px;
    }
}
/* Base styles */
.Overview_faq_container_head {
  border-radius: 20px;
  padding: 32px;
    color: #014A91;
    
    font-size: 56px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%;
    letter-spacing: 0.14px;
    border-radius: 20px;
    background: #F4F4F4;
  }
  
  /* Media queries for responsive design */
  @media screen and (max-width: 1280px) {
    .Overview_faq_container_head {
      border-radius: 20px;
      padding: 24px;
      color: #014A91;
      font-size: 56px;
      font-style: normal;
      font-weight: 900;
      line-height: 140%;
      letter-spacing: 0.14px;
      border-radius: 20px;
      background: #F4F4F4;
      width: 95%;
      margin: 0 auto;
  }
  }
  
  @media screen and (max-width: 890px) {
    .Overview_faq_container_head {
      padding: 24px;
      margin: 0 auto;
      width: 92%;
      font-size: 32px;
  
  
    }
  }
  
  @media screen and (max-width: 660px) {
    .Overview_faq_container_head {
      padding: 16px;
    }
  }
  
  @media screen and (max-width: 780px) {
    .Overview_faq_container_head {
      padding: 24px;
      margin: 0 auto;
      width: 90%;
      font-size: 32px;
  
    }
  }
  
  @media screen and (max-width: 750px) {
    .Overview_faq_container_head {
      padding: 24px;
      margin: 0 auto;
      width: 80%;
      font-size: 42px;
    }
  }
  
  @media screen and (max-width: 660px) {
    .Overview_faq_container_head {
      padding: 16px;
      font-size: 28px;
    }
  }
  
  @media screen and (max-width: 550px) {
    .Overview_faq_container_head {
      padding: 16px;
      font-size: 28px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .Overview_faq_container_head {
      padding: 16px;
      font-size: 28px;
    }
  }
  
  @media screen and (max-width: 380px) {
    .Overview_faq_container_head {
      padding: 16px;
      font-size: 28px;
      width: 90%;
    }
  }
  
  @media screen and (max-width: 290px) {
    .Overview_faq_container_head {
      padding: 16px;
      font-size: 28px;
    }
  }
  
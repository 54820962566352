.eligibilityMain-layout {
    margin: 0 auto;
    max-width: 1280px;
    /* padding: 32px 72px 32px 72px; */
    padding-bottom: 72px;
}

.eligibility-heading {
    border-radius: 23px;
    background: #F4F4F4;
    font-family: Mulish;
    padding: 32px;
    margin-bottom: 24px;
}

.visa-eligibility-heading {
    color: #014A91;
    font-family: Mulish;
    font-size: 56px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%;
    letter-spacing: 0.14px;
}
.eligiblity-desc1
li{
    font-family: Mulish;
font-size: 16px;
font-weight: 400;
line-height: 23px;
letter-spacing: 0.0025em;
text-align: left;
color: rgba(132, 132, 132, 1);

}
.eligiblity-desc
li{
    font-family: Mulish;
font-size: 16px;
font-weight: 400;
line-height: 23px;
letter-spacing: 0.0025em;
text-align: left;
color: rgba(132, 132, 132, 1);

}
.eligibility-two-desc
li{
    font-family: Mulish;
font-size: 16px;
font-weight: 400;
line-height: 23px;
letter-spacing: 0.0025em;
text-align: left;
color: rgba(132, 132, 132, 1);

}
.eligibility-subHeading {
    color: #3B3B3B;
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0.06px;
}

.eligibility-layout-one {
    max-width: 362px;
    width: 28%;
    border-radius: 23px;
    background: #F4F4F4;
    padding: 32px;
}

.eligibility-layout-one-186 {
    max-width: 362px;
    width: 28%;
    border-radius: 23px;
    background: #F4F4F4;
    padding: 32px;
}

.eligiblity-heading {
    color: #3B3B3B;
     font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
    margin-bottom: 24px;
}

.eligiblity-heading-186 {
    color: #3B3B3B;
     font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
    margin-bottom: 24px;
}

.eligiblity-desc {
    color: #848484;
     font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04px;
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid #D9C0AA;
}

.eligiblity-heading1 {
    color: #3B3B3B;
     font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
    margin-bottom: 24px;
}

.eligiblity-desc1 {
    color: #848484;
     font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04px;
}

.eligibility-two-service {
    border-radius: 20px;
    background: #F4F4F4;
    padding: 32px;
    max-width: 362px;
    width: 100%;
    height: 300px;
}

.eligibility-two-service-186 {
    border-radius: 20px;
    background: #F4F4F4;
    padding: 32px;
    max-width: 362px;
    width: 100%;
    height: 350px;
}

.eligibility-two-trans {
    height: auto;
    border-radius: 20px;
    background: #F4F4F4;
    padding: 32px;
    max-width: 362px;
    width: 100%;
}

.eligibility-two-sublayout2 {
    padding: 32px;
    border-radius: 20px;
    background: #F4F4F4;
}

.eligibility-two-head {
    color: #3B3B3B;
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
    margin-bottom: 24px;
}

.eligibility-two-desc {
    color: #848484;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04px;
}

.eligibility-two-support {
    color: #3B3B3B;
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
    margin-bottom: 24px;
}

.eligibility-main-layout2 {
    display: none;
}

.eligibility-two-support-desc {
    color: #848484;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.04px;
}

.eligibility-two-sublayout1 {
    display: flex;
    gap: 24px;
}

.eligibility-second-layout {
    display: flex;
    gap: 24px;
    justify-content: space-between;
    margin-bottom: 24px;
    margin-top: 24px;
}

.eligiblity-layout-two {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.transacVisaBox {
    max-width: 564px;
    height: 256px;
    border-radius: 23px;
    background: #F4F4F4;
    padding: 32px;
}

.visaTransacHead {
    color: #3B3B3B;
     font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
    margin-bottom: 24px;
}

.visaTransacDesc {
    color: #848484;
     font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.04px;
}

.eligibility-extra-layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    margin-top: 24px;
}

@media screen and (max-width:1090px) {
    .eligibility-two-service {
        height: auto;
    }

    .eligibility-two-service {
        height: 400px;
    }

    .eligibility-layout-one-186 {
        width: 40%;
    }
}

@media screen and (max-width:950px) {
    .eligibility-second-layout {
        flex-direction: column;
    }

    .eligibility186-layout-exp {
        width: 125%;
    }

    .eligibility-layout-one {
        display: flex;
        max-width: 1280px;
        width: 91%;
    }

    .eligibility-layout-one-186 {
        display: flex;
        max-width: 1280px;
        width: 91%;
    }

    .eligiblity-desc {
        padding-right: 18px;
        border-bottom: none;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }

    .eligibility-layout-ext {
        padding-left: 18px;
        border-left: 1px solid #D9C0AA;
    }
}

@media screen and (max-width:890px) {
    .eligibilityMain-layout {
        padding: 54px 54px 54px 54px;
    }

    .eligibility-second-layout {
        margin-top: 0px;
    }

    .eligibility-heading,
    .eligibility-two-sublayout2,
    .eligibility-two-service,
    .eligibility-two-trans,
    .eligibility-layout-one-186 {
        padding: 24px;
    }

    .visa-eligibility-heading {
        font-size: 48px;
    }

    .eligibility-subHeading {
        font-size: 23px;
    }

    .eligibility-layout-one {
        padding: 24px;
        margin-top: 24px;
        width: -webkit-fill-available;
    }

    .eligibility-two-sublayout2 {
        margin-bottom: 0px;
    }

    .transacVisaBox {
        padding: 24px;
    }
    
    .visaTransacHead {
        font-size: 18px;
        margin-bottom: 18px;
    }
    
    .visaTransacDesc {
        font-size: 14px;
    }
}

@media screen and (max-width:660px) {
    .eligibility-two-sublayout1 {
        flex-direction: column;
    }

    .eligibilityMain-layout {
        padding: 16px 16px 16px 16px;
    }

    .eligibility-heading,
    .eligibility-two-sublayout2,
    .eligibility-two-service,
    .eligibility-two-trans {
        padding: 16px;
    }

    .eligibility-two-service {
        height: auto;
    }

    .eligibility-two-service-186 {
        padding: 16px;
        height: auto;
        max-width: 555px;
        width: 91%;
    }

    .visa-eligibility-heading {
        font-size: 24px;
    }

    .eligibility-subHeading {
        font-size: 16px;
    }

    .eligibility-layout-one,
    .eligibility-layout-one-186 {
        padding: 16px;
    }

    .eligiblity-heading,
    .eligiblity-heading1,
    .eligibility-two-head,
    .eligibility-two-support {
        font-size: 16px;
        margin-bottom: 16px;
    }

    .eligiblity-desc1,
    .eligibility-two-desc,
    .eligibility-two-support-desc,
    .eligiblity-desc {
        font-size: 12px;
    }

    .eligibility-two-trans,
    .eligibility-two-service {
        max-width: 660px;
        width: 92%;
    }

    .eligibility186-layout-exp {
        width: 215%;
    }

    .eligiblity-heading-186 {
        font-size: 16px;
        margin-bottom: 14px;
    }
    .transacVisaBox {
        padding: 16px;
        height: auto;
    }
    
    .visaTransacHead {
        font-size: 16px;
        margin-bottom: 16px;
    }
    
    .visaTransacDesc {
        font-size: 12px;
    }
    
    .eligibility-extra-layout {
       flex-direction: column;
    }
}
.EmpHowLayout {
    margin: 0 auto;
    max-width: 1280px;
    padding: 0px 0px 0px 0px;
}

.EmpHowHeadBox {
    border-radius: 20px;
    background: #FFF7F1;
    padding: 32px;
}

.EmpHow-Image{
    width: 100%;
    border-radius: 20px;
}

.EmpHowImage {
    margin-top: 24px;
    margin-bottom: 24px;
    max-width: 1280px;
}

.howSubLayout {
    border-radius: 20px;
    background: #FFF7F1;
    width: 556px;
    height: 110px;
    padding: 32px;
}

.EmpHowSubLayout {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
}

.EmpHowHeading {
    color: #B0383D;
     font-family: Mulish;
    font-size: 57px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%;
    letter-spacing: 0.143px;
}

.EmpHowSubHead {
    color: #3B3B3B;
     font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.06px;
    margin-bottom: 16px;
}

.EmpHowDesc {
    color: #848484;
     font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04px;
}

@media screen and (max-width:950px) {
    .EmpHowSubLayout{
        flex-wrap: wrap;
    }
    .howSubLayout{
        height: 100px;
    }
}

@media screen and (max-width: 890px) {
    .EmpHowLayout {
        padding: 0px 54px 54px 54px;
    }
    .howSubLayout{
        width: 254px;
    }
}

@media screen and (max-width: 660px) {
    .EmpHowLayout {
        padding: 0px 16px 16px 16px;
    }
    .howSubLayout{
        width: -webkit-fill-available;
        padding: 16px;
        height: auto;
    }
    .EmpHowHeading{
        font-size: 24px;
    }
    .EmpHowHeadBox{
        padding: 16px;
    }
    .EmpHowSubHead{
        font-size: 18px;
    }
    .EmpHowDesc{
        font-size: 12px;
    }
}

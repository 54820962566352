.PartnerVisaHeadComp_layout{
    max-width: 1280px;
    margin: 0 auto;

}
.PartnerVisaHeadComp_Title{
    color: #014A91;
    font-family: Mulish;
font-size: 56px;
font-style: normal;
font-weight: 900;
line-height: 140%; /* 78.4px */
letter-spacing: 0.14px;
border-radius: 20px;
background: #F4F4F4;
padding: 32px;
}
.Family_Box{
    border-radius: 20px;
background: #F4F4F4;
flex-shrink: 0;
padding: 32px;
margin-top: 24px;
color: #3B3B3B;
font-family: Mulish;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 28px; /* 116.667% */
letter-spacing: 0.06px;
}
.Family_Box li{
    color: #848484;
    font-family: Mulish;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
letter-spacing: 0.04px;
margin-top: 16px;
}
@media screen and (max-width: 1280px) {
    .PartnerVisaHeadComp_layout {
        margin: 0 auto;
    
        width: 95%;
    }
}
@media screen and (max-width: 880px) {
    .PartnerVisaHeadComp_layout {
        margin: 0 auto;
    
        width: 95%;
    }
    .Family_Box{
        border-radius: 20px;
        background: #F4F4F4;
        flex-shrink: 0;
        padding: 16px;
        margin-top: 24px;
        width: 80%;
        margin: 0 auto;
        margin-top: 24px;
        padding: 24px;
        font-size: 20px;
    } 
    .PartnerVisaHeadComp_Title {
        color: #014A91;
        font-family: Mulish;
        font-size: 28px;
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.14px;
        border-radius: 20px;
        background: #F4F4F4;
        padding: 16px;
        width: 85%;
        margin: 0 auto;
    }
}
@media screen and (max-width: 480px) {
    .PartnerVisaHeadComp_layout {
        margin: 0 auto;
    
        width: 95%;
    }
    .Family_Box{
        border-radius: 20px;
        background: #F4F4F4;
        flex-shrink: 0;
        padding: 24px;
        font-size: 18px;
        width: 80%;
        margin: 0 auto;
        margin-top: 24px;
    
    } 
    .PartnerVisaHeadComp_Title {
        color: #014A91;
        font-family: Mulish;
        font-size: 28px;
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.14px;
        border-radius: 20px;
        background: #F4F4F4;
        padding: 16px;
        width: 85%;
        margin: 0 auto;
    }
}
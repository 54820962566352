.Consult_Comp_Layout{
    margin: 0 auto;
    max-width: 1280px;
    margin-top: 72px;
}
.Consult_Comp_Layout_double{
    margin: 0 auto;
    max-width: 1280px;
    /* margin-top: 72px; */
    display: flex;
    gap: 24px;
}
.Consult_Comp_container{
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 20px;
    background: rgba(244, 244, 244, 1);
}
.Consult_Comp_container1{
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 20px;
    background: rgba(244, 244, 244, 1);
    width: 556px;
    height: 180px;
}
.Consult_Comp_title{
    font-family: Mulish;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.0025em;
    text-align: left;
    color: rgba(59, 59, 59, 1);
    
}
.Consult_Comp_desc{
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;
    text-align: left;
    color: rgba(132, 132, 132, 1);

}
.Consult_Comp_btn{
width: 166px;
padding: 10px 36px 10px 36px;
border-radius: 128px;
gap: 8px;
background: rgba(176, 56, 61, 1);
font-family: Mulish;
font-size: 16px;
font-weight: 700;
line-height: 20px;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
text-transform: uppercase;
letter-spacing: 0.0025em;
text-align: left;
cursor: pointer;
color: rgba(241, 253, 255, 1);
}
@media screen and (max-width: 1280px) {
    .Consult_Comp_container {
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        border-radius: 20px;
        background: rgba(244, 244, 244, 1);
        width: 90%;
        margin: 0 auto;
    }
    }
@media screen and (max-width: 880px) {
.Consult_Comp_container {
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 20px;
    background: rgba(244, 244, 244, 1);
    width: 80%;
    margin: 0 auto;
}
.Consult_Comp_btn {
    padding: 10px 20px 10px 20px;
    border-radius: 128px;
    gap: 8px;
    background: rgba(176, 56, 61, 1);
    font-size: 14px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 0.0025em;
    text-align: left;
    color: rgba(241, 253, 255, 1);
    width: 150px;
}
}
@media screen and (max-width: 880px) {
    .Consult_Comp_title {
        font-family: Mulish;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.0025em;
        text-align: left;
        color: rgba(59, 59, 59, 1);
    }
    .Consult_Comp_desc {
        font-family: Mulish;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.0025em;
        text-align: left;
        color: rgba(132, 132, 132, 1);
    }
    .Consult_Comp_btn {
        padding: 10px 20px 10px 20px;
        border-radius: 128px;
        gap: 8px;
        background: rgba(176, 56, 61, 1);
        font-size: 14px;
        font-size: 16px;
        cursor: pointer;
        font-weight: 700;
        line-height: 20px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        letter-spacing: 0.0025em;
        text-align: left;
        color: rgba(241, 253, 255, 1);
        width: 150px;
    }
   
    }
    @media screen and (max-width: 480px) {
        .Consult_Comp_container1 {
            padding: 24px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            border-radius: 20px;
            background: rgba(244, 244, 244, 1);
            width: 227px;
            height: auto;
        }
    }
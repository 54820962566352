.family_layout_comp_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    margin-top: 24px;
    margin-bottom: 72px;
    
}
.family_layout_comp_container_right{
    border-radius: 20px;
    background: #FFF7F1;
    width: 320px;
    height: 520px;
    flex-shrink: 0;
    padding: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
}
.family_comp_left_fields{
    display: flex;
    gap: 24px;
}
.family_comp_title{
    color: #3B3B3B;
    font-family: Mulish;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 28px; /* 116.667% */
letter-spacing: 0.06px;
}
.family_comp_desc{
    color: #848484;
    font-family: Mulish;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
letter-spacing: 0.04px;
}
.family_comp_left_box{
    width: 363px;
flex-shrink: 0;
border-radius: 20px;
background: #FFF7F1;
padding: 32px;
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-direction: column;
gap: 24px;
}
.family_comp_left_fields_extraly{
gap: 24px;
display: flex;
flex-direction: column;
align-items: flex-start;
flex-shrink: 0;
border-radius: 20px;
background: #FFF7F1;
padding: 32px;
}
.family_layout_comp_container_left{
display: flex;
flex-direction: column;
align-items: flex-start;
width: 100%;
gap: 24px;
}
@media screen and (max-width: 1200px) {
    .family_layout_comp_container_right {
        width: 100%;
        height: auto;
        padding: 24px;
    }
}
@media screen and (max-width: 880px) {
    .Family_extrayLay {
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        font-family: Mulish;
        margin-top: 24px;
        color: #3B3B3B;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.06px;
        width: 80%;
        margin: 0 auto;
        margin-top: 24px;
        
    }
    .family_comp_left_fields_extraly {
        gap: 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-shrink: 0;
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        width: 80%;
        margin: 0 auto;
    }
    .family_comp_left_fields {
        display: flex;
        gap: 24px;
        /* flex-direction: column; */
        margin: 0 auto;
    }
    .family_comp_left_box {
        width: 285px;
        flex-shrink: 0;
        border-radius: 20px;
        background: #FFF7F1;
        padding: 24px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 24px;
        margin: 0 auto;
    }
    .family_layout_comp_container_right {
        width: 80%;
        height: auto;
        padding: 24px;
    }
    .family_layout_comp_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        margin-top: 24px;
        margin-bottom: 72px;
        flex-direction: column;
    }
    .family_layout_comp_container_right {
        width: 80%;
        height: auto;
        padding: 24px;
    }
    }
@media screen and (max-width: 768px) {
    .family_layout_comp_container {
        flex-direction: column;
    }

    .family_layout_comp_container_right,
    .family_comp_left_box {
        width: 85%;
        height: auto;
        padding: 24px;
        margin: 0 auto;
    }
    .family_comp_left_fields {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    .family_comp_left_fields_extraly{
        width: 85%;
        height: auto;
        padding: 24px;
        margin: 0 auto;
    }
    .Family_extrayLay{   
        width: 85%;
        height: auto;
        padding: 24px;
        margin: 0 auto;
        margin-top: 24px;
    }
}

@media screen and (max-width: 480px) {
    .family_layout_comp_container_right,
    .family_comp_left_box {
        width: 80%;
        height: auto;
        padding: 24px;
        margin: 0 auto;
    }
    .family_comp_left_fields {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    .family_comp_title {
        color: #3B3B3B;
        font-family: Mulish;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.06px;
    }
    .family_comp_desc {
        color: #848484;
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.04px;
    }
    .family_comp_left_fields_extraly{
        width: 80%;
        height: auto;
        padding: 24px;
        margin: 0 auto;
    }
    .Family_extrayLay{   
        width: 80%;
        height: auto;
        font-size: 18px;
        padding: 24px;
        margin: 0 auto;
        margin-top: 24px;
    }
}
.AboutUsVisa_box_fields_content_482{
    background: rgba(244, 244, 244, 1);
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 363px;
    height: 190px;
    border-radius: 20px;

}
@media screen and (max-width: 1280px) {
    .AboutUsVisa_box_fields_content_482 {
        background: rgba(244, 244, 244, 1);
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        width: 80%;
        height: auto;
        border-radius: 20px;
        margin: 0 auto;
    }
    }
@media screen and (max-width: 880px) {
.AboutUsVisa_box_fields_content_482 {
    background: rgba(244, 244, 244, 1);
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 80%;
    height: auto;
    border-radius: 20px;
    margin: 0 auto;
}
}
@media screen and (max-width: 480px) {
    .AboutUsVisa_box_fields_content_482 {
        background: rgba(244, 244, 244, 1);
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        width: 80%;
        height: auto;
        border-radius: 20px;
        margin: 0 auto;
    }
    }
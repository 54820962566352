.IndividualWhat_layout{
    margin: 0 auto;
    max-width: 1280px;
    display: flex;
    gap: 24px;
    padding-bottom: 72px;   
    flex-direction: column;
}
.Individual_title{
    background: rgba(244, 244, 244, 1);
    color: rgba(1, 74, 145, 1);
    border-radius: 20px;
    font-family: Mulish;
    padding: 32px;
    border-radius: 20px;
    font-size: 56px;
    font-weight: 900;
    line-height: 78px;
    letter-spacing: 0.0025em;
    text-align: left;
        
}
.Individual_Box{
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px;
    width: 362px;
    height: 120px;
    align-items: flex-start;
    border-radius: 20px;
    /* margin-top: 24px; */
    background: rgba(244, 244, 244, 1);
}
.Individual_Box_title{
    font-family: Mulish;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.0025em;
    text-align: left;
    color: rgba(59, 59, 59, 1);    
}
.Individual_Box_desc{
    font-family: Mulish;
    font-size: 16px;
    color:rgba(132, 132, 132, 1);
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;
    text-align: left;
    
}
.Individual_Box_field{
    display: flex;
    gap: 24px;
    /* margin-bottom: 24px; */
}
.Consult_layout{
    margin: 0 auto;
    max-width: 1280px;
    
}
.Individual_Comp_Fields{
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
}
.Individual_Comp_container{
    display: flex;
    gap: 24px;
}
.Individual_Comp_container_box{
    width: 555px;
    height: 470px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background: rgba(255, 247, 241, 1);
    border-radius: 20px;
}
.Individual_Comp_container_box2{
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background: rgba(255, 247, 241, 1);
    border-radius: 20px;
    width: 557px;
    height: 200px;
}
.Individual_Comp_container_desc{
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.0025em;
    text-align: left;
    color: rgba(132, 132, 132, 1);    
}
.Individual_Comp_container_box_title{
    font-family: Mulish;
font-size: 24px;
font-weight: 500;
line-height: 28px;
letter-spacing: 0.0025em;
text-align: left;
color: rgba(59, 59, 59, 1);

}
@media screen and (max-width: 1280px) {
    .IndividualWhat_layout{
        margin: 0 auto;
       width: 85%;
        display: flex;
        gap: 24px;
        padding-bottom: 72px;
        flex-direction: column;
    }
    .Individual_Box_field {
        display: flex;
        gap: 24px;
        flex-wrap: wrap;
    }
    .Individual_Box{
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 24px;
        width: 362px;
        height: auto;
        align-items: flex-start;
        border-radius: 20px;
        width: 80%;
        margin: 0 auto;
        /* margin-top: 24px; */
        background: rgba(244, 244, 244, 1);
    }
    .Individual_Comp_container_box{
        width: 555px;
        height: auto;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        background: rgba(255, 247, 241, 1);
        border-radius: 20px;
    }
    .Individual_Comp_container_box2{
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        background: rgba(255, 247, 241, 1);
        border-radius: 20px;
        width: 557px;
        height: auto;
    }
    .Individual_title{
        background: rgba(244, 244, 244, 1);
        color: rgba(1, 74, 145, 1);
        border-radius: 20px;
        font-family: Mulish;
        padding: 24px;
        border-radius: 20px;
        font-size: 42px;
        font-weight: 900;
        line-height: 60px;
        letter-spacing: 0.0025em;
        text-align: left;
            
    }
}
@media screen and (max-width: 880px) {
    .IndividualWhat_layout{
        margin: 0 auto;
       width: 90%;
        display: flex;
        gap: 24px;
        padding-bottom: 72px;
        flex-direction: column;
    }
    .Individual_Box_field {
        display: flex;
        gap: 24px;
        flex-wrap: wrap;
    }
    .Individual_Box{
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 24px;
        width: 80%;
        height: auto;
        align-items: flex-start;
        border-radius: 20px;
        width: 80%;
        margin: 0 auto;
        /* margin-top: 24px; */
        background: rgba(244, 244, 244, 1);
    }
    .Individual_title{
        background: rgba(244, 244, 244, 1);
        color: rgba(1, 74, 145, 1);
        border-radius: 20px;
        font-family: Mulish;
        padding: 24px;
        border-radius: 20px;
        font-size: 42px;
        font-weight: 900;
        line-height: 60px;
        letter-spacing: 0.0025em;
        text-align: left;
            
    }
    .Individual_Comp_container {
        display: flex;
        gap: 24px;
        flex-direction: column;
    }
    .Individual_Comp_container_box2 {
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        background: rgba(255, 247, 241, 1);
        border-radius: 20px;
        width: 90%;
        height: auto;
    }
    .Individual_Comp_container_box {
        width: 90%;
        height: auto;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        background: rgba(255, 247, 241, 1);
        border-radius: 20px;
        margin: 0 auto;
    }
}
@media screen and (max-width: 480px) {
    .IndividualWhat_layout {
        margin: 0 auto;
        width: 85%;
        gap: 24px;
        padding-bottom: 72px;
        flex-direction: column;
        padding: 16px;
    }
    .Individual_Box_field {
        display: flex;
        gap: 24px;
        flex-wrap: wrap;
    }
    .Individual_Box{
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 24px;
        width: 80%;
        height: auto;
        align-items: flex-start;
        border-radius: 20px;
        width: 80%;
        margin: 0 auto;
        /* margin-top: 24px; */
        background: rgba(244, 244, 244, 1);
    }
    .Individual_title{
        background: rgba(244, 244, 244, 1);
        color: rgba(1, 74, 145, 1);
        border-radius: 20px;
        font-family: Mulish;
        padding: 24px;
        border-radius: 20px;
        font-size: 42px;
        font-weight: 900;
        line-height: 60px;
        letter-spacing: 0.0025em;
        text-align: left;
            
    }
    .Individual_Comp_container {
        display: flex;
        gap: 24px;
        flex-direction: column;
    }
    .Individual_Comp_container_box2 {
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        background: rgba(255, 247, 241, 1);
        border-radius: 20px;
        width: 90%;
        height: auto;
    }
    .Individual_Comp_container_box {
        width: 90%;
        height: auto;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        background: rgba(255, 247, 241, 1);
        border-radius: 20px;
        margin: 0 auto;
    }
}
.VisaBookNow_Layout{
    margin: 0 auto;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    margin-top: 72px;
    margin-bottom: 72px;
    gap: 24px;
}
.VisaBookNow_Conatiner{
    border-radius: 20px 0px 0px 20px;
    background: rgba(244, 244, 244, 1);
    display: flex;
    gap: 24px;
    padding: 32px;    
    justify-content: center;
    align-items: center;
}
.VisaBookNow_Content{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.VisaBookNow_Btn{
    background: rgba(176, 56, 61, 1);
    width: 169px;
    padding: 10px 28px 10px 36px;
    border-radius: 96px;
    gap: 8px;
    cursor: pointer;
    color: rgba(241, 253, 255, 1);
    font-family: Mulish;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.0025em;
    text-align: center;
}
.VisaBookNow_Desc{
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
color:
rgba(132, 132, 132, 1);    
}
.VisaBookNow_Title{
    font-family: Mulish;
font-size: 24px;
font-weight: 500;
line-height: 28px;
letter-spacing: 0.0025em;
text-align: left;
color: 
rgba(59, 59, 59, 1);
}
@media screen and (max-width:1280px) {
    .VisaBookNow_Layout{
        margin: 0 auto;
        margin-top: 72px;
        width: 90%;
    }
}
@media screen and (max-width:890px) {
    .VisaBookNow_Conatiner {
        border-radius: 20px 0px 0px 20px;
        background: rgba(244, 244, 244, 1);
        display: flex;
        gap: 24px;
        padding: 24px;
        justify-content: center;
        align-items: flex-start;
        /* flex-direction: column; */
    }
}
@media screen and (max-width:660px) {
    .VisaBookNow_Conatiner {
        border-radius: 20px 0px 0px 20px;
        background: rgba(244, 244, 244, 1);
        display: flex;
        gap: 24px;
        padding: 24px;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }
}
@media screen and (max-width:480px) {
    .VisaBookNow_Layout{
        margin: 0 auto;
        margin-top: 72px;
    }
    .VisaBookNow_Conatiner {
        border-radius: 20px 0px 0px 20px;
        background: rgba(244, 244, 244, 1);
        display: flex;
        gap: 24px;
        padding: 24px;
        justify-content: center;
        align-items: flex-start;
        width: 90%;
        flex-direction: column;
        margin-bottom: 54px;
    }
    .Visa_Book_now_img{
        width: 100%;
    }
    .VisaBookNow_Title {
        font-family: Mulish;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.0025em;
        text-align: left;
        color: rgba(59, 59, 59, 1);
    }
}
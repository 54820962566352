.login-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content:space-around;
    gap: 0rem;
    max-width: 1280px;
  }
  
  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .login-form {
  z-index: 1;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  max-width: 1280px;
  align-items: flex-start;
  padding-left: 80%;
  padding-bottom: 10%;
flex-direction: column;
  text-align: center;
  }
  .label{
    color:  rgba(255, 255, 255, 1);
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
  }
  
  h2 {
    margin-bottom: 20px;
    display: flex;
    text-align: left;
    color:  rgba(255, 255, 255, 1);
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  input {
    width: 384px;
    padding: 10px 14px 10px 14px;
    margin-bottom: 10px;
    height: 54px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 15px !important;
    letter-spacing: 0.02em;
    border: 1px solid rgba(0, 0, 0, 0.4);
    color: #000;
  }
  .textfield{
    width: 384px;
    background: rgba(255, 255, 255, 1);;
    padding:   14px 19px 14px 19px;
    margin-bottom: 10px;
    height: 54px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px ;
    border: 1px solid rgba(0, 0, 0, 0.4)
  }
 
  .textfield:focus {
      outline: none;
  }
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
   font-size: 15px !important;
   color: #000 !important; 
  }
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-radius: 10px !important;
    border:none;
    border-color: transparent !important;
    font-size: 12px;
    font-size: 0.5rem;

}
  
  .btn_login{
    width: 380px;
    padding: 10px;
    background-color: rgba(176, 56, 61, 1);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    margin-top: 32px;
  }
  
  .btn_login:hover {
    /* background-color: #2980b9; */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
    cursor: pointer;

  }
  @media only screen and (max-width: 480px) {
    .login-form {
      padding-left: 5%;
    }
    input {
      width: 320px;
      padding: 10px 14px 10px 14px;
      margin-bottom: 10px;
      box-sizing: border-box;
      border: 1px solid #ccc;
      /* border-radius: 12px; */
      outline: none;
  }
  .textfield {
    width: 320px;
    background: rgba(255, 255, 255, 1);
    padding: 14px 19px 14px 19px;
    margin-bottom: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    /* border-radius: 128px; */
    outline: none;
}
.btn_login {
  width: 320px;
  padding: 10px;
  background-color: rgba(176, 56, 61, 1);
  color: #fff;
  border: none;
  /* border-radius: 128px; */
  cursor: pointer;
  outline: none;
}
  }
  
  /* Medium screens (880px) */
  @media only screen and (min-width: 481px) and (max-width: 880px) {
    .login-form {
      padding-left: 15%;
    }
    input {
      width: 320px;
      padding: 14px 19px 14px 19px;
      margin-bottom: 10px;
      box-sizing: border-box;
      border: 1px solid #ccc;
      /* border-radius: 12px; */
      outline: none;
  }
  .textfield {
    width: 320px;
    background: rgba(255, 255, 255, 1);
    padding: 14px 19px 14px 19px;
    margin-bottom: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    /* border-radius: 128px; */
    outline: none;
}
.btn_login {
  width: 320px;
  padding: 10px;
  background-color: rgba(176, 56, 61, 1);
  color: #fff;
  border: none;
  /* border-radius: 128px; */
  cursor: pointer;
  outline: none;
}
  }
  
  /* Large screens (1280px) */
  @media only screen and (min-width: 881px) and (max-width: 1280px) {
    .login-form {
      padding-left: 20%;
    }
  }
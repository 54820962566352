.Family_header_title{
    color: #014A91;
    font-size: 56px;
    font-style: normal;
    font-family: Mulish;
    font-weight: 900;
    line-height: 140%; 
    letter-spacing: 0.14px;
}
.container_Blog_user{
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    gap: 24px;
}
.family_header_desc_btn_secondry{
    background:white;
    padding: 24px;
    color: rgba(176, 56, 61, 1);
    border: 1px solid  rgba(176, 56, 61, 1) ;
    font-family: Mulish;
    font-size: 20px;
    font-weight: 900;
    line-height: 78px;
    letter-spacing: 0.0025em;
    text-align: left;
    width: 180px;
    border-radius: 20px;
}
.back_btn{
    font-size: 20px;
    font-style: normal;
    color: #3B3B3B;
    font-family: Mulish;
    font-weight: 600;
    line-height: 140%; 
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 1280px;
    margin: 0 auto;
    margin-top: 32px;
    margin-bottom: 32px;
    letter-spacing: 0.14px;
}
.Blog_Box{
    border-radius: 20px;
    background: #F4F4F4;
    padding: 32px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 0px;
    flex-direction: column;
    color: #3B3B3B;
    font-family: Mulish;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 33.6px */
    letter-spacing: 0.06px;
    margin-top: 24px;

    
}
.Blog_Box p{
    font-size: 20px;
    color: #3B3B3B;
    /* margin: 0 auto; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 5px;

}
.back_btn:hover{
    text-decoration: underline;
    cursor: pointer;
}
.add{
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    gap: 20px;
    margin: 0 auto;
    justify-content: center;
    align-items: flex-start;
    background: rgba(244, 244, 244, 1);
    padding: 32px;
    border-radius: 20px;
    margin-top: 32px;
}
.label_head{
    color: #3B3B3B;
    font-weight: 500;
    font-family: Mulish;
    line-height: 28px;
    letter-spacing: 0.06px;
    font-size: 20px;
}
.label_head a{
    color: #3B3B3B;
    font-weight: 600;
    font-family: Mulish;
    line-height: 28px;
    letter-spacing: 0.06px;
    font-size: 20px;
}
.title{
    text-align: left;
    display: flex;
    justify-content: flex-start;
}
.buttons_field{
    display: flex;
    gap: 8px;
}
.Blog_layout{
    max-width: 1280px;
    margin: 0 auto;
    padding: 32px 72px;
}
.admin_blog_content{
    display: flex;
    flex-direction: column;

}
.help-form-textarea{
    display: flex;
    width: 54%;
    padding: 12px 18px;
    height: 300px;
    font-family: Mulish;
    align-items: center;
    gap: 8px;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.40);
    color: rgba(0, 0, 0, 1);
    background: none;
    resize: none;
}
.Blog_header_description_admin{
    gap: 24px;
    align-items: flex-start;
    border-radius: 20px;
    justify-content: center;
    padding: 32px;
    border-radius: 20px;
    color: #3B3B3B;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    font-family: Mulish;
    line-height: 28px;
    letter-spacing: 0.06px;
    width: 90%;
    height: 308px;  
    display: -webkit-box;
  -webkit-line-clamp: 10;
  overflow: hidden;
  white-space: normal; /* Change wrap to normal */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
   
}
.read-delete_btn_fields{
    display: flex;
    gap: 8px;
}
.blog_content_desc{
    min-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    overflow: hidden;
    white-space: normal;
    -webkit-box-orient: vertical;
}
.label_head 
span{
    color: rgba(59, 59, 59, 1);
    font-size: 24px;
    font-weight: 600;
    text-transform: capitalize;
}
.Blog_header_description {
    /* display: flex;
    flex-direction: column; */
    gap: 24px;
    align-items: flex-start;
    border-radius: 20px;
    justify-content: center;
    /* background: #F4F4F4; */
    padding: 32px;
    border-radius: 20px;
    /* background: #F4F4F4; */
    color: #3B3B3B;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    font-family: Mulish;
    line-height: 28px;
    letter-spacing: 0.06px;
    width: 620px;
    height: auto;
    display: -webkit-box;
  -webkit-line-clamp: 10;
  overflow: hidden;
  white-space: normal; /* Change wrap to normal */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.Family_header_Box{
    border-radius: 20px;
background: #F4F4F4;
padding: 32px;
font-family: Mulish;
}
.Family_header_Image{
    display: flex;
    gap: 24px;
    padding: 24px 0px 52px 0px;
    max-width: 1280px;
    border-radius: 20px;
}
.Family_header_img{
    Width: 556px;
    height: auto;
    border-radius: 20px;
}
.Family_header_description{
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    gap: 0px;
    align-items: flex-start;
    border-radius: 20px;
    background: #F4F4F4;
    padding: 32px;
    border-radius: 20px;
    background: #F4F4F4;
    color: #3B3B3B;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    font-family: Mulish;
    line-height: 28px;
    letter-spacing: 0.06px;
    /* width: 556px; */
    width: 80%;
    height: auto;
}
.label_head_title{
    font-size: 22px;
    font-weight: 600;
}
.family_header_desc_btn{
    border-radius: 128px;
background: #B0383D;
display: flex;
width: 191px;
color: white;
border: none;
font-size: 16px;
text-transform: uppercase;
padding: 10px 36px;
justify-content: center;
margin-top: 52px;
cursor: pointer;
align-items: center;
gap: 8px;
}
.family_header_desc_btn_admin {
    border-radius: 128px;
    background: #B0383D;
    display: flex;
    width: 191px;
    color: white;
    border: none;
    font-size: 16px;
    text-transform: uppercase;
    padding: 10px 36px;
    justify-content: center;
    /* margin-top: 52px; */
    cursor: pointer;
    align-items: center;
    gap: 8px;
}
.family_header_desc_btn_secondry{
    border-radius: 128px;
    color: #B0383D;
    background:none;
    display: flex;
    height: 45px;
    width: 180px;
    border: none;
    font-size: 16px;
    text-transform: uppercase;
    padding: 10px 10px;
    justify-content: center;
    cursor: pointer;
    margin-top: 52px;
    align-items: center;
    gap: 8px;
    border: 1px solid #B0383D;
}
@media screen and (max-width: 890px) {
    .Family_header_Box{
        border-radius: 20px;
        background: #F4F4F4;
        font-family: Mulish;
        padding: 24px;
        margin: 0 auto;
        width: 90%;
    } 
    .Family_header_Image {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 16px;
        margin: 0 auto;
    }
    .Family_header_img{
        width: 100%;
    }
    .Family_header_title {
        color: #014A91;
        font-family: Mulish;
        font-size: 32px;
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.14px;
    }
    .Blog_header_description {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;
        border-radius: 20px;
        justify-content: center;
        /* background: #F4F4F4; */
        padding: 24px;
        border-radius: 20px;
        /* background: #F4F4F4; */
        color: #3B3B3B;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        font-family: Mulish;
        line-height: 28px;
        letter-spacing: 0.06px;
        width: 93%;
        height: auto;
    }
    
    .Blog_layout {
        padding: 16px;
        margin: 0 auto;
    }
    .Blog_Box{
        border-radius: 20px;
        background: #F4F4F4;
        padding: 24px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        flex-direction: column;
        color: #3B3B3B;      
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.06px;
        margin-top: 24px;
        width: 80%;
        margin: 0 auto;
        margin-top: 24px;
    }
    .back_btn {
        font-size: 18px;
        font-style: normal;
        color: #3B3B3B;
        font-family: Mulish;
        font-weight: 600;
        line-height: 140%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 16px;
        letter-spacing: 0.14px;
        margin-top: 24px;
    }
    .help-form-textarea {
        display: flex;
        width: 90%;
        padding: 12px 18px;
        height: 300px;
        font-family: Mulish;
        align-items: center;
        gap: 8px;
        border-radius: 20px;
        border: 1px solid rgba(0, 0, 0, 0.40);
        color: rgba(0, 0, 0, 1);
        background: none;
        resize: none;
    }
}
@media screen and (max-width: 660px) {

    .Family_header_Box {
        font-family: Mulish;
        padding: 16px;
        margin: 0 auto;
        width: 85%;
    }
    .Family_header_Image {
        display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0px;
    margin: 0 auto;
    margin-top: 24px;
    }
    .Family_header_img{
        width: 95%;
        margin: 0 auto;
        padding: 9px;
        border-radius: 20px;
    }
    .Family_header_description {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;
        border-radius: 20px;
        background: #F4F4F4;
        padding: 24px;
        border-radius: 20px;
        background: #F4F4F4;
        color: #3B3B3B;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        font-family: Mulish;
        line-height: 28px;
        letter-spacing: 0.06px;
        /* width: 556px; */
        height: auto;
        cursor: pointer;
        margin: 0 auto;
    }
    .Family_header_title {
        color: #014A91;
        font-family: Mulish;
        font-size: 28px;
        font-style: normal;
        font-weight: 900;
        line-height: 130%;
        letter-spacing: 0.14px;
    }
    .Blog_header_description {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;
        border-radius: 20px;
        justify-content: center;
        /* background: #F4F4F4; */
        padding: 24px;
        border-radius: 20px;
        /* background: #F4F4F4; */
        color: #3B3B3B;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        font-family: Mulish;
        line-height: 28px;
        letter-spacing: 0.06px;
        width: 80%;
        margin: 0 auto;
        height: auto;
    }
    .Blog_layout {
        padding: 16px;
        margin: 0 auto;
    }
    .family_header_desc_btn {
        border-radius: 128px;
        background: #B0383D;
        display: flex;
        width: 191px;
        color: white;
        border: none;
        font-size: 16px;
        text-transform: uppercase;
        padding: 10px 36px;
        justify-content: center;
        margin-top: 0px;
        align-items: center;
        gap: 8px;
    }
    .Blog_Box{
        border-radius: 20px;
        background: #F4F4F4;
        padding: 24px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        flex-direction: column;
        color: #3B3B3B;      
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.06px;
        width: 80%;
        margin: 0 auto;
        margin-top: 24px;
    }
    .back_btn {
        font-size: 18px;
        font-style: normal;
        color: #3B3B3B;
        font-family: Mulish;
        font-weight: 600;
        line-height: 140%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 16px;
        letter-spacing: 0.14px;
    }
    .Blog_header_description_admin {
        gap: 24px;
        align-items: flex-start;
        border-radius: 20px;
        justify-content: center;
        padding: 24px;
        border-radius: 20px;
        color: #3B3B3B;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        font-family: Mulish;
        line-height: 28px;
        letter-spacing: 0.06px;
        width: 80%;
        height: auto;
        margin: 0 auto;
        display: -webkit-box;
        -webkit-line-clamp: 10;
        overflow: hidden;
        white-space: normal;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }
    .read-delete_btn_fields {
        display: flex;
        gap: 8px;
        margin: 0 auto;
        margin-top: 24px;
    }
    .help-form-textarea {
        display: flex;
        width: 90%;
        padding: 12px 18px;
        height: 300px;
        font-family: Mulish;
        align-items: center;
        gap: 8px;
        border-radius: 20px;
        border: 1px solid rgba(0, 0, 0, 0.40);
        color: rgba(0, 0, 0, 1);
        background: none;
        resize: none;
    }
    .label_head a {
        color: #3B3B3B;
        font-weight: 500;
        font-family: Mulish;
        line-height: 20px;
        letter-spacing: 0.06px;
        font-size: 15px !important;
    }
    .label_head span {
        color: rgba(59, 59, 59, 1);
        font-size: 20px !important;
        font-weight: 600;
        text-transform: capitalize;
    }
}
@media screen and (max-width: 480px) {
    .container_Blog_user {
        max-width: 1280px;
        margin: 0 auto;
        display: flex;
        gap: 24px;
        flex-direction: column;
    }
    .Link_container_left {
        background: rgba(255, 247, 241, 1);
        padding: 24px;
        color: rgba(176, 56, 61, 1);
        font-family: Mulish;
        font-size: 40px;
        font-weight: 900;
        line-height: 78px;
        letter-spacing: 0.0025em;
        text-align: left;
        width: 85%;
        height: auto;
        border-radius: 20px;
    }
    .Link_container_right {
        width: 85%;
        height: auto;
        padding: 24px;
    }
.family_header_desc_btn {
    border-radius: 128px;
    background: #B0383D;
    display: flex;
    width: 191px;
    color: white;
    border: none;
    font-size: 16px;
    text-transform: uppercase;
    padding: 10px 36px;
    justify-content: center;
    margin-top: 0px;
    align-items: center;
    gap: 8px;
}
.Blog_Box{
    border-radius: 20px;
    background: #F4F4F4;
    padding: 24px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    flex-direction: column;
    color: #3B3B3B;      
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.06px;
   
    width: 80%;
    margin: 0 auto;
    margin-top: 24px;
}
.back_btn {
    font-size: 18px;
    font-style: normal;
    color: #3B3B3B;
    font-family: Mulish;
    font-weight: 600;
    line-height: 140%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    letter-spacing: 0.14px;
}
.Blog_header_description_admin {
    gap: 24px;
    align-items: flex-start;
    border-radius: 20px;
    justify-content: center;
    padding: 24px;
    border-radius: 20px;
    color: #3B3B3B;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    font-family: Mulish;
    line-height: 28px;
    letter-spacing: 0.06px;
    width: 80%;
    height: auto;
    margin: 0 auto;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    overflow: hidden;
    white-space: normal;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}
.read-delete_btn_fields {
    display: flex;
    gap: 8px;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 24px;
}
.help-form-textarea {
    display: flex;
    width: 120%;
    padding: 12px 18px;
    height: 300px;
    font-family: Mulish;
    align-items: center;
    gap: 8px;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.40);
    color: rgba(0, 0, 0, 1);
    background: none;
    resize: none;
}
.label_head a {
    color: #3B3B3B;
    font-weight: 600;
    font-family: Mulish;
    line-height: 20px;
    letter-spacing: 0.06px;
    font-size: 15px !important;
}
.label_head span {
    color: rgba(59, 59, 59, 1);
    font-size: 20px !important;
    font-weight: 500;
    text-transform: capitalize;
}
}


.headoffice-layout {
    margin: 0 auto;
    max-width: 1280px;
    padding: 36px 72px;
}

.headoffice-heading-box {
    padding: 32px;
    border-radius: 20px;
    background: #F4F4F4;
    width: 380px;
    height: auto;
}

.headoffice-heading-box1 {
    display: none;
    padding: 32px 32px 75px 32px;
    border-radius: 20px;
    background: #F4F4F4;
    width: 363px;
    height: auto;
}

.headoffice-heading {
    color: #045590;
    font-family: Arial;
    font-size: 56px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%;
    letter-spacing: 0.14px;
    width: 80%;
}

.headoffice-image {
    width: 810px;
    height: auto;
    border-radius: 20px;
    background: #F4F4F4;
    text-align: center;
}

.head-layout-one {
    display: flex;
    gap: 24px;
    justify-content: space-between;
    margin-bottom: 24px;
}

.head-city-name {
    width: 512px;
    display: flex;
    gap: 24px;
    align-items: center;
}

.head-city-name1 {
    width: 512px;
    display: flex;
    gap: 24px;
    align-items: center;
}


.australia {
    width: 88px;
    height: 95px;
}

.india {
    width: 94px;
    height: 95px;
}

.head-cities {
    border-radius: 20px;
    background: #F4F4F4;
    padding: 32px;
}

.head-cities1 {
    display: none;
    border-radius: 20px;
    background: #F4F4F4;
    padding: 60px 32px;
}

.cityName {
    color: #3B3B3B;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    line-height: 24px;
    margin-bottom: 16px
}

.cityAddress {
    color: #848484;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    align-items: flex-start;
    gap: 8px;
    margin-bottom: 8px;
    width: 335px;
}

.cityAddress3 {
    max-width: 350px;
}

.address-style {
    display: flex;
    gap: 8px;
}

.contact-style {
    display: flex;
    gap: 8px;
}

.cityAddress1 {
    color: #848484;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    align-items: center;
    padding-right: 16px;
    gap: 8px;
    /* border-right: 1px solid #848484; */
}

.cityAddress11 {
    color: #848484;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    align-items: center;
    padding-right: 16px;
    gap: 8px;
    border-right: 1px solid #848484;
}

.cityAddress2 {
    color: #848484;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 16px;
}

.cityAddress22 {
    color: #848484;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: 8px;
}

.cityContactDetails {
    display: flex;
}

.cityContactDetails1 {
    display: flex;
}

.head-cities {
    display: flex;
    gap: 80px;
    justify-content: center;
    align-items: center;
}

.whatsapplogo {
    padding-left: 16px;
    color: #848484;
}

.head-layout-two {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
@media screen and (max-width: 1115px) {
    .cityContactDetails {
        flex-direction: column;
        gap: 8px;
    }

    .whatsapplogo {
        padding-left: 0px;
    }

    .cityAddress11 {
        border-right: none;
    }
}

@media screen and (max-width:1050px) {
    .headoffice-layout {
        padding: 24px 32px;
    }
}

@media screen and (max-width: 960px) {
    .head-cities {
        flex-direction: column;
    }

    .head-layout-one {
        flex-direction: column;
    }

    .headoffice-image {
       width: 100%;
    }

    .headoffice-heading-box {
        width: auto;
        padding-bottom: 32px;
    }

    .cityContactDetails {
        flex-direction: row;
        gap: 0px;
    }

    .whatsapplogo {
        padding-left: 16px;
    }

    .cityAddress11 {
        border-right: 1px solid #848484;
    }
}

@media screen and (max-width: 690px) {
    .headoffice-layout {
        padding: 24px 0px;
    }

    .headoffice-heading-box {
        display: none;
    }

    .headoffice-heading-box1 {
        display: block;
        width: auto;
        padding: 16px;
    }

    .headoffice-heading{
        font-size: 24px;
    }

    .head-layout-one {
        padding-left: 16px;
        padding-right: 16px;
    }

    .head-city-name1 {
        display: none;
    }

    .head-cities {
        border-radius: 0px;
        padding: 16px;
    }

    .head-cities1 {
        display: flex;
        justify-content: center;
        border-radius: 0px;
        padding: 16px;
        margin-top: 16px;
    }
    .cityName{
        font-size: 20px;
    }
    .cityAddress, .cityAddress11,.cityAddress22, .cityAddress1, .cityAddress2{
        font-size: 12px;
        width: 200px;

    }

}

@media screen and (max-width: 570px) {
    .headoffice-map {
        width: 100%;
        height: 185px;
    }
}

@media screen and (max-width: 550px) {
    .head-city-name {
        align-items: center;
    }

    .head-city-name {
        max-width: 495px;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .cityContactDetails {
        flex-direction: column;
        gap: 8px;
    }

    .whatsapplogo {
        padding-left: 0px;
    }

    .cityAddress11 {
        border-right: none;
    }

    .cityAddress1 {
        padding-right: 0px;
        border: none;
    }

    .cityAddress2 {
        padding-left: 32px;
    }
}

@media screen and (max-width: 400px) {
    .cityAddress {
        font-size: 14px;
    }

    .head-city-name {
        width: 105%;
    }
}
.ourteam-layout {
    margin: 0 auto;
    max-width: 1280px;
    padding: 36px 72px;
    display: flex;
    gap: 24px;
    /* justify-content: space-between; */
}

.team-main-heading {
    max-width: 556px;
    max-height: 224px;
    border-radius: 20px;
    background: #F4F4F4;
}

.team-main-heading1 {
    max-height: 224px;
    border-radius: 20px;
    background: #F4F4F4;
    font-family: Mulish;
    display: flex;
    flex-direction: column;
    font-family: Mulish;
    gap: 18px;
    padding: 32px;
    width: 635px;
    height: 155px;
}

.team-heading {
    color: #045590;
    font-family: Arial;
    font-size: 56px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%;
    letter-spacing: 0.14px;
    padding-top: 34px;
    padding-bottom: 34px;
    padding-left: 32px;
    padding-right: 97px;
}

.team-subheading {
    color: #3B3B3B;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.06px;
    max-width: 258px;
    padding-bottom: 22px;
}

.team-subheading1 {
    color: #3B3B3B;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.06px;
    max-width: 213px;
    padding-bottom: 22px;
}

.team-sub-desc {
    /* max-width: 492px; */
    color: #848484;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.team-main-image {
    max-width: 556px;
    max-height: 463px;
    border-radius: 20px;
}

.team-layout-one{
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: space-around;
}

.team-layout-two{
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: space-between;
}

.team-layout-two-main{
    display: none;
    flex-direction: column;
    gap: 15px;
    justify-content: space-between;
}

@media screen and (max-width: 1050px) {
    .team-main-heading1{
        max-width: 1050px;
    }
    .ourteam-layout{
        flex-direction: column;
        padding: 24px 32px;
    }
    .team-subheading, .team-subheading1,.team-sub-desc,.team-main-heading {
        max-width: 1050px;
    }
    .our-team{
        width: 100%;
        height: 450px;
    }
    .team-main-image{
        max-width: 1050px;
    }
    .team-heading{
        padding-right: 0px;
    }
}

@media screen and (max-width: 660px) {
    .ourteam-layout{
        padding: 24px 16px;
    }
    .team-heading{
        padding: 16px;
        font-size: 24px;
    }
    .our-team{
        height: 350px;
    }
    .team-main-heading1{
        padding: 16px;
        width: 90%;
        height: auto;
    }
    .team-subheading, .team-subheading1{
        font-size: 20px;
    }
    .team-sub-desc{
        font-size: 14px;
    }
    .team-layout-two{
        display: none;
    }
    .team-layout-two-main{
        display: flex;
    }
}

@media screen and (max-width: 500px) {
    .our-team{
        height: 250px;
    }
}